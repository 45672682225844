import {
  DR_Business_User_Invite,
  DR_Tenant_Private,
  DR_Tenant_Public,
} from '@rabbit/data/portal';
import {
  FBD_Business_User_Invite,
  BusinessUserInviteStatus,
  DTBusiness_User_Invite,
  DTTenant_Public,
  FBD_Tenant_Public,
} from '@rabbit/data/types';
import { useFirestoreQueryData } from '@react-query-firebase/firestore';
import { orderBy, query, where } from 'firebase/firestore';
import { FBD_User_Request } from '../../../../../data/types/src/lib/user-request';

export function useGetSagePartners(tenantid: string) {
  const inviteQuery = query(
    DR_Business_User_Invite.collection(),
    where('status', '!=', BusinessUserInviteStatus.Completed),
    where('tenantLink', '==', tenantid),
    orderBy('status'),
    orderBy('tenantLink')
  );

  const inviteList = useFirestoreQueryData(
    ['inviteList', tenantid],
    inviteQuery,
    { subscribe: true }
  );

  const partnerTenantQuery = query(
    DR_Tenant_Public.collection(),
    where('isPartnerTo', 'array-contains', tenantid),
    orderBy('isPartnerTo')
  );

  const partnerTenantList = useFirestoreQueryData(
    ['partnerTenantList', tenantid],
    partnerTenantQuery,
    { subscribe: true }
  );

  const partnersList = inviteList?.data?.map(
    (partnerTenant: DTBusiness_User_Invite) => {
      const matchingInvite = partnerTenantList?.data?.find(
        (invite: DTTenant_Public) => invite.email === partnerTenant.email
      );
      return matchingInvite ? matchingInvite : partnerTenant;
    }
  );

  if (partnersList && partnerTenantList?.data) {
    partnerTenantList.data.forEach((invite: DTTenant_Public) => {
      const matchingPartner = partnersList.find(
        (partner: DTTenant_Public) => partner.email === invite.email
      );
      if (!matchingPartner) {
        partnersList.unshift(invite);
      }
    });
  }

  const getSingleInvite = async (inviteId: string) => {
    const invite = await FBD_Business_User_Invite.get(inviteId);
    if (!invite) throw new Error('No invite found');
    if (invite.tenantLink !== tenantid)
      throw new Error('You are not authorized to view this invite');
    return invite;
  };

  const getUserRequest = async (partnerId: string) => {
    if (partnerId.includes('_')) {
      const email = partnerId.split('_')[1];
      const userRequest = await FBD_User_Request.query()
        .where('email', '==', email)
        .getContents();
      if (userRequest.length > 0) {
        const businessInvite = await FBD_Business_User_Invite.query()
          .where('email', '==', userRequest[0].email)
          .getContents();
        return { ...businessInvite[0], ...userRequest[0] };
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  const getSinglePartner = async (partnerId: string) => {
    const partner = await FBD_Tenant_Public.get(partnerId);

    if (!partner) throw new Error('No partner found');
    if (!partner.isPartnerTo?.includes(tenantid))
      throw new Error('You are not authorized to view this partner');
    return partner;
  };

  return {
    inviteList,
    partnerTenantList,
    partnersList,
    getSingleInvite,
    getSinglePartner,
    getUserRequest,
  };
}
