import { createHmac } from 'node:crypto';

export class UtilsPseudoRandom {
  private readonly MULTIPLIER = 1664525;
  private readonly INCREMENT = 1013904223;
  private readonly MODULUS = Math.pow(2, 32);
  private seed: number;

  constructor(seed: string) {
    let hash = 0;
    for (let i = 0; i < seed.length; i++) {
      const charCode = seed.charCodeAt(i);
      hash = (hash * 31 + charCode) % this.MODULUS;
    }
    this.seed = hash;
  }

  public generate(): number {
    const next = (this.MULTIPLIER * this.seed + this.INCREMENT) % this.MODULUS;
    this.seed = next;
    return next / this.MODULUS;
  }
}
