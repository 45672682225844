import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Button, Input } from '@rabbit/elements/shared-components';
import {
  ConsumerIssueTypeHookOrigins,
  useManageConsumerIssueTypes,
  useSageAPI,
} from '@rabbit/bizproc/react';
import { CaseflowCaseTypes, RegisterCaseOrigins } from '@rabbit/bizproc/core';
import { t } from 'i18next';
import {
  PersonaIdTypeSplitter,
  PersonaTypeSingleLetter,
} from '@rabbit/data/types';
import { toast } from 'react-toastify';
import { SAGE_ROUTE_NAME } from '@rabbit/config/enums';

export const validationSchema = Yup.object().shape({
  registrationNo: Yup.string()
    .trim()
    .min(5, 'Enter a valid registration plate')
    .required('Enter a registration plate'),
});

export interface NewClaimDescriptionProps {
  handleClose: () => void;
  onChange: any; //TODO
  setIsLoading: (loading: boolean) => void;
  data: any;
}

interface HoldingDataCase {
  consumer_issue_description: string;
  consumer_issue_type_ref: string;
  internal_comment: string;
}

export function NewClaimDescription(props: NewClaimDescriptionProps) {
  const { initiateCaseFlowCase } = useSageAPI();
  const { onChange, handleClose, setIsLoading, data } = props;
  const tenantLink = t('tenantLink');

  const validationSchema = Yup.object().shape({
    consumer_issue_type_ref: Yup.string()
      .trim()
      .required('Issue type must be selected'),
    consumer_issue_description: Yup.string().trim(),
    internal_comment: Yup.string().trim(),
  });

  const { consumerIssueTypeList } = useManageConsumerIssueTypes(
    ConsumerIssueTypeHookOrigins.SAGE
  );
  const consumerIssueTypeOptions = !consumerIssueTypeList?.data
    ? []
    : consumerIssueTypeList.data.map((issue) => ({
        id: issue.docid,
        value: issue.docid,
        label: issue.label,
      })) ?? [];

  const initialValues: HoldingDataCase = {
    consumer_issue_type_ref: '',
    consumer_issue_description: '',
    internal_comment: '',
  };

  // TODO: check this on submit func to save the case
  const onSubmit = async (values: HoldingDataCase) => {
    const consumer_issue_type = consumerIssueTypeOptions.find(
      (issue) => issue.value === values.consumer_issue_type_ref
    )?.label;

    const formData = {
      ...values,
      consumer_issue_type: consumer_issue_type ?? '',
    };
    const warrantorLink =
      PersonaTypeSingleLetter.Warrantor + PersonaIdTypeSplitter + tenantLink;
    try {
      setIsLoading(true);
      const response = await initiateCaseFlowCase({
        origin: RegisterCaseOrigins.SAGE_LITE,
        caseType: CaseflowCaseTypes.LITE,
        tenantLink,
        holdingLink: data.holding.holdingLink,
        consumerLink: data.holding.consumer,
        formData,
        warrantorLink,
      });

      if (response?.caseId) {
        toast.success('Claim created successfully');
        setTimeout(() => {
          handleClose();
          window.location.href =
            SAGE_ROUTE_NAME.CLAIMS + `/${response?.caseId}`;
        }, 1000);
      }
    } catch (err: any) {
      const errorMsg = err?.message as string;
      console.error(err);
      if (errorMsg.includes('Case already exists')) {
        toast.error(
          "A claim already exists for this vehicle. Please check the claim's status in the claim list."
        );
      } else {
        toast.error(
          'An error occurred while creating your claim. Please try again later.'
        );
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ errors, values }) => {
        return (
          <Form className="font-nunito flex flex-col gap-5 p-4">
            <Input
              type="select"
              name="consumer_issue_type_ref"
              label="Please select an issue*"
              settings={{
                options: consumerIssueTypeOptions,
                placeholder: 'Select',
                id: 'consumer_issue_type_ref',
                hint: '*required',
              }}
            />
            <Input
              label="Additional information"
              type="text"
              name="consumer_issue_description"
              settings={{
                id: 'consumer_issue_description',
                placeholder: 'Write text here…',
                allowSpecialCharacter: true,
              }}
            />
            <Input
              type="rich-text"
              label="Internal comment"
              name="internal_comment"
              settings={{
                id: 'internal_comment',
                placeholder: '',
                allowSpecialCharacter: true,
              }}
            />
            <div className="sticky bottom-0 -mb-2 flex w-full gap-4 bg-white py-4">
              <Button
                kind="primary"
                type="submit"
                disabled={Object.keys(errors).length > 0}
              >
                Continue
              </Button>
              <Button kind="red" type="submit" onClick={handleClose}>
                Cancel
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default NewClaimDescription;
