export interface BLITech {
  getOliveUrlFromSage: (sageUrl: string) => string;
  getSageUrlFromOlive: (oliveUrl: string) => string;
}

export const BL_Tech: BLITech = {
  getOliveUrlFromSage,
  getSageUrlFromOlive,
};

function getOliveUrlFromSage(sageUrl: string): string {
  return sageUrl
    .replace('localhost:1317', 'localhost:1337')
    .replace('-sage', '-olive')
    .replace('admin-', '');
}

function getSageUrlFromOlive(oliveUrl: string): string {
  if (oliveUrl.includes('localhost') || oliveUrl.includes('olive')) {
    return oliveUrl
      .replace('localhost:1337', 'localhost:1317')
      .replace('-olive', '-sage');
  } else if (oliveUrl.includes('-')) {
    const split = oliveUrl.split('-');
    split[0] = `${split[0]}-admin`;
    return split.join('-');
  } else {
    const split = oliveUrl.split('.');
    split[0] = split[0].replace('//', '//admin-');
    return split.join('.');
  }
}
