import { UtilsPseudoRandom } from '@rabbit/utils/pseudo-random';

let utilsPseudoRandom = new UtilsPseudoRandom(new Date().getTime() + '!');
let instance = 1;
let theSeed: string | undefined = undefined;
const FixedNowTime = new Date().getTime();

export function SetRandomSeed(seed?: string) {
  theSeed = seed;
  utilsPseudoRandom = new UtilsPseudoRandom(
    seed ?? new Date().getTime() + '_' + instance++
  );
}

export function Random() {
  return utilsPseudoRandom.generate();
}

export function NowTime() {
  if (theSeed) return FixedNowTime;
  return new Date().getTime();
}
