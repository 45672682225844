import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import { getRootPersonaFromLexicon } from '@rabbit/bizproc/client';
import {
  Button,
  Input,
  Heading,
  LoadingSpinner,
  getCurrencyFormat,
  getAgeFromDate,
} from '@rabbit/elements/shared-components';
import * as Yup from 'yup';
import {
  PrincipalsFieldName,
  PersonaTypeSingleLetter,
  DTWarranty_Template,
  DTWarranty_Offer,
  DeciderApprovedOptionInfo,
  SingleApprovedOptionInfo,
  SingleApprovedOptionInfoPairs,
  DTOptionsSingleOption,
  PartnerSettings,
  DeciderOutput,
} from '@rabbit/data/types';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CarRegistrationShape } from '../../ModalNewRegistration/ModalNewRegistration';
import Select from 'react-select';
import { generateSelectStyles } from '@rabbit/elements/shared-components';
import { Formik, Form } from 'formik';
import {
  RegistrationsWarrantyOptionShape,
  SelectOptionShape,
} from '@rabbit/elements/shared-types';
import { useGetMySagePersonas } from '@rabbit/data/portal';
import { toTitleCase, useAppInfo } from '@rabbit/sage/utils/helpers';
import { DeciderPerformDecision } from '@rabbit/bizproc/core';
import { useSageAPI } from '@rabbit/bizproc/react';
import { format } from 'date-fns';

export interface CreateNewCoverageOptionsProps {
  handleClose: () => void;
  onChange: any; //TODO
  data: CarRegistrationShape & {
    mileage: string;
    mileageUnit: string;
    commercialVehicle: string;
    driveType: string;
  };
  setIsLoading: Dispatch<SetStateAction<boolean>>;
}

export interface CoverageOptions {
  warranty_type: string;
  warranty_duration: any;
  warranty_claim_limit: any;
  warranty_labour_rates: any;
  warranty_offer?: DTWarranty_Offer;
  warranty_template: DTWarranty_Template;
  warranty_start_date?: string;
}

const optionDescription = (offer: RegistrationsWarrantyOptionShape) => {
  const durations = offer.approvedOptions.find((i) => i.label === 'Duration');
  if (durations) {
    return durations.optionInfo
      .map((option, index) => {
        return index === durations.optionInfo.length - 1
          ? durations.optionInfo.length > 1
            ? `or ${option.option.label}`
            : `${option.option.label}`
          : index === durations.optionInfo.length - 2
          ? `${option.option.label?.split(' ')[0]} `
          : `${option.option.label?.split(' ')[0]}, `;
      })
      .join('');
  } else return '';
};

const validationSchema = Yup.object().shape({
  warranty_start_date: Yup.string().nullable(),
});

export function CreateNewCoverageOptions({
  handleClose,
  onChange,
  data,
  setIsLoading,
}: CreateNewCoverageOptionsProps) {
  const { getApprovedWarrantyOffers, isReady } = useSageAPI();
  const [selectedOption, setSelectedOption] = useState<CoverageOptions>();
  const [activeOption, setActiveOption] = useState<string>();
  const [offers, setOffers] = useState<RegistrationsWarrantyOptionShape[]>();
  const [deciderOutput, setDeciderOutput] = useState<any>();
  const [labourRates, setLabourRates] =
    useState<PartnerSettings['labourRates']>();
  // todo: put this at the start of the flow, so that the user can't even get here if they don't have the right personas
  // pass the activeTenantid in as a prop or in the data object
  const { warrantyDealerPersona, warrantorPersona } = useGetMySagePersonas();
  const activeTenantId =
    warrantyDealerPersona?.warrantyDealer_private?.tenantLink ||
    warrantorPersona?.warrantor_private?.tenantLink;

  const { t } = useTranslation();
  const appInfo = useAppInfo();

  const handleOptionSelected = (
    template: DTWarranty_Template,
    term: any | undefined,
    claimLimit: any | undefined,
    labourRates: any | undefined,
    offer: DTWarranty_Offer
  ) => {
    if (!term || !claimLimit || !labourRates) {
      setSelectedOption(undefined);
      setDeciderOutput(undefined);
      return;
    }
    const convertedMileage =
      data.mileageUnit && data.mileageUnit === 'mi'
        ? Number(data.mileage) * 1.609344
        : data.mileage;
    const duration = {
      label: term.label
        ?.split(' ')
        .map((i: any) => toTitleCase(i))
        .join(' '),
      value: term.value,
    };

    const finalClaimLimit = {
      label: claimLimit.label,
      // Sorry for the hack job @Tolu @Vasco, had to do this for a quick fix - DC
      value:
        claimLimit.label !== 'PPOV'
          ? Number(claimLimit.value)
          : claimLimit.value,
    };

    const stipulated = {
      age: getAgeFromDate(data.regDate),
      mileage: convertedMileage,
      riskCategory: riskCategoryMap[data.vehicleCategory],
      duration,
      claimLimit: finalClaimLimit,
      isCommercial: data.commercialVehicle === 'yes',
      is4x4: data.driveType === '4x4',
      isElectric: data.fuel === 'Electric',
      isHybrid: data.fuel === 'Hybrid',
      increasedLabourRate1: labourRates.id === '1',
      increasedLabourRate2: labourRates.id === '2',
      increasedLabourRate3: labourRates.id === '3',
    };

    const output = DeciderPerformDecision(stipulated, template, offer);
    if (output) {
      setDeciderOutput(output);
    }

    setSelectedOption({
      warranty_duration: term,
      warranty_claim_limit: claimLimit,
      warranty_labour_rates: labourRates,
      warranty_type: template.docid,
      // warranty_type: offer.docid,
      warranty_template: template,
      warranty_offer: offer,
    });
  };

  const riskCategoryMap: Record<string, { label: string; value: string }> = {
    standard: { label: 'No risk', value: 'A' },
    'special-risk-b': { label: 'B', value: 'B' },
    'special-risk-c': { label: 'C', value: 'C' },
  };

  useEffect(() => {
    if (isReady) {
      void (async () => {
        if (!activeTenantId) return; // todo: remove this once the activeTenantId is passed in

        const convertedMileage =
          data.mileageUnit && data.mileageUnit === 'mi'
            ? Number(data.mileage) * 1.609344
            : data.mileage;

        try {
          const { offers, labourRates } = await getApprovedWarrantyOffers(
            // Add all the relevant data you have at this point here. For a list of all the fields to fill in, check out the decider for these templates
            // I'll make something to fetch the stipulations from the decider when it becomes necessary, but for now look for  PinnacleDecider2 in
            // libs/bizproc/core/src/decider/testing/testdata.ts
            {
              params: {
                age: getAgeFromDate(data.regDate),
                mileage: Number(convertedMileage),
                riskCategory: riskCategoryMap[data.vehicleCategory],
              },
              // Dimensions will be the options that we want to display in the next step, in this case it's the duration and claim limit  (ignore labour rates for now)
              dimensions: ['claimLimit', 'duration'],
              partnerTenantLink: activeTenantId,
              warrantorLink: getRootPersonaFromLexicon(
                t(PrincipalsFieldName),
                PersonaTypeSingleLetter.Warrantor
              ),
            }
          );
          console.log('offers', offers);
          //todo:  Store the labour rates in state and use them to build that specific option in the next step
          console.log('labourRates', labourRates);
          setOffers(offers);
          setLabourRates(labourRates);
        } catch (e) {
          console.error(e);
        }
      })();
    }
  }, [activeTenantId, isReady]);

  const handleSubmit = (values: any) => {
    onChange(4, { ...selectedOption, ...values });
  };

  if (!offers || !activeTenantId)
    return (
      <div className="py-10">
        <LoadingSpinner size="md" />
      </div>
    );

  return (
    <div className="px-5 pt-4">
      {offers && offers.length === 0 ? (
        <>
          <div className="font-nunito mb-4 flex flex-col gap-2 rounded-md bg-red-100 p-4 text-red-700">
            <span className="flex items-center gap-2 font-semibold">
              <ExclamationTriangleIcon className="h-4 w-4 text-red-700" />
              Car does not qualify
            </span>
            <div className="text-red-700">
              This car does not meet the minimum requirements for any of the
              available options.
            </div>
          </div>
          <div className="mt-4 flex w-full gap-4">
            <Button kind="primary" type="submit" onClick={handleClose}>
              Ok
            </Button>
            <Button kind="red" type="submit" onClick={handleClose}>
              Cancel
            </Button>
          </div>
        </>
      ) : (
        <>
          <Heading kind="h4" className="mb-2">
            Available options
          </Heading>
          <Formik
            initialValues={{
              warranty_start_date: (data as any).warranty_start_date,
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            validateOnChange={true}
            validateOnBlur={false}
          >
            {({ setFieldValue }) => (
              <Form>
                <div className="flex flex-col gap-4">
                  {offers?.map((offer) => (
                    <>
                      <SelectableOption
                        key={offer.template.docid}
                        title={offer.offer?.title ?? offer.title}
                        description={optionDescription(offer)}
                        name={offer.template.docid}
                        selected={
                          selectedOption?.warranty_type === offer.template.docid
                        }
                        offer={offer.offer ?? ({} as DTWarranty_Offer)} // todo: another hack job sorry. It should never not be there but I don't like having to do this.
                        template={offer.template}
                        approvedOptions={offer.approvedOptions}
                        labourRates={labourRates}
                        onChange={handleOptionSelected}
                        active={activeOption === offer.template.docid}
                        onOpen={(active) => {
                          setActiveOption(offer.template.docid);
                          active && setSelectedOption(undefined);
                        }}
                      />
                      {/* <Input type="text" label="Claim limit" name="warranty_claim_limit"  settings={{}} /> */}
                    </>
                  ))}
                  <Input
                    type="datepicker"
                    name="warranty_start_date"
                    label="Warranty start date"
                    settings={{
                      id: 'warranty_start_date',
                      placeholder: 'DD/MM/YYYY',
                      onChange: (value: any) => {
                        if (value) {
                          const formattedDate = format(value, 'yyyy-MM-dd');
                          setFieldValue('warranty_start_date', formattedDate);
                        }
                      },
                    }}
                  />
                </div>
                {deciderOutput && (
                  <div className="font-nunito my-2 flex text-lg font-bold text-black">
                    Total:{' '}
                    {deciderOutput.decided.approval
                      ? getCurrencyFormat(
                          deciderOutput.decided.warrantyPrice,
                          appInfo.currency
                        ) +
                        ` (${getCurrencyFormat(
                          deciderOutput.decided.warrantyPrice +
                            deciderOutput.decided.VAT,
                          appInfo.currency
                        )} incl. VAT)`
                      : '-'}
                  </div>
                )}
                <div className="sticky bottom-0 flex w-full gap-4 bg-white pt-4">
                  <Button
                    kind="primary"
                    type="submit"
                    disabled={!selectedOption}
                  >
                    Continue
                  </Button>
                  <Button kind="red" type="button" onClick={handleClose}>
                    Cancel
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </>
      )}
    </div>
  );
}

/* -------------------------------------------------------------------------- */
/*                              Option component                              */
/* -------------------------------------------------------------------------- */

function SelectableOption({
  title,
  description,
  selected: picked,
  active,
  name,
  template,
  offer,
  approvedOptions,
  labourRates,
  onChange,
  onOpen,
}: {
  title: string;
  description: string;
  selected: boolean;
  active: boolean;
  name: string;
  template: DTWarranty_Template;
  offer: DTWarranty_Offer;
  approvedOptions: DeciderApprovedOptionInfo[];
  labourRates: PartnerSettings['labourRates'];
  onChange: (
    template: DTWarranty_Template,
    term: string | undefined,
    claimLimit: string | undefined,
    labourRates: string | undefined,
    offer: DTWarranty_Offer
  ) => void;
  onOpen: (open: boolean) => void;
}) {
  const claimLimitRef = useRef(null) as any;

  const duration = approvedOptions.find((i: any) => i.label === 'Duration');

  const [selectedTerm, setSelectedTerm] = useState<any | undefined>();
  const [selectedClaimLimit, setSelectedClaimLimit] = useState<
    any | undefined
  >();
  const [selectedLabourRates, setSelectedLabourRates] = useState<
    any | undefined
  >();

  const options = duration?.optionInfo?.map(
    (obj: SingleApprovedOptionInfo, index: number) => ({
      id: index + '',
      label: obj.option.label,
      value: obj.option.value,
    })
  );

  const selectedOptionBasedOnDuration = duration?.optionInfo?.find(
    (i: SingleApprovedOptionInfo) => i.option.value === selectedTerm?.value
  );

  const claimLimit = selectedOptionBasedOnDuration
    ? selectedOptionBasedOnDuration.availablePairs.find(
        (i: SingleApprovedOptionInfoPairs) => i.label === 'Claim limit'
      )
    : null;

  const claimLimitOptions =
    claimLimit?.indices?.map(
      (indice: DTOptionsSingleOption, index: number) => ({
        id: index + '',
        label: indice.label,
        value: indice.value,
      })
    ) ?? [];

  const labourRatesOptions = labourRates
    ? [
        {
          id: '0',
          label: `Default (£${labourRates.default}/h)`,
          value: labourRates.default,
        },
        ...(labourRates.tier1 && labourRates.tier1 !== 0
          ? [
              {
                id: '1',
                label: `Tier 1 (£${labourRates.tier1}/h)`,
                value: labourRates.tier1,
              },
            ]
          : []),
        ...(labourRates.tier2 && labourRates.tier2 !== 0
          ? [
              {
                id: '2',
                label: `Tier 2 (£${labourRates.tier2}/h)`,
                value: labourRates.tier2,
              },
            ]
          : []),
        ...(labourRates.tier3 && labourRates.tier3 !== 0
          ? [
              {
                id: '3',
                label: `Tier 3 (£${labourRates.tier3}/h)`,
                value: labourRates.tier3,
              },
            ]
          : []),
      ]
    : [];

  const handleOpen = () => {
    active === false && onOpen(!active);
    onChange(
      template,
      selectedTerm,
      selectedClaimLimit,
      selectedLabourRates,
      offer
    );
  };

  useEffect(() => {
    onChange(
      template,
      selectedTerm,
      selectedClaimLimit,
      selectedLabourRates,
      offer
    );
    onOpen(!active);
  }, [selectedTerm, selectedClaimLimit, selectedLabourRates]);

  return (
    <div
      key={name}
      className={
        'font-nunito hover:border-primary-900 w-full cursor-pointer rounded-md border border-gray-300 px-4 pt-4' +
        (active ? ' border-primary-600' : ' overflow-hidden')
      }
      onClick={handleOpen}
    >
      <div className="font-medium">{title}</div>
      <div className="mt-[10px] text-gray-700">{description}</div>
      <div
        className={
          'mt-4 transition-all duration-200' +
          (active ? ' h-[96px]' : ' h-[0px]')
        }
      >
        <div className="grid grid-cols-3 gap-4 pb-4">
          <div className="flex flex-col">
            <div className="font-nunito mb-2 text-base text-gray-900 ">
              Term*
            </div>
            <Select
              classNames={generateSelectStyles()}
              options={options}
              value={options?.find(
                (i: DTOptionsSingleOption) => i.value === selectedTerm?.value
              )}
              onChange={(v) => {
                setSelectedTerm(v);
                claimLimitRef?.current?.clearValue();
              }}
            />
          </div>
          <div className="flex flex-col">
            <div className="font-nunito mb-2 text-base text-gray-900 ">
              Claim limit*
            </div>
            <Select
              ref={claimLimitRef}
              classNames={generateSelectStyles()}
              options={claimLimitOptions}
              value={claimLimitOptions.find(
                (i: DTOptionsSingleOption) =>
                  i.value === selectedClaimLimit?.value
              )}
              onChange={(v) => setSelectedClaimLimit(v)}
              isDisabled={claimLimitOptions.length === 0}
            />
          </div>
          <div className="flex flex-col">
            <div className="font-nunito mb-2 text-base text-gray-900 ">
              Labour rate*
            </div>
            <Select
              classNames={generateSelectStyles()}
              options={labourRatesOptions}
              value={labourRatesOptions?.find(
                (i) => i.value === selectedLabourRates?.value
              )}
              onChange={(v) => setSelectedLabourRates(v)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateNewCoverageOptions;
