import {
  ShadowContainer,
  Heading,
  LoadingSpinner,
} from '@rabbit/elements/shared-components';
import { NavLink, Navigate } from 'react-router-dom';
import { SAGE_ROUTE_NAME } from '@rabbit/config/enums';
import AuthForm from '../components/templates/AuthForm/AuthForm';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppInfo } from '../utils/helpers';
import { useTranslation } from 'react-i18next';

function SignupView() {
  const { t } = useTranslation();
  const appInfo = useAppInfo();
  const [isLoading, setIsLoading] = useState(false);
  const [authErrorMsg, setAuthErrorMsg] = useState('');
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');
  const type: string | 'internal' | 'c' = queryParams.get('c') || 'internal';

  // if (appInfo.environment === 'LIVE' && !token) return <Navigate to="/" />;

  if (isLoading) return <LoadingSpinner extraClasses={'mt-2'} size={'lg'} />;

  return (
    <ShadowContainer>
      <div className="px-14 pt-12 pb-4">
        <div className="mb-5">
          <Heading kind="h2">
            {type === 'internal'
              ? t('message.createAccount')
              : t('message.createYourPartnerAccountFor') + ' ' + appInfo.name}
          </Heading>
        </div>
        {type === 'internal' ? (
          <AuthForm
            kind="Signup"
            setIsLoading={setIsLoading}
            authErrorMsg={authErrorMsg}
            setAuthErrorMsg={setAuthErrorMsg}
          />
        ) : (
          <AuthForm
            kind="Partner-Signup"
            setIsLoading={setIsLoading}
            authErrorMsg={authErrorMsg}
            setAuthErrorMsg={setAuthErrorMsg}
          />
        )}
        <div>
          <p className="font-nunito mx-auto mt-3 text-sm">
            {t('message.byContinuingYouAgreeToIWarrantys')}
            <br />
            <a
              href={SAGE_ROUTE_NAME.TERMS_AND_CONDITIONS}
              className="text-primary-800 font-bold no-underline hover:underline"
              target="_blank"
              rel="noreferrer"
            >
              {t('general.termsAndConditions')}
            </a>
            &nbsp;and&nbsp;
            <a
              href={SAGE_ROUTE_NAME.PRIVACY_POLICY}
              className="text-primary-800 font-bold no-underline hover:underline"
              target="_blank"
              rel="noreferrer"
            >
              {t('general.privacyPolicy')}
            </a>
          </p>
        </div>
      </div>
      <div className="font-nunito flex items-center gap-1 rounded-b-[6px] border-t border-gray-200 bg-gray-50 px-14 py-4 text-base">
        {t('message.alreadyHaveAnAccount')}
        <NavLink
          to={SAGE_ROUTE_NAME.LOGIN}
          className="text-primary-800 font-bold no-underline hover:underline"
          reloadDocument
        >
          {t('general.loginHere')}
        </NavLink>
      </div>
    </ShadowContainer>
  );
}

export default SignupView;
