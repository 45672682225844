import {
  BusinessUserInviteType,
  Colour,
  Money,
  PersonaTypeSingleLetter,
  VolumeDimensions,
} from '@rabbit/data/types';
import { BrandNames, ProductList } from './lists';
import { Address, SplitName } from '@rabbit/data/types';
import { Random } from './utilities';

const COLOURS: Colour[] = [
  { name: 'red', hex: '#FF0000' },
  { name: 'green', hex: '#00FF00' },
  { name: 'blue', hex: '#0000FF' },
  { name: 'yellow', hex: '#FFFF00' },
  { name: 'orange', hex: '#FFA500' },
  { name: 'purple', hex: '#800080' },
  { name: 'pink', hex: '#FFC0CB' },
  { name: 'black', hex: '#000000' },
  { name: 'white', hex: '#FFFFFF' },
  { name: 'grey', hex: '#808080' },
  { name: 'cherry', hex: '#DE3163' },
  { name: 'turquoise', hex: '#40E0D0' },
  { name: 'charcoal', hex: '#36454F' },
  { name: 'lavendar', hex: '#E6E6FA' },
  { name: 'lime', hex: '#00FF00' },
  { name: 'platinum', hex: '#E5E4E2' },
  { name: 'beige', hex: '#F5F5DC' },
  { name: 'navy', hex: '#000080' },
  { name: 'aqua', hex: '#00FFFF' },
  { name: 'wine', hex: '#722F37' },
  { name: 'amber', hex: '#FFBF00' },
  { name: 'champagne', hex: '#F7E7CE' },
  { name: 'taupe', hex: '#483C32' },
  { name: 'sandstone', hex: '#796D62' },
];

/*
// Find a colour in a string
export function FindColour(what: string) {
  for (const colour of colours) {
    if (what.toLowerCase().indexOf(colour) !== -1) {
      return colour;
    }
  }
  return '';
}
*/

// Generate a random colour predictable by a random number function
export function GenerateColours(howMany = 1) {
  const copy = COLOURS.filter(Boolean);
  const res: Colour[] = [];
  for (let i = 0; i < howMany; i++) {
    res.push(copy.splice(Math.floor(Random() * copy.length), 1)[0]);
  }

  //console.log('GenerateColours', res);
  //process.exit();

  return res;
}

export function GenerateProductName() {
  return (
    BrandNames[Math.floor(Random() * BrandNames.length)] +
    ' ' +
    Capitalise(ProductList[Math.floor(Random() * ProductList.length)])
  );
}

export function GenerateJustProductName() {
  return Capitalise(ProductList[Math.floor(Random() * ProductList.length)]);
}

export function GenerateBrandName() {
  return BrandNames[Math.floor(Random() * BrandNames.length)];
}

export function GenerateSerialNumber() {
  // Serial number with a random format - either pure number, mix of numbers and letters, or 2-5 letters followed by numbers
  const formats = [
    () => {
      return Math.floor(Random() * 10000000000000000000).toString();
    },
    () => {
      const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      const numbers = '0123456789';
      let result = '';
      for (let i = 0; i < 20; i++) {
        if (Random() > 0.5) {
          result += letters[Math.floor(Random() * letters.length)];
        } else {
          result += numbers[Math.floor(Random() * numbers.length)];
        }
      }
      return result;
    },
    () => {
      const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      const numbers = '0123456789';
      let result = '';
      const numLetters = Math.floor(Random() * 4) + 2;
      for (let i = 0; i < numLetters; i++) {
        result += letters[Math.floor(Random() * letters.length)];
      }
      for (let i = 0; i < 5 - numLetters; i++) {
        result += numbers[Math.floor(Random() * numbers.length)];
      }
      return result;
    },
  ];
  return formats[Math.floor(Random() * formats.length)]();
}

// Uppercase every first letter of each word
export function Capitalise(what: string) {
  return what
    .split(' ')
    .map((w) => w.charAt(0).toUpperCase() + w.slice(1))
    .join(' ');
}

export function GenerateImageUrl(topic: string) {
  // Use unsplash to generate a 300x300 image based on the topic, and fixed with a seed
  return `https://source.unsplash.com/300x300/?${topic}&${Math.floor(
    Random() * 100000
  )}`;
}

export function GenerateUPCBarcode() {
  // Generate a random UPC barcode
  let result = '';
  for (let i = 0; i < 12; i++) {
    result += Math.floor(Random() * 10);
  }
  return result;
}

function randomString(n: number) {
  let result = '';
  const characters = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  for (let i = 0; i < n; i++) {
    result += characters.charAt(Math.floor(Random() * characters.length));
  }
  return result;
}

export function GenerateAsin() {
  return randomString(10);
}

export function GeneratePartNumber() {
  // Serial number with a random format - either pure number, mix of numbers and letters, or 2-5 letters followed by numbers
  const formats = [
    () => {
      return Math.floor(Random() * 10000000000000000000).toString();
    },
    () => {
      const prefix = randomString(1 + ~~(4 * Random()));
      const numbers = randomString(1 + ~~(4 * Random()));
      const suffix = randomString(1 + ~~(4 * Random()));
      return `${prefix}-${numbers}-${suffix}`;
    },
    () => {
      const prefix = randomString(1);
      const numbers = randomString(4);
      return `${prefix}-${numbers}`;
    },
    () => {
      const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      const numbers = '0123456789';
      let result = '';
      for (let i = 0; i < 20; i++) {
        if (Random() > 0.5) {
          result += letters[Math.floor(Random() * letters.length)];
        } else {
          result += numbers[Math.floor(Random() * numbers.length)];
        }
      }
      return result;
    },
    () => {
      const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      const numbers = '0123456789';
      let result = '';
      const numLetters = Math.floor(Random() * 4) + 2;
      for (let i = 0; i < numLetters; i++) {
        result += letters[Math.floor(Random() * letters.length)];
      }
      for (let i = 0; i < 5 - numLetters; i++) {
        result += numbers[Math.floor(Random() * numbers.length)];
      }
      return result;
    },
  ];
  return formats[Math.floor(Random() * formats.length)]();
}

export function GeneratePrice(): Money {
  return {
    amount: Math.floor(Random() * 1000),
    currency: 'AUD',
  };
}

export function GenerateWeighInGrams() {
  return Math.floor(Random() * 10 * 1000);
}

export function GenerateDimensions(): VolumeDimensions {
  // all fake dimensions are between 6 and 6.999999 meters
  return {
    length: 6000 + Math.floor(Random() * 1000),
    width: 6000 + Math.floor(Random() * 1000),
    height: 6000 + Math.floor(Random() * 1000),
  };
}

export function GenerateSplitPersonName(): SplitName {
  // ChatGPT made this for me.

  const maleFirstNames = [
    'Liam',
    'Noah',
    'Oliver',
    'Ethan',
    'Aiden',
    'Caleb',
    'Benjamin',
    'Lucas',
    'Mason',
    'Elijah', // Western names
    'Aarav',
    'Aryan',
    'Vihaan',
    'Advait',
    'Reyansh',
    'Vivaan',
    'Sai',
    'Arnav',
    'Ishaan',
    'Siddharth', // Indian names
  ];

  const femaleFirstNames = [
    'Emma',
    'Olivia',
    'Ava',
    'Isabella',
    'Sophia',
    'Mia',
    'Charlotte',
    'Amelia',
    'Harper',
    'Evelyn', // Western names
    'Aaradhya',
    'Ananya',
    'Aarohi',
    'Anika',
    'Avni',
    'Ira',
    'Myra',
    'Saanvi',
    'Siya',
    'Vaishnavi', // Indian names
  ];

  const japaneseFirstNames = [
    'Haruto',
    'Yuto',
    'Riku',
    'Kaito',
    'Haruki',
    'Sora',
    'Kota',
    'Hiroto',
    'Ren',
    'Kakeru', // Japanese male names
    'Yui',
    'Sakura',
    'Aoi',
    'Hina',
    'Mio',
    'Yuna',
    'Yua',
    'Kokona',
    'Kanna',
    'Riko', // Japanese female names
  ];

  const europeanFirstNames = [
    'Adrian',
    'Gabriel',
    'Andreas',
    'David',
    'Erik',
    'Florian',
    'Jan',
    'Jonas',
    'Lucas',
    'Matthias', // German names
    'Giovanni',
    'Marco',
    'Alessandro',
    'Davide',
    'Filippo',
    'Federico',
    'Lorenzo',
    'Riccardo',
    'Simone',
    'Vittorio', // Italian names
    'Pierre',
    'Antoine',
    'Guillaume',
    'Lucas',
    'Hugo',
    'Louis',
    'Théo',
    'Alexandre',
    'Baptiste',
    'Jules', // French names
  ];

  const indianFirstNames = [
    'Aarav',
    'Aaradhya',
    'Aarya',
    'Aashvi',
    'Aayushi',
    'Abhay',
    'Aditi',
    'Advait',
    'Alisha',
    'Anaya',
    'Aniket',
    'Ankit',
    'Ansh',
    'Anushka',
    'Aryan',
    'Arya',
    'Asha',
    'Avikar',
    'Avishi',
    'Ayush',
    'Devanshi',
    'Dhruv',
    'Diya',
    'Divya',
    'Hemant',
    'Ira',
    'Isha',
    'Ishan',
    'Jhanvi',
    'Kabir',
    'Kavya',
    'Kritika',
    'Krishna',
    'Madhav',
    'Madhuri',
    'Manav',
    'Manisha',
    'Meera',
    'Nandini',
    'Navya',
    'Neha',
    'Nikita',
    'Nisha',
    'Omkar',
    'Pranav',
    'Rajat',
    'Ritika',
    'Riya',
    'Sakshi',
    'Samarth',
  ];

  const firstNames = [
    ...maleFirstNames,
    ...femaleFirstNames,
    ...japaneseFirstNames,
    ...europeanFirstNames,
    ...indianFirstNames,
  ];

  const europeanLastNames = [
    'Müller',
    'Schmidt',
    'Schneider',
    'Fischer',
    'Weber',
    'Meyer',
    'Wagner',
    'Becker',
    'Hoffmann',
    'Schulz',
    'Lopez',
    'Garcia',
    'Martinez',
    'Gonzalez',
    'Rodriguez',
    'Fernandez',
    'Perez',
    'Sanchez',
    'Ramirez',
    'Alonso',
    'Rossi',
    'Ricci',
    'Conti',
    'Esposito',
    'Galli',
    'Marchetti',
    'Moretti',
    'Rizzo',
    'Lombardi',
    'Bianchi',
    'Gagne',
    'Lavoie',
    'Rousseau',
    'Boucher',
    'Bergeron',
    'Leclerc',
    'Lefebvre',
    'Beaulieu',
    'Perron',
    'Pelletier',
    'Garczynski',
    'Kowalski',
    'Nowak',
    'Lewandowski',
    'Wojcik',
    'Kaczmarek',
    'Mazur',
    'Krawczyk',
    'Adamczyk',
    'Pawlak',
  ];

  const lastNames = [
    ...europeanLastNames,
    'Smith',
    'Johnson',
    'Williams',
    'Jones',
    'Brown',
    'Davis',
    'Miller',
    'Wilson',
    'Moore',
    'Taylor', // Western last names
    'Patel',
    'Shah',
    'Kumar',
    'Singh',
    'Jha',
    'Yadav',
    'Rao',
    'Gupta',
    'Reddy',
    'Mishra', // Indian last names
    'Sato',
    'Suzuki',
    'Takahashi',
    'Tanaka',
    'Watanabe',
    'Ito',
    'Nakamura',
    'Kobayashi',
    'Saito',
    'Yamamoto', // Japanese last names
    'Müller',
    'Schmidt',
    'Schneider',
    'Fischer',
    'Weber',
    'Meyer',
    'Wagner',
    'Becker',
    'Hoffmann',
    'Schulz', // German last names
  ];

  return {
    first: firstNames[Math.floor(Random() * firstNames.length)],
    last: lastNames[Math.floor(Random() * lastNames.length)],
  };
}

export function GenerateFullPersonName() {
  const name = GenerateSplitPersonName();
  return `${name.first} ${name.last}`;
}

export function GenerateEmailAddress(name: string) {
  // ChatGPT Strikes Again
  const emailServers = [
    'gmail.com',
    'hotmail.com',
    'yahoo.com',
    'outlook.com',
    'aol.com',
  ];
  const companyNames = [
    'acme',
    'globex',
    'initech',
    'weyland-yutani',
    'wayneenterprises',
    'peakventures',
    'brightbyte',
    'agilecore',
    'appliedlogic',
    'quantumtech',
    'neocorp',
    'bluesky',
    'techwave',
    'cloud9',
    'cosmictech',
    'upliftlabs',
    'webgenius',
    'innovatec',
    'pinnacle',
    'techsavvy',
    'futureforge',
    'futuresight',
    'newwave',
    'netwise',
    'smarttech',
    'flowtech',
    'logicloop',
    'moonlight',
    'brightmind',
    'mindwave',
    'opentech',
    'primecode',
    'codeforge',
    'redwood',
    'redshift',
    'skyscope',
    'stellar',
    'sunrise',
    'sunburst',
    'techjump',
    'techsphere',
    'webwave',
    'virtuoso',
    'wisetech',
    'zenith',
    'arrowhead',
    'bluemoon',
    'brightpath',
    'bytecraft',
    'cloud9',
    'cosmotech',
    'cybercore',
    'digitron',
    'elemental',
    'futuretech',
    'growthtech',
    'hypernova',
    'innovative',
    'jumpstart',
    'logicmind',
    'neotech',
    'oceanic',
    'peaktech',
    'protech',
    'quantumcore',
    'redsky',
    'safetech',
    'skytech',
    'sparktech',
    'speedtech',
    'starlight',
    'techbox',
    'techsmith',
    'techspark',
    'techvision',
    'uplifttech',
    'virtuetech',
    'wavelight',
    'webcore',
    'webmind',
    'webworks',
    'wisebyte',
    'wisehub',
    'zenbyte',
    'zenhub',
    'alloytech',
    'arctech',
    'brightbyte',
    'bytepath',
    'codepath',
    'coraltech',
    'deltatech',
    'elevatech',
    'futurepath',
    'growthpath',
    'horizontech',
    'innovatech',
    'jumpbyte',
    'neocore',
    'novapath',
    'oceanpath',
    'peakpath',
    'propath',
    'quantumbyte',
    'reachtech',
    'shifttech',
    'smartpath',
    'sparkpath',
    'techbyte',
    'techpath',
    'techreach',
    'techspectrum',
    'virtuopath',
  ];
  const randomServer = emailServers[Math.floor(Random() * emailServers.length)];
  const randomCompany =
    companyNames[Math.floor(Random() * companyNames.length)];
  const firstName = name.split(' ')[0];
  const lastName = name.split(' ')[1] || firstName;
  const randomNumber = Math.floor(Random() * 1000);
  const possibleFormats = [
    `${firstName.toLowerCase()}.${lastName.toLowerCase()}`,
    `${firstName.toLowerCase().substring(0, 1)}${lastName.toLowerCase()}`,
    `${firstName.toLowerCase()}${randomNumber}`,
    `${firstName.toLowerCase()}_${lastName.toLowerCase()}`,
    `${firstName.toLowerCase()}-${lastName.toLowerCase()}`,
    `${lastName.toLowerCase()}.${firstName.toLowerCase()}`,
    `${lastName.toLowerCase()}_${firstName.toLowerCase()}`,
  ];

  const tlds = [
    '.com',
    '.co.uk',
    '.net',
    '.org',
    '.io',
    '.dev',
    '.app',
    '.xyz',
    '.info',
    '.me',
  ];
  const randomTLD = tlds[Math.floor(Random() * tlds.length)];

  const randomFormat =
    possibleFormats[Math.floor(Random() * possibleFormats.length)];
  const companyEmail = `${randomFormat}@${randomCompany}${randomTLD}`;
  const personalEmail = `${randomFormat}${randomNumber}@${randomServer}`;
  return Random() < 0.5 ? companyEmail : personalEmail;
}

export function GenerateAddress(): Address {
  const streetNames = [
    'Main',
    'Park',
    'Oak',
    'Maple',
    'Cedar',
    'Elm',
    'Pine',
    'Birch',
    'Cherry',
    'Willow',
  ];
  const townNames = [
    'Springfield',
    'Windsor',
    'Fairview',
    'Mapleton',
    'Hillside',
    'Oakdale',
    'Pleasantville',
    'Meadowbrook',
  ];
  const countyNames = [
    'Kent',
    'Essex',
    'Yorkshire',
    'Sussex',
    'Devon',
    'Lancashire',
    'Surrey',
    'Cornwall',
    'Norfolk',
  ];
  const countryNames = [
    'United States',
    'United Kingdom',
    'Canada',
    'Australia',
    'New Zealand',
    'South Africa',
    'France',
    'Germany',
    'Spain',
    'Italy',
  ];
  const line2Values = [
    `Suite ${Math.floor(Random() * 1000) + 1}`,
    `Apt. ${Math.floor(Random() * 20) + 1}${String.fromCharCode(
      64 + Math.floor(Random() * 26)
    )}`,
    `Top Floor`,
    `${Random() < 0.5 ? 'Lower' : 'Upper'} Level`,
    `West Wing`,
    `East Wing`,
    `Tower ${String.fromCharCode(65 + Math.floor(Random() * 26))}`,
    `Building ${Math.floor(Random() * 10) + 1}`,
    `Penthouse`,
    `Basement`,
    `The Lofts`,
    `The Village`,
    `The Heights`,
    `The Gardens`,
  ];

  const randomStreetNumber = Math.floor(Random() * 1000) + 1;
  const randomStreetName =
    streetNames[Math.floor(Random() * streetNames.length)];
  const randomTownName = townNames[Math.floor(Random() * townNames.length)];
  const randomCountyName =
    countyNames[Math.floor(Random() * countyNames.length)];
  const randomCountryName =
    countryNames[Math.floor(Random() * countryNames.length)];
  const randomPostcode = Math.floor(Random() * 90000) + 10000;
  const randomLine2 = line2Values[Math.floor(Random() * line2Values.length)];

  const address: Address = {
    line1: `${randomStreetNumber} ${randomStreetName} Street`,
    line2: Random() > 0.6 ? randomLine2 : '',
    company: '',
    town: randomTownName,
    postcode: `${randomPostcode}`,
    county: randomCountyName,
    country: randomCountryName,
  };

  return address;
}

export function GenerateRetailAddress(): Address {
  const streetTypes = [
    'Street',
    'Road',
    'Avenue',
    'Lane',
    'Drive',
    'Boulevard',
    'Place',
  ];
  const suffixes = ['North', 'South', 'East', 'West'];
  const towns = [
    'Metropolis',
    'Central City',
    'Gotham',
    'Springfield',
    'South Park',
    'Bedrock',
  ];
  const counties = [
    'Big City',
    'Smallville',
    'Sunnydale',
    'Hill Valley',
    'Riverdale',
    'Pawnee',
  ];
  const countries = ['USA', 'Canada', 'UK', 'Australia', 'New Zealand'];

  const streetNumber = Math.floor(Random() * 999) + 1;
  const streetType = streetTypes[Math.floor(Random() * streetTypes.length)];
  const suffix = suffixes[Math.floor(Random() * suffixes.length)];
  const town = towns[Math.floor(Random() * towns.length)];
  const county = counties[Math.floor(Random() * counties.length)];
  const country = countries[Math.floor(Random() * countries.length)];
  const postcode = Math.floor(Random() * 90000) + 10000;
  const line1 = `${streetNumber} ${suffix} ${streetType}`;
  const line2Values = ['Suite #', 'Unit #', 'Floor #', 'Shop #', 'Store #'];
  const line2 = `${line2Values[Math.floor(Random() * line2Values.length)]}${
    Math.floor(Random() * 999) + 1
  }`;

  return {
    line1,
    line2,
    company: '',
    town,
    postcode: `${postcode}`,
    county,
    country,
  };
}

export function GeneratePhoneNumber(): string {
  const formats = [
    '+1 (###) ###-####', // North America
    '### ### ####', // UK
    '## #### ####', // Australia
    '## ### ####', // New Zealand
    '+## ### #######', // South Africa
    '###-###-####', // Japan
    '## ####-####', // Brazil
    '## ### ####', // Mexico
    '+## # #######', // France
    '+## ### ### ###', // Germany
    '## ### ## ##', // Netherlands
    '+## ### ### ###', // Spain
    '## ### ## ##', // Italy
  ];

  const format = formats[Math.floor(Random() * formats.length)];
  let phoneNumber = '';
  for (let i = 0; i < format.length; i++) {
    const char = format[i];
    if (char === '#') {
      phoneNumber += Math.floor(Random() * 10);
    } else {
      phoneNumber += char;
    }
  }
  return phoneNumber;
}

export function GenerateRepairerName(): string {
  const prefixes = [
    'The',
    'All',
    'Master',
    'Ace',
    'Pro',
    'Total',
    'Expert',
    'Precision',
    'First',
    'Top',
  ];
  const suffixes = [
    'Works',
    'Services',
    'Center',
    'Clinic',
    'Repair',
    'Lab',
    'Fix',
    'Shop',
    'Tech',
    'Solutions',
  ];

  const prefix =
    Random() < 0.4
      ? GenerateFullPersonName()
      : prefixes[Math.floor(Random() * prefixes.length)];
  const suffix = suffixes[Math.floor(Random() * suffixes.length)];

  const name = `${prefix} ${suffix}`;

  return name;
}

export function GenerateManufacturerName(): string {
  const prefix = [
    'Sino',
    'Great',
    'Eastern',
    'Red',
    'Dragon',
    'Golden',
    'China',
    'Lucky',
    'Bright',
  ];
  const middle = [
    'Tech',
    'Star',
    'King',
    'Power',
    'Sun',
    'Link',
    'Bridge',
    'Harmony',
    'Peak',
  ];
  const suffix = [
    'Electronics',
    'Industries',
    'Group',
    'Enterprises',
    'Technology',
    'Innovations',
    'Manufacturing',
    'Corporation',
    'Ventures',
  ];

  const randomPrefix = prefix[Math.floor(Random() * prefix.length)];
  const randomMiddle = middle[Math.floor(Random() * middle.length)];
  const randomSuffix = suffix[Math.floor(Random() * suffix.length)];

  return `${randomPrefix}${randomMiddle} ${randomSuffix}`;
}

export function GenerateIndustrialAddress(): Address {
  const townSuffixes = [
    'zhou',
    'shan',
    'cheng',
    'jing',
    'hu',
    'dao',
    'du',
    'gang',
  ];
  const prefixes = [
    'Nan',
    'Dong',
    'Xi',
    'Bei',
    'Chang',
    'Wu',
    'Qian',
    'Hua',
    'Jin',
    'Jie',
  ];
  const middle = [
    'jin',
    'feng',
    'hua',
    'yuan',
    'yue',
    'xiang',
    'dong',
    'xi',
    'cheng',
    'ji',
  ];
  const suffix = [
    'lu',
    'luo',
    'bei',
    'nan',
    'xi',
    'dong',
    'men',
    'dao',
    'jie',
    'qu',
  ];

  const randomTownSuffix =
    townSuffixes[Math.floor(Random() * townSuffixes.length)];
  const randomPrefix = prefixes[Math.floor(Random() * prefixes.length)];
  const randomMiddle = middle[Math.floor(Random() * middle.length)];
  const randomSuffix = suffix[Math.floor(Random() * suffix.length)];

  const line1 = `${randomPrefix}${randomMiddle}${randomSuffix}`;
  const line2 = '';
  const town = `${randomPrefix}${randomTownSuffix} town`;
  const postcode = Math.floor(Random() * 90000) + 10000;
  const county = 'county';
  const country = 'China';

  return {
    line1,
    line2,
    company: '',
    town,
    postcode: postcode.toString(),
    county,
    country,
  };
}

/* -------------------------------------------------------------------------- */
/*                    iWarranty bizproc specific generators                   */
/* -------------------------------------------------------------------------- */

// DC: Beau, tell me to get this outta here if there's a better place to put it

/* --------------------- Business Invite generators -------------------- */
export function GenerateTenantLink(): string {
  const tenants = [
    'SHELTA',
    'UNIONAUDIO',
    'WIRE',
    'CHARLIEOVEN',
    'ISCOOT',
    'ABBYSJEWELRY',
  ];
  return tenants[Math.floor(Random() * tenants.length)];
}

export function GenerateInvitedUserType(): BusinessUserInviteType {
  const enumValues = Object.values(BusinessUserInviteType);
  const index = Math.floor(Math.random() * enumValues.length);

  return enumValues[index];
}

export function GeneratePersonaAssignmentArrayForInvites(): PersonaTypeSingleLetter[] {
  const personaAssignments = [
    ['R'],
    ['S'],
    ['R', 'S'],
  ] as PersonaTypeSingleLetter[][];

  return personaAssignments[Math.floor(Random() * personaAssignments.length)];
}
