import {
  Button,
  Input,
  UploadedFileCardv2,
} from '@rabbit/elements/shared-components';
import {
  CaseflowContext,
  CaseflowInterface,
} from '@rabbit/sage/context/CaseflowContext';
import { useAppInfo } from '@rabbit/sage/utils/helpers';
import { Form, Formik, FormikProps } from 'formik';
import { useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { UploadedFileCategories } from '@rabbit/elements/shared-types';
import {
  CFCF_ExternalRepairInvoice,
  ExternalRepairInvoiceStatus,
} from '@rabbit/bizproc/core';
import { PersonaTypeSingleLetter } from '@rabbit/data/types';

interface FormValuesShape {
  //invoice: UserUploadedDocument;
  cost: {
    amount: string;
    currency: string;
  };
  rejection_reason?: string;
  external_repairer_comment?: string;
  internal_comment?: string;
}

interface AddEditInvoiceFormWarrantorProps {
  handleClose: (submitted?: boolean) => void;
  data?: CFCF_ExternalRepairInvoice;
}
export default function AddEditInvoiceFormWarrantor({
  handleClose,
  data,
}: AddEditInvoiceFormWarrantorProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showInternalComment, setShowInternalComment] = useState(false);
  const {
    alterCaseFacts,
    caseFacts,
    operatingPersona,
    executeAction,
    moveSpotlight,
  } = useContext(CaseflowContext) || ({} as CaseflowInterface);

  const formikRef = useRef<FormikProps<FormValuesShape> | null>(null);

  const disableInputs =
    data?.status === ExternalRepairInvoiceStatus.APPROVED ||
    data?.status === ExternalRepairInvoiceStatus.REJECTED;

  const appInfo = useAppInfo();
  const { t } = useTranslation();

  async function reviewInvoice(
    values: FormValuesShape,
    response: 'approved' | 'rejected'
  ) {
    setIsSubmitting(true);
    let factsToAlter: any = {
      ...(values.internal_comment && {
        internal_comment: {
          comment: values.internal_comment,
          author: operatingPersona,
        },
      }),
    };

    if (response === 'approved') {
      factsToAlter = {
        ...factsToAlter,
        external_repair_invoices: caseFacts?.external_repair_invoices?.map(
          (invoice) => {
            if (invoice.timestamp === data?.timestamp) {
              return {
                ...invoice,
                status: ExternalRepairInvoiceStatus.APPROVED,
              };
            }
            return invoice;
          }
        ),
      };
      await moveSpotlight(PersonaTypeSingleLetter.Repairer);
      await executeAction('approve_invoice');
      await alterCaseFacts(factsToAlter);
    }

    if (response === 'rejected') {
      factsToAlter = {
        ...factsToAlter,
        external_repair_invoices: caseFacts?.external_repair_invoices?.map(
          (invoice) => {
            if (invoice.timestamp === data?.timestamp) {
              return {
                ...invoice,
                status: ExternalRepairInvoiceStatus.REJECTED,
                rejectionReason: values.rejection_reason,
              };
            }
            return invoice;
          }
        ),
      };
      await executeAction('reject_invoice');
      await alterCaseFacts(factsToAlter);
      await moveSpotlight(PersonaTypeSingleLetter.Repairer);
    }

    setIsSubmitting(false);
    handleClose(true);
  }

  const initialValues: FormValuesShape = {
    cost: {
      amount: data?.totalCost.amount.toString() ?? '',
      currency: data?.totalCost.currency ?? appInfo.currency,
    },
    external_repairer_comment: '',
    rejection_reason: data?.rejectionReason ?? '',
  };

  const warrantorValidationSchema = Yup.object().shape({
    rejection_reason: Yup.string().trim(),
    internal_comment: Yup.string().trim(),
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={() => void 0}
      validationSchema={warrantorValidationSchema}
      formikRef={formikRef}
    >
      {(props) => {
        return (
          <Form className="flex flex-col gap-4 p-4">
            {data && (
              <UploadedFileCardv2
                kind="existing"
                data={data.file}
                docid=""
                uploadCategory={UploadedFileCategories.ExternalRepairInvoices}
              />
            )}
            <Input
              type="currency"
              name="cost"
              label={t('Cost of repair')}
              settings={{
                id: 'cost',
                placeholder: t('Cost of repair'),
                currency: data?.totalCost.currency ?? appInfo.currency,
                disabled: true,
              }}
            />

            <Input
              type="textarea"
              label={t('Rejection reason')}
              name="rejection_reason"
              settings={{
                id: 'rejection_reason',
                allowSpecialCharacter: true,
                disabled: disableInputs,
              }}
            />

            {!disableInputs && !showInternalComment && (
              <div className="mt-4">
                <Button
                  kind="outline"
                  type="button"
                  className="w-full"
                  onClick={() => setShowInternalComment(true)}
                >
                  {t('Add internal comment')}
                </Button>
              </div>
            )}
            {showInternalComment && (
              <div className="mt-3">
                <Input
                  type="rich-text"
                  label={t('Internal comment')}
                  name={'internal_comment'}
                  settings={{
                    id: 'internal_comment',
                    allowSpecialCharacter: true,
                  }}
                />
              </div>
            )}
            {!disableInputs && (
              <div className="mt-4 grid grid-cols-2 gap-3">
                <Button
                  kind="primary"
                  disabled={isSubmitting}
                  onClick={() => reviewInvoice(props.values, 'approved')}
                >
                  {t('Approve invoice')}
                </Button>
                <Button
                  kind="red"
                  onClick={() => reviewInvoice(props.values, 'rejected')}
                  disabled={isSubmitting}
                >
                  {t('Reject invoice')}
                </Button>
              </div>
            )}
            {disableInputs && (
              <div className="mt-4 grid gap-3">
                <Button
                  kind="outline_red"
                  onClick={() => handleClose()}
                  disabled={isSubmitting}
                >
                  {t('Close')}
                </Button>
              </div>
            )}
          </Form>
        );
      }}
    </Formik>
  );
}
