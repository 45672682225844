import { CaseFlowConfiguration } from '@rabbit/data/types';
import { CaseFlowConfigurationRegistry } from '../configuration';
import {
  CaseFlowConfig_Facts_DocLinks,
  CaseFlowConfig_Facts_Comments,
  CaseFlowConfig_Facts_IssueDetails,
  CaseFlowConfig_Facts_Delegates,
  CaseFlowConfig_Facts_Holding,
  CaseFlowConfig_Facts_Consumer,
  CaseFlowConfig_Facts_CaseStatus,
  CaseFlowConfig_Facts_UploadedFiles,
  CaseFlowConfig_Facts_PartnerData,
  CaseFlowConfig_Facts_CostLogging,
} from '../_common/facts';
import {
  CaseflowActorTypes,
  CaseFlowCaseStations,
  CaseflowCaseTypes,
} from '../_common/types';
import {
  CaseFlowActions_Fatbikes_ExternalRepairer,
  CaseFlowActions_Fatbikes_Warrantor,
} from './types';
import i18next from 'i18next';

/* -------------------------------------------------------------------------- */
/*                                   Actors                                   */
/* -------------------------------------------------------------------------- */

const actors: CaseFlowConfiguration['actors'] = {
  consumer: {
    tech_description: 'holder of broken item',
  },
  warrantor: {
    tech_description: 'entity responsible for the warranty',
    proxy: {
      facts: [
        'administrative_cost_data',
        'parts_used_cost_data',
        'repair_time_data',
        'shipping_cost_data',
        'travel_cost_data',
        'other_cost_data',
        'consumer_holding',
        'consumer_persona_id',
        'holding_vendable_id',
        'consumer_issue_type',
        'consumer_issue_type_ref',
        'consumer_issue_description',
        'consumer_claim_evidence',
        'consumer_proof_of_purchase',
        'warranty_link',
        'vendable_version_number',
        'final_assessment',
        'goodwill_warranty_initial',
        'goodwill_warranty_final',
        'delegate_repairer_id',
        'delegate_repairer_name',
        'consumer_name',
        'consumer_holding_name',
        'holding_warranty_term',
        'purchase_date',
        'consumer_email',
        'external_repairer_comment',
        'external_repair_invoices',
        'serial_number',
        'serial_number_proof',
        'claim_outcome',
        'warranty_status',
      ],
    },
  },
  external_repairer: {
    tech_description: 'external entity responsible for the repair of the item',
  },
};

/* -------------------------------------------------------------------------- */
/*                                    Facts                                   */
/* -------------------------------------------------------------------------- */

const fatbikesFacts: CaseFlowConfiguration['facts'] = {
  serial_number: {
    type: 'String',
    label: i18next.t('general.serialNumber'),
  },
  serial_number_proof: {
    type: 'UserUploadedDocument[]',
    label: i18next.t('general.serialNumberProof'),
  },
  external_repair_invoices: {
    type: 'CFCF_ExternalRepairInvoice[]',
    label: i18next.t('general.repairInvoice'),
  },
  external_repairer_comment: {
    type: 'String',
    label: i18next.t('general.externalRepairerComment'),
  },
  comment_to_external_repairer: {
    type: 'String',
    label: i18next.t('general.commentToExternalRepairer'),
  },
};

const facts: CaseFlowConfiguration['facts'] = {
  /* --------------------------- Fatbikes specific facts -------------------------- */
  ...fatbikesFacts,
  /* ------------------------------ Common facts ------------------------------ */
  ...CaseFlowConfig_Facts_CostLogging,
  ...CaseFlowConfig_Facts_DocLinks,
  ...CaseFlowConfig_Facts_Comments,
  ...CaseFlowConfig_Facts_IssueDetails,
  ...CaseFlowConfig_Facts_Delegates,
  ...CaseFlowConfig_Facts_Holding,
  ...CaseFlowConfig_Facts_Consumer,
  ...CaseFlowConfig_Facts_CaseStatus,
  ...CaseFlowConfig_Facts_UploadedFiles,
  ...CaseFlowConfig_Facts_PartnerData,
};

/* -------------------------------------------------------------------------- */
/*                                  Stations                                  */
/* -------------------------------------------------------------------------- */

const stations: CaseFlowConfiguration['stations'] = {
  _birth: {
    actions: {
      _onEnter: {
        available_to: [
          CaseflowActorTypes.CONSUMER,
          CaseflowActorTypes.EXTERNAL_REPAIRER,
          CaseflowActorTypes.WARRANTOR,
        ],
        steps: [
          {
            type: 'go_station',
            station: CaseFlowCaseStations.PRELIMINARY_ASSESSMENT,
          },
        ],
      },
    },
  },
  [CaseFlowCaseStations.PRELIMINARY_ASSESSMENT]: {
    actions: {
      [CaseFlowActions_Fatbikes_ExternalRepairer.RepairCovered]: {
        available_to: [
          CaseflowActorTypes.EXTERNAL_REPAIRER,
          CaseflowActorTypes.WARRANTOR,
        ],
        label: i18next.t('general.repairCoveredByWarranty'),
        steps: [
          {
            type: 'go_station',
            station: CaseFlowCaseStations.REPAIR_INVOICE_SUBMISSION,
          },
        ],
      },
      [CaseFlowActions_Fatbikes_ExternalRepairer.RepairNotCovered]: {
        available_to: [
          CaseflowActorTypes.EXTERNAL_REPAIRER,
          CaseflowActorTypes.WARRANTOR,
        ],
        label: i18next.t('general.outOfWarrantyRepair'),
        steps: [
          {
            type: 'go_station',
            station: CaseFlowCaseStations.IN_REPAIR,
          },
        ],
      },
    },
  },
  [CaseFlowCaseStations.REPAIR_INVOICE_SUBMISSION]: {
    actions: {
      [CaseFlowActions_Fatbikes_ExternalRepairer.SubmitInvoice]: {
        available_to: [CaseflowActorTypes.EXTERNAL_REPAIRER],
        label: i18next.t('general.submitInvoice'),
        steps: [
          {
            type: 'go_station',
            station: CaseFlowCaseStations.REPAIR_INVOICE_ASSESSMENT,
          },
        ],
      },
    },
  },
  [CaseFlowCaseStations.REPAIR_INVOICE_ASSESSMENT]: {
    actions: {
      // if warrantor approves we move to invoice accepted
      [CaseFlowActions_Fatbikes_Warrantor.ApproveInvoice]: {
        available_to: [CaseflowActorTypes.WARRANTOR],
        label: i18next.t('general.approveInvoice'),
        steps: [
          {
            type: 'go_station',
            station: CaseFlowCaseStations.INVOICE_ACCEPTED,
          },
        ],
      },

      // if we reject, we go to back to invoice submission
      [CaseFlowActions_Fatbikes_Warrantor.RejectInvoice]: {
        available_to: [CaseflowActorTypes.WARRANTOR],
        label: i18next.t('general.rejectInvoice'),
        steps: [
          // one day
          // {
          //   type: 'req_facts',
          //   facts: ['external_repair_invoices'],
          // },
          {
            type: 'go_station',
            station: CaseFlowCaseStations.REPAIR_INVOICE_SUBMISSION,
          },
        ],
      },
    },
  },
  [CaseFlowCaseStations.INVOICE_ACCEPTED]: {
    actions: {
      [CaseFlowActions_Fatbikes_ExternalRepairer.InitiateRepair]: {
        available_to: [CaseflowActorTypes.EXTERNAL_REPAIRER],
        label: i18next.t('general.initiateRepair'),
        steps: [
          {
            type: 'go_station',
            station: CaseFlowCaseStations.IN_REPAIR,
          },
        ],
      },
    },
  },
  [CaseFlowCaseStations.IN_REPAIR]: {
    actions: {
      [CaseFlowActions_Fatbikes_ExternalRepairer.MarkRepairComplete]: {
        available_to: [CaseflowActorTypes.EXTERNAL_REPAIRER],
        label: i18next.t('general.markRepairAsComplete'),
        steps: [
          {
            type: 'go_station',
            station: CaseFlowCaseStations.REPAIRED,
          },
        ],
      },
    },
  },
  [CaseFlowCaseStations.REPAIRED]: {
    actions: {
      [CaseFlowActions_Fatbikes_Warrantor.CloseCase]: {
        available_to: [
          CaseflowActorTypes.WARRANTOR,
          CaseflowActorTypes.EXTERNAL_REPAIRER,
        ],
        label: i18next.t('general.closeCase'),
        steps: [
          {
            type: 'go_station',
            station: CaseFlowCaseStations.CLOSED,
          },
        ],
      },
    },
  },
  [CaseFlowCaseStations.CLOSED]: {
    // No steps or actions are required. This station marks the end of Journey for a case
    actions: {},
  },
};

/* -------------------------------------------------------------------------- */
/*                               Global Actions                               */
/* -------------------------------------------------------------------------- */

const genericActions: CaseFlowConfiguration['global_actions'] = {
  contact_customer_generic: {
    label: i18next.t('general.contactCustomerGeneric'),
    available_to: [
      CaseflowActorTypes.WARRANTOR,
      CaseflowActorTypes.EXTERNAL_REPAIRER,
    ],
    steps: [
      {
        type: 'req_facts',
        facts: ['comment_to_customer'],
      },
    ],
  },
  internal_comment_generic: {
    label: i18next.t('general.leaveInternalComment'),
    available_to: [CaseflowActorTypes.WARRANTOR],
    steps: [
      {
        type: 'req_facts',
        facts: ['internal_comment'],
      },
    ],
  },
  assign_case: {
    label: i18next.t('general.assignCase'),
    available_to: [CaseflowActorTypes.WARRANTOR],
    params: {
      delegate_repairer_id: {
        type: 'String',
        label: i18next.t('general.delegateRepairerId'),
        required: true,
      },
      delegate_repairer_name: {
        type: 'String',
        label: i18next.t('genera.delegateRepairerName'),
        required: true,
      },
    },
    steps: [
      {
        type: 'set_fact',
        fact: 'delegate_repairer_id',
        value: '[[delegate_repairer_id]]',
      },
      {
        type: 'set_fact',
        fact: 'delegate_repairer_name',
        value: '[[delegate_repairer_name]]',
      },
      {
        type: 'set_actor',
        role: CaseflowActorTypes.EXTERNAL_REPAIRER,
        persona_value: '[[delegate_repairer_id]]',
      },
      {
        type: 'alter_spotlight',
        clear: true,
        add: '[[delegate_repairer_id]]',
      },
    ],
  },
  //Set the spotlight to point at the consumer
  spotlight_on_consumer: {
    label: i18next.t('general.spotlightOnConsumer'),
    available_to: [
      CaseflowActorTypes.WARRANTOR,
      CaseflowActorTypes.EXTERNAL_REPAIRER,
      CaseflowActorTypes.CONSUMER,
    ],
    params: {
      consumer: {
        type: 'String',
        label: i18next.t('general.consumerPersona'),
        required: true,
        get_from: ['fact:consumer_persona_id', 'actor:consumer'],
      },
    },
    steps: [
      {
        type: 'alter_spotlight',
        clear: true,
        add: '[[consumer]]',
      },
    ],
  },
  // Set the spotlight to point at the repairer, which may be the delegate repairer if it has been set as a fact
  spotlight_on_repairer: {
    label: i18next.t('general.spotlightOnRepairer'),
    available_to: [
      CaseflowActorTypes.WARRANTOR,
      CaseflowActorTypes.EXTERNAL_REPAIRER,
      CaseflowActorTypes.CONSUMER,
    ],
    params: {
      external_repairer: {
        type: 'String',
        label: 'Repairer persona',
        required: true,
        get_from: ['fact:delegate_repairer_id', 'actor:external_repairer'],
      },
    },
    steps: [
      {
        type: 'alter_spotlight',
        clear: true,
        add: '[[external_repairer]]',
      },
    ],
  },
  spotlight_on_warrantor: {
    label: i18next.t('general.spotlightOnWarrantor'),
    available_to: [
      CaseflowActorTypes.WARRANTOR,
      CaseflowActorTypes.EXTERNAL_REPAIRER,
      CaseflowActorTypes.CONSUMER,
    ],
    params: {
      warrantor: {
        type: 'String',
        label: 'Warrantor persona',
        required: true,
        get_from: ['actor:warrantor'],
      },
    },
    steps: [
      {
        type: 'alter_spotlight',
        clear: true,
        add: '[[warrantor]]',
      },
    ],
  },
};

/* -------------- Actions for moving the case between stations -------------- */
const stationMovementActions: CaseFlowConfiguration['global_actions'] = {
  [CaseFlowActions_Fatbikes_Warrantor.MoveToPreliminaryAssessment]: {
    label: i18next.t('general.moveToPreliminaryAssessment'),
    available_to: [CaseflowActorTypes.WARRANTOR],
    steps: [
      {
        type: 'go_station',
        station: CaseFlowCaseStations.PRELIMINARY_ASSESSMENT,
      },
    ],
  },
  [CaseFlowActions_Fatbikes_Warrantor.MoveToRepairInvoiceSubmission]: {
    label: i18next.t('general.moveToRepairInvoiceSubmission'),
    available_to: [CaseflowActorTypes.WARRANTOR],
    steps: [
      {
        type: 'go_station',
        station: CaseFlowCaseStations.REPAIR_INVOICE_SUBMISSION,
      },
    ],
  },
  [CaseFlowActions_Fatbikes_Warrantor.MoveToRepairInvoiceAssessment]: {
    label: i18next.t('general.moveToRepairInvoiceAssessment'),
    available_to: [CaseflowActorTypes.WARRANTOR],
    steps: [
      {
        type: 'go_station',
        station: CaseFlowCaseStations.REPAIR_INVOICE_ASSESSMENT,
      },
    ],
  },
  [CaseFlowActions_Fatbikes_Warrantor.MoveToInvoiceAccepted]: {
    label: i18next.t('general.moveToInvoiceAccepted'),
    available_to: [CaseflowActorTypes.WARRANTOR],
    steps: [
      {
        type: 'go_station',
        station: CaseFlowCaseStations.INVOICE_ACCEPTED,
      },
    ],
  },
  [CaseFlowActions_Fatbikes_Warrantor.MoveToInRepair]: {
    label: i18next.t('general.moveToInRepair'),
    available_to: [CaseflowActorTypes.WARRANTOR],
    steps: [
      {
        type: 'go_station',
        station: CaseFlowCaseStations.IN_REPAIR,
      },
    ],
  },
  [CaseFlowActions_Fatbikes_Warrantor.MoveToRepaired]: {
    label: i18next.t('general.moveToRepaired'),
    available_to: [CaseflowActorTypes.WARRANTOR],
    steps: [
      {
        type: 'go_station',
        station: CaseFlowCaseStations.REPAIRED,
      },
    ],
  },
  [CaseFlowActions_Fatbikes_Warrantor.MoveToClosed]: {
    label: i18next.t('general.moveToClosed'),
    available_to: [CaseflowActorTypes.WARRANTOR],
    steps: [
      {
        type: 'go_station',
        station: CaseFlowCaseStations.CLOSED,
      },
    ],
  },
};

/* --------------------- Actions for logging claim costs -------------------- */
const costLoggingActions: CaseFlowConfiguration['global_actions'] = {
  /* -------------------------------------------------------------------------- */
  /*                                  Warrantor                                 */
  /* -------------------------------------------------------------------------- */
  /* --------------------------- Administrative time -------------------------- */
  log_costs_administrative_time: {
    available_to: [
      CaseflowActorTypes.WARRANTOR,
      CaseflowActorTypes.EXTERNAL_REPAIRER,
    ],
    label: i18next.t('general.logCostsAdministrativeTime'),
    params: {
      administrative_cost_data: {
        type: 'CFCF_AdministrativeCostLog',
        label: i18next.t('general.administrativeTimeCost'),
        required: true,
      },
      internal_comment: {
        type: 'String',
        label: i18next.t('general.internalComment'),
      },
    },
    steps: [
      {
        type: 'set_fact',
        fact: 'administrative_cost_data',
        value: '[[administrative_cost_data]]',
      },
      {
        type: 'set_fact',
        fact: ' internal_comment',
        value: '[[internal_comment]]',
      },
    ],
  },
  /* ------------------------------- Parts used ------------------------------- */
  log_costs_parts_used: {
    available_to: [
      CaseflowActorTypes.WARRANTOR,
      CaseflowActorTypes.EXTERNAL_REPAIRER,
    ],
    label: i18next.t('general.logCostsPartsUsed'),
    params: {
      parts_used_cost_data: {
        type: 'CFCF_PartsUsed',
        label: i18next.t('general.partsUsedCost'),
        required: true,
      },
      internal_comment: {
        type: 'String',
        label: i18next.t('general.internalComment'),
      },
    },
    steps: [
      {
        type: 'set_fact',
        fact: 'parts_used_cost_data',
        value: '[[parts_used_cost_data]]',
      },
      {
        type: 'set_fact',
        fact: 'internal_comment',
        value: '[[internal_comment]]',
      },
    ],
  },
  /* ------------------------------- Repair time ------------------------------ */
  log_costs_repair_time: {
    available_to: [
      CaseflowActorTypes.WARRANTOR,
      CaseflowActorTypes.EXTERNAL_REPAIRER,
    ],
    label: i18next.t('general.logCostsRepairTime'),
    params: {
      repair_time_data: {
        type: 'CFCF_RepairTimeLog',
        label: i18next.t('general.repairTimeCost'),
        required: true,
      },
      internal_comment: {
        type: 'String',
        label: i18next.t('general.internalComment'),
      },
    },
    steps: [
      {
        type: 'set_fact',
        fact: 'repair_time_data',
        value: '[[repair_time_data]]',
      },
      {
        type: 'set_fact',
        fact: 'internal_comment',
        value: '[[internal_comment]]',
      },
    ],
  },
  /* -------------------------------- Shipping -------------------------------- */
  log_costs_shipping: {
    available_to: [
      CaseflowActorTypes.WARRANTOR,
      CaseflowActorTypes.EXTERNAL_REPAIRER,
    ],
    label: i18next.t('general.logCostsShipping'),
    params: {
      shipping_cost_data: {
        type: 'CFCF_ShippingCostLog',
        label: i18next.t('general.shippingCost'),
        required: true,
      },
      internal_comment: {
        type: 'String',
        label: i18next.t('general.internalComment'),
      },
    },
    steps: [
      {
        type: 'set_fact',
        fact: 'shipping_cost_data',
        value: '[[shipping_cost_data]]',
      },
      {
        type: 'set_fact',
        fact: 'internal_comment',
        value: '[[internal_comment]]',
      },
    ],
  },
  /* --------------------------------- Travel -------------------------------- */
  log_costs_travel: {
    available_to: [
      CaseflowActorTypes.WARRANTOR,
      CaseflowActorTypes.EXTERNAL_REPAIRER,
    ],
    label: i18next.t('general.logCostsTravel'),
    params: {
      travel_cost_data: {
        type: 'CFCF_TravelCostLog',
        label: i18next.t('general.travelCost'),
        required: true,
      },
      internal_comment: {
        type: 'String',
        label: i18next.t('general.internalComment'),
      },
    },
    steps: [
      {
        type: 'set_fact',
        fact: 'travel_cost_data',
        value: '[[travel_cost_data]]',
      },
      {
        type: 'set_fact',
        fact: 'internal_comment',
        value: '[[internal_comment]]',
      },
    ],
  },
  /* ------------------------------- Other costs ------------------------------ */
  log_costs_other: {
    available_to: [
      CaseflowActorTypes.WARRANTOR,
      CaseflowActorTypes.EXTERNAL_REPAIRER,
    ],
    label: i18next.t('general.logCostsOther'),
    params: {
      other_cost_data: {
        type: 'CFCF_OtherCostLog',
        label: i18next.t('general.otherCost'),
        required: true,
      },
      internal_comment: {
        type: 'String',
        label: 'Internal comment',
      },
    },
    steps: [
      {
        type: 'set_fact',
        fact: 'other_cost_data',
        value: '[[other_cost_data]]',
      },
      {
        type: 'set_fact',
        fact: 'internal_comment',
        value: '[[internal_comment]]',
      },
    ],
  },
};
/* ------------------- The complete global actions object ------------------- */
const global_actions: CaseFlowConfiguration['global_actions'] = {
  ...genericActions,
  ...stationMovementActions,
  ...costLoggingActions,
};

export const FatbikesConfig: CaseFlowConfiguration = {
  actors,
  facts,
  stations,
  global_actions,
};

CaseFlowConfigurationRegistry.Register(
  FatbikesConfig,
  CaseflowCaseTypes.FATBIKES
);
