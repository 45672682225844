import {
  Empty_NoSqlDoc,
  FBD_Keygen_BANNED,
  MakeFBDocType,
  NoSqlDoc,
  Z_NoSqlDoc,
} from '@rabbit/firebase/doctype';
import { Address, Money, Z_Address, Z_Money } from '../base/basic';
import { z, schema } from '@rabbit/utils/ts';
import { PersonaTypeSingleLetter, Z_PersonaTypeSingleLetter } from '../persona';
import { PartnerSettings, TenantLink, Z_PartnerSettings } from './tenant';

// TODO: Handle working with multiple tenants. One for the future - DC
/* -------------------------------------------------------------------------- */
/*                           User Invite - Business                           */
/* -------------------------------------------------------------------------- */

export enum BusinessUserInviteStatus {
  Pending = 'pending',
  Completed = 'completed',
  Cancelled = 'cancelled',
  requested = 'requested',
}

export enum BusinessUserInviteType {
  Internal = 'internal',
  Partner = 'partner',
}

/** An account creation invitation for a business user. This can be either an internal user or a partner user.
 *
 */
export interface DTBusiness_User_Invite extends NoSqlDoc {
  /** The email address of the invited user */
  email: string;

  /** The user's first name */
  firstName?: string;

  /** The user's last name */
  lastName?: string;

  /** The name of the organization that this user represents, if applicable */
  orgName?: string;

  /** The user's or the organization's phone number */
  phone?: string;

  /** The address for the organization that this user represents, if applicable */
  addresses?: Address[];

  /** The tenant this user will be working with */
  tenantLink: TenantLink;

  /** Has the invite process been completed? */
  status: BusinessUserInviteStatus;

  /** When the invite will cease to be active, in unix timestamp format. May be null for invites that don't expire  */
  expiryDate: number | null;

  /** The type of user.
   *  'Internal' refers to users working directly under the Tenant,
   *  while 'partner' refers to the tenant's external partners */
  type: BusinessUserInviteType;

  /** The personas that should be assigned to the new user upon successful signup */
  personas: PersonaTypeSingleLetter[];

  /** The new user's partner-specific settings, if applicable */
  settings?: PartnerSettings[];

  /** The user's hourly rate, if applicable */
  hourlyRate?: Money | null;
}

export const FBD_Business_User_Invite = MakeFBDocType<DTBusiness_User_Invite>({
  name: 'Business User Invite',
  collection: 'business_user_invite',
  empty: () => {
    const result: DTBusiness_User_Invite = {
      ...Empty_NoSqlDoc(),
      email: '',
      type: BusinessUserInviteType.Internal,
      personas: [],
      tenantLink: '',
      status: BusinessUserInviteStatus.Pending,
      expiryDate: null,
    };
    return result;
  },
  keygen: FBD_Keygen_BANNED(
    'Business User Invite key should be TENANT_EMAILADDRESS'
  ),
});

export const Z_DTBusiness_User_Invite = schema<DTBusiness_User_Invite>()(
  z
    .object({
      email: z.string(),
      firstName: z.string().optional(),
      lastName: z.string().optional(),
      orgName: z.string().optional(),
      tenantLink: z.string(),
      status: z.enum([
        BusinessUserInviteStatus.Pending,
        BusinessUserInviteStatus.Completed,
        BusinessUserInviteStatus.Cancelled,
        BusinessUserInviteStatus.requested,
      ]),
      expiryDate: z.nullable(z.number().optional()),
      type: z.enum([
        BusinessUserInviteType.Internal,
        BusinessUserInviteType.Partner,
      ]),
      phone: z.string().optional(),
      addresses: z.array(Z_Address).optional(),
      personas: z.array(Z_PersonaTypeSingleLetter),
      hourlyRate: z.nullable(Z_Money.optional()),
      role: z.string().optional(),
      settings: z.array(Z_PartnerSettings).optional(),
    })
    .merge(Z_NoSqlDoc)
);
