import {
  DTBusiness_User_Invite,
  DTConsumer_Private,
  DTHoldingProxy,
  DTRepairer_Public,
  DTTenant_Public,
  FBD_Business_User_Invite,
  FBD_Repairer_Public,
  PersonaTypeSingleLetter,
} from '@rabbit/data/types';
import Skeleton from 'react-loading-skeleton';
import {
  Button,
  CardWrapperWithHeader,
  LabelledItemList,
  LabelledItemShape,
  Modal,
  ModalSettingsShape,
} from '@rabbit/elements/shared-components';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import { timeFormat } from '@rabbit/sage/utils/helpers.tsx';
import { useEffect, useState } from 'react';
import ClaimAttachmentsUpload from '@rabbit/sage/components/organisms/ClaimAttachmentsUpload/ClaimAttachmentsUpload.tsx';
import { SageFileUploader } from '@rabbit/sage/components/organisms/upload-wrapper/SageFileUploader.tsx';
import {
  DocTypeShapeTypes,
  UploadedFileCategories,
} from '@rabbit/elements/shared-types';
import { FBD_User_Request } from '../../../../../../../libs/data/types/src/lib/user-request.ts';
import { ModalNewEditPartner } from '@rabbit/sage/components/organisms/ModalNewEditPartner/ModalNewEditPartner.tsx';
import { UserIcon } from '@heroicons/react/24/solid';

interface RegistrationsHoldingDetailsSectionProps {
  allHoldingsList?: DTHoldingProxy[] | null;
  consumer_private?: DTConsumer_Private | null;
  holding_proxy?: DTHoldingProxy | null;
  holdingId: string;
  storeAddress: string;
}

export default function InstallationDetails({
  allHoldingsList,
  holding_proxy,
  consumer_private,
  holdingId,
  storeAddress,
}: RegistrationsHoldingDetailsSectionProps) {
  const { t } = useTranslation();
  const [fileMode, setFileMode] = useState(false);
  const [installer, setInstaller] = useState<any | null>(null);
  const handleOpenInstallationAttachmentsModal = (totalFile: number) => {
    setFileMode(totalFile > 0);
  };
  const [installerLoaded, setInstallerLoaded] = useState<boolean>(false);
  const installerId = holding_proxy?.installer!;
  const [unAuthInstaller, setUnAuthInstaller] = useState<boolean>(false);
  const [isNewEditModalOpen, setIsNewEditModalOpen] = useState<boolean>(false);
  const [partnerDetails, setPartnerDetails] = useState<
    DTBusiness_User_Invite | DTTenant_Public
  >();
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] =
    useState<boolean>(false);

  useEffect(() => {
    if (!installerLoaded) {
      void (async () => {
        const installerData = await FBD_Repairer_Public.get(installerId);
        if (installerData) {
          setInstaller(installerData!);
          setInstallerLoaded(true);
          setUnAuthInstaller(false);
        } else {
          const installerUserRequest = await FBD_User_Request.get(installerId);
          if (installerUserRequest) {
            const businessUserInvite = await FBD_Business_User_Invite.get(
              installerUserRequest?.user_request_id
            );
            if (businessUserInvite && businessUserInvite.status === 'pending') {
              setUnAuthInstaller(false);
            } else {
              setUnAuthInstaller(true);
            }
            setInstaller(installerUserRequest);
            setInstallerLoaded(true);
          }
        }
      })();
    }
  }, [installerLoaded, installerId]);

  const handleEditModal = () => {
    setPartnerDetails({
      orgName: installer.orgName,
      email: installer.email,
      docid: '',
      phone: installer.telephone,
      tcreate: new Date().getTime(),
      tupdate: new Date().getTime(),
      personas: [PersonaTypeSingleLetter.Installer],
    });
    setIsNewEditModalOpen(true);
  };

  const modalSettingsClaimFiles: ModalSettingsShape = {
    title: t('general.supportingMaterials'),
    handleClose: () => setFileMode(false),
  };

  const addEditModalSettings = {
    title: t('general.addPartner'),
    handleClose: () => setIsNewEditModalOpen(false),
  };

  const renderAttachments = () => {
    let totalFiles = holding_proxy?.installation_attachments?.length ?? 0;
    return (
      <div
        className={
          'flex cursor-pointer items-center gap-2 text-sm ' +
          (totalFiles === 0 ? 'text-red-500' : 'text-black')
        }
        onClick={() => handleOpenInstallationAttachmentsModal(totalFiles)}
      >
        {totalFiles} {t('general.filesUploaded')}
        <div>
          <ArrowTopRightOnSquareIcon
            className={
              'h-4 w-4' + (totalFiles === 0 ? 'text-red-500' : 'text-black')
            }
          />
        </div>
      </div>
    );
  };

  const confirmationModalSettings = {
    kind: 'pop-up' as const,
    settings: {
      text: 'You are about to delete this partner. You will lose all information. Are you sure you want to continue?',
      primaryButtonText: 'Yes',
      outlineButtonText: 'No, cancel',
      handleClose: () => setIsConfirmationModalOpen(false),
      handlePrimaryClick: async () =>
        console.log('delete --> selectedRowToDelete'),
      handleOutlineClick: () => setIsConfirmationModalOpen(false),
    },
  };

  const items: LabelledItemShape[] = [
    {
      label: t('general.installerName'),
      value: installer?.name
        ? installer?.name
        : installer?.firstName
        ? `${installer.firstName} ${installer.lastName}`
        : '',
    },
    {
      label: t('general.phone'),
      value: installer?.phone ? installer?.phone : installer?.telephone ?? '',
    },
    {
      label: t('general.email'),
      value: installer?.email!,
    },
    {
      label: t('general.installationDate'),
      value: holding_proxy?.installation?.installation_date
        ? moment(
            new Date(holding_proxy?.installation?.installation_date ?? '')
          ).format('DD/MM/yyyy')
        : '-',
    },
    {
      label: t('general.installationTime'),
      value: holding_proxy?.installation?.installation_time
        ? timeFormat(
            String(holding_proxy?.installation?.installation_time),
            t('general.hours'),
            t('general.minutes')
          )
        : '-',
    },
    {
      label: t('general.supportingMaterials'),
      value: renderAttachments(),
    },
    {
      label: t('general.comment'),
      value: holding_proxy?.internal_comment ? (
        <div
          dangerouslySetInnerHTML={{
            __html: holding_proxy?.internal_comment ?? '',
          }}
        ></div>
      ) : (
        '-'
      ),
    },
  ];

  return (
    <CardWrapperWithHeader
      title={`${t('general.installationDetails')} ${
        unAuthInstaller ? `(${t('general.unauthorisedInstaller')})` : ''
      }`}
      headerRight={
        unAuthInstaller && (
          <Button
            icon={<UserIcon />}
            type="button"
            kind="primary"
            size={'sm'}
            children={t('general.inviteInstaller')}
            onClick={() => handleEditModal()}
          />
        )
      }
    >
      {!holding_proxy || !allHoldingsList ? (
        <div>
          <div className="font-nunito mb-[30px] grid grid-cols-2 gap-8 md:grid-cols-5 lg:grid-cols-5">
            <div className="mb-10">
              {' '}
              <Skeleton count={2} />
            </div>
            <div className="mb-10">
              {' '}
              <Skeleton count={2} />
            </div>
            <div className="mb-10">
              {' '}
              <Skeleton count={2} />
            </div>
            <div className="mb-10">
              {' '}
              <Skeleton count={2} />
            </div>
            <div className="mb-10">
              {' '}
              <Skeleton count={2} />
            </div>
          </div>
        </div>
      ) : (
        <div className="font-nunito">
          <LabelledItemList items={items} />
        </div>
      )}
      {fileMode && (
        <Modal
          kind="generic"
          settings={modalSettingsClaimFiles}
          className="m-auto w-[724px] rounded-md border bg-white"
        >
          <div className="px-5">
            <div className="px-5">
              <SageFileUploader
                label={t('general.installationAttachments')}
                identifiers={{
                  category: UploadedFileCategories.installation_attachment,
                  docType: {
                    docid: holding_proxy?.docid || '',
                    type: DocTypeShapeTypes.Case,
                  },
                  personaId: holding_proxy?.consumer ?? '',
                }}
                disabled={true}
                allowDelete={false}
                accepts={['image/*', '.pdf']}
                shouldAutoUpdateDocs={false}
                maxFiles={1}
                currentFiles={holding_proxy?.installation_attachments ?? []}
              />
            </div>
          </div>
        </Modal>
      )}
      {isNewEditModalOpen && (
        <div>
          <ModalNewEditPartner
            handleClose={() => setIsNewEditModalOpen(false)}
            handleDelete={() => setIsConfirmationModalOpen(true)}
            partner={partnerDetails}
            modalSettings={addEditModalSettings}
            installerSettings={{ installer: true }}
          />
          {isConfirmationModalOpen && (
            <Modal
              className="h-fit w-[350px] rounded-md bg-white"
              kind={confirmationModalSettings.kind}
              settings={confirmationModalSettings.settings}
            />
          )}
        </div>
      )}
    </CardWrapperWithHeader>
  );
}
