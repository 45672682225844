import { LoadingSpinner } from '@rabbit/elements/shared-components';
import { Navigate, useLocation } from 'react-router-dom';
import { useGetMySagePersonas } from '@rabbit/data/portal';
import { CaseflowProviderWrapper } from '../context/CaseflowContext';

import ClaimDetailViewTemplate from '../components/templates/ClaimDetailViewTemplate/ClaimDetailViewTemplate';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { UserContext } from '../context/UserContext';
import { getOperatingPersonaId, getUserPermissions } from '../utils/helpers';
import { Permissions } from '@rabbit/data/types';
import { SAGE_ROUTE_NAME } from '@rabbit/config/enums';
import { ConfigContext } from '@rabbit/config/context';

export function ClaimDetailView() {
  const { t } = useTranslation();
  const userContext = useContext(UserContext);
  const isAdmin = userContext?.accessToken?.admin ?? false;
  const tenantLink = t('tenantLink');
  const { config } = useContext(ConfigContext);
  const operatingPersonas = config.CLAIMS.OPERATING_PERSONAS;
  const userPermissions = getUserPermissions(tenantLink, userContext);

  const location = useLocation();
  const caseId = location.pathname.split('/')[2].split('_')[0];

  const { warrantorPersona, repairerPersona, installerPersona } =
    useGetMySagePersonas();
  if (
    !userPermissions?.includes(Permissions.CasesEdit) &&
    !userPermissions?.includes(Permissions.CasesView) &&
    !userPermissions?.includes(Permissions.Owner)
  )
    return <Navigate to={SAGE_ROUTE_NAME.MANAGE_ACCOUNT} />;
  if (
    (!warrantorPersona?.personaId &&
      !repairerPersona?.personaId &&
      !installerPersona?.personaId) ||
    !userContext?.accessToken
  )
    return <LoadingSpinner size="md" />;

  const operatingPersonaId = getOperatingPersonaId(
    operatingPersonas,
    tenantLink,
    userContext?.accessToken,
    {
      repairer: repairerPersona?.personaId,
      warrantor: warrantorPersona?.personaId,
      installer: installerPersona?.personaId,
    }
  );

  if (operatingPersonaId) {
    return (
      <CaseflowProviderWrapper
        caseId={caseId}
        operatingPersona={operatingPersonaId}
        isAdmin={isAdmin}
      >
        <ClaimDetailViewTemplate />
      </CaseflowProviderWrapper>
    );
  }

  return <LoadingSpinner size="md" />;
}

export default ClaimDetailView;
