import { useManageSageProfile, useSageAPI } from '@rabbit/bizproc/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { WarrantyOfferFullDataShape } from '@rabbit/elements/shared-types';
import {
  PlanCategoryInterface,
  PlanOptionInterface,
  PlansHeader,
  PlanOptions,
} from './PlansAndPricesView';
import { DTWarranty_Template } from '@rabbit/data/types';
import {
  getCurrencyFormat,
  LoadingSpinner,
} from '@rabbit/elements/shared-components';
import { useAppInfo } from '../utils/helpers';

export default function PricingView() {
  const { t } = useTranslation();
  const appInfo = useAppInfo();
  const warrantorLink = `W:${t('tenantLink')}`;
  const { currentData, userActivePersonas } = useManageSageProfile();
  const partnerTenantLink =
    currentData?.[`${userActivePersonas[0]}_public`]?.docid ?? '';
  const { getAllWarrantyOffers } = useSageAPI();
  const [availableOffers, setAvailableOffers] = useState<
    WarrantyOfferFullDataShape[]
  >([]);
  const [loading, setLoading] = useState(false);
  const [selectedArea, setSelectedArea] = useState('');
  const areaOptions = [
    { label: '>15m²', value: '>15m²', id: '0' },
    { label: '<15m²', value: '<15m²', id: '1' },
  ];

  useEffect(() => {
    if (
      !partnerTenantLink ||
      !warrantorLink ||
      !partnerTenantLink?.split(':')?.[1]
    ) {
      return;
    }
    const fetchOffers = async () => {
      setLoading(true);
      const res = await getAllWarrantyOffers({
        partnerTenantLink: partnerTenantLink?.split(':')?.[1],
        warrantorLink,
      });
      setAvailableOffers(res as WarrantyOfferFullDataShape[]); // todo confirm
      setLoading(false);
    };

    fetchOffers();
  }, [warrantorLink, partnerTenantLink]);

  if (loading) {
    return <LoadingSpinner size="md" />;
  }

  if (availableOffers.length === 0) {
    return <div className="text-center">No pricing plans available</div>;
  }
  const pricePlans = availableOffers
    .sort((a, b) => a.template.orderNo - b.template.orderNo)
    .filter((plan) => plan.offer.enabled)
    .map((plan) => ({
      title: plan.offer.title,
      tableData: plan.lookupTables,
      originalOffer: plan,
      docid: plan.offer.docid,
    }));

  const optionDescription = (offer: DTWarranty_Template) => {
    const durations = offer.options.optionList.find(
      (i) => i.optionKey === 'duration'
    );
    if (durations) {
      return durations.optionItems
        .map((option, index) => {
          return index === durations.optionItems.length - 1
            ? durations.optionItems.length > 1
              ? `or ${option.itemLabel}`
              : `${option.itemLabel}`
            : index === durations.optionItems.length - 2
            ? `${option.itemLabel?.replace(/\syear(s)?/, '')} `
            : `${option.itemLabel?.replace(/\syear(s)?/, '')}, `;
        })
        .join('');
    } else return '';
  };

  const plans: PlanCategoryInterface[] = pricePlans.map((plan) => {
    return {
      title: plan.title,
      description: optionDescription(plan.originalOffer.template),
      start: (
        (plan.tableData[0].values as (number | string)[][])
          .map((v) => v[Number(selectedArea)])
          .filter((i) => Number(i)) as number[]
      ).sort((a, b) => a - b)[0],
    };
  });

  // const options: PlanOptionInterface[] = [
  //   { title: '1', values: ['Free', '$200.00', '$400.00', '$600.00'] },
  //   { title: '2', values: ['Free', '$370.00', '$600.00', '$1,100.00'] },
  //   { title: '3', values: ['-', '$530.00', '$1,100.00', '$1,600.00'] },
  //   { title: '5', values: ['-', '$850.00', '$1,800.00', '$2,600.00'] },
  // ];

  const ooptions = pricePlans
    .map((plan) => {
      const durations = plan.originalOffer.template.options.optionList.find(
        (i) => i.optionKey === 'duration'
      );
      if (durations) {
        return durations.optionItems.map((option) => option.itemLabel);
      }
      return [];
    })
    .flat()
    .filter((i, index, arr) => arr.indexOf(i) === index);

  let pricePlanOptions = pricePlans.map((plan) => {
    return (plan.tableData[0].values as (number | string)[][]).map(
      (v) => v[Number(selectedArea)]
    );
  });
  const max =
    Math.max.apply(
      null,
      pricePlanOptions.map((i) => i.length)
    ) || 0;
  pricePlanOptions = pricePlanOptions.map((option) =>
    Array.from({ length: max }, (_, i) => option[i] || '-')
  );

  // generic matrix inverse function
  const inverseMatrix = (matrix: (number | string)[][]) => {
    const rows = matrix.length;
    const columns = matrix[0].length;
    const result: (number | string)[][] = [];
    for (let i = 0; i < columns; i++) {
      result[i] = [];
      for (let j = 0; j < rows; j++) {
        result[i][j] = matrix[j][i];
      }
    }
    return result;
  };

  const invertedPrices = inverseMatrix(pricePlanOptions);

  const planOptions: PlanOptionInterface[] = ooptions.map((option, index) => {
    return {
      title: option || '',
      values:
        invertedPrices[index].map((i) => {
          if (parseInt(i + '') > 0) {
            return getCurrencyFormat(Number(i + ''), appInfo.currency);
          }
          return String(i) === 'true'
            ? false
            : String(i) === 'false'
            ? false
            : String(i);
        }) || [],
    };
  });

  return (
    <div className="flex h-fit flex-col">
      <PlansHeader
        title="Prices (AUD)"
        description=""
        plans={plans}
        planAddons={[
          {
            title: 'Area',
            options: areaOptions,
          },
        ]}
        onAddonChange={(option) => setSelectedArea(option?.id || '')}
      />
      <div className="text-md mt-6 font-bold text-black">Term</div>
      <PlanOptions options={planOptions} plans={plans} />
      <div className="font-caudex text-md text-md mt-16 max-w-[min(560px,100vw)]">
        <strong>Minor repair</strong>: chips, blemishes, small cracks (where
        face polishing is not required).
        <br />
        <strong>Major repair</strong>: structural repairs including joints,
        dropped sinks, scratch and crack repairs (where face polishing is
        required). Other repairs by assessment.
      </div>
      <div className="p-4"></div>
    </div>
  );
}
