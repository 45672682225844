import { useState } from 'react';
import { Modal } from '@rabbit/elements/shared-components';
import { ModalSettingsShape } from '@rabbit/elements/shared-components';
import {
  TenantType,
  TenantTypeEnum,
} from '@rabbit/sage/views/RegistrationsView';
import { useTranslation } from 'react-i18next';
import {
  RenderWarrantyIrelandFlow,
  RenderNuCoverFlow,
  RenderPinnacleWarrantiesFlow,
  RenderGenericFlow,
} from '../registration-flows/TenantFlows';
import { TFunction } from 'i18next';

export interface ModalNewRegistrationProps {
  itemDetails?: any; //TODO
  handleClose: () => void;
}

export interface CarRegistrationShape {
  registrationNo: string;
  cherishedRegistrationNo?: string;
  make: string;
  model: string;
  version: string;
  body: string;
  regDate: string;
  engineCc: string;
  colour: string;
  fuel: string;
  transmission: string;
  yearOfManufacture: string;
  chassisNo: string;
  engineNo: string;
  vehicleCategory: string;
  manualInput: boolean;
}

function ModalNewRegistration({ handleClose }: ModalNewRegistrationProps) {
  const [currentStep, setCurrentStep] = useState<{
    step: number;
    data: any;
  }>({ step: 0, data: null });
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const tenantLink = t('tenantLink');
  const tenantConfigType = t('tenantConfigType');

  let tenantType: TenantType = TenantTypeEnum.DEFAUL_TENANT;
  if (tenantConfigType === 'vehicle') {
    tenantType = TenantTypeEnum.CAR_TENANT;
  } else if (tenantConfigType === 'stone') {
    tenantType = TenantTypeEnum.TABLE_TENANT;
  }

  const stepTitle: { [key: number]: string } = {
    0: t('general.newRegistration'),
    1:
      tenantType === TenantTypeEnum.CAR_TENANT
        ? 'Car details'
        : tenantType === TenantTypeEnum.DEFAUL_TENANT
        ? t('general.createNewUser')
        : t('general.coverageDetails'),
    2:
      tenantType === TenantTypeEnum.CAR_TENANT
        ? 'Car details'
        : tenantType === TenantTypeEnum.DEFAUL_TENANT
        ? t('general.completeRegistration')
        : t('general.consumerDetails'),
    3:
      tenantType === TenantTypeEnum.CAR_TENANT
        ? t('general.coverageOptions')
        : tenantType === TenantTypeEnum.DEFAUL_TENANT &&
          currentStep?.data?.productResponse?.id
        ? t('general.editProduct')
        : tenantType === TenantTypeEnum.DEFAUL_TENANT &&
          !currentStep?.data?.productResponse?.id
        ? t('general.addNewProduct')
        : t('general.summary'),
    4: t('general.consumerDetails'),
    5: t('general.summary'),
    6: t('general.submissionPreview'),
  };

  function getChildrenClassName(
    tenantType: string,
    currentStep: { step: number; data: any },
    stepTitle: { [key: number]: string },
    t: TFunction
  ) {
    if (tenantType === TenantTypeEnum.DEFAUL_TENANT) {
      if (currentStep.step === 6) {
        return 'overflow-auto';
      }
      if (currentStep.step === 3) {
        return stepTitle[currentStep.step] === t('general.addNewProduct') ||
          stepTitle[currentStep.step] === t('general.editProduct')
          ? 'overflow-auto'
          : 'overflow-visible';
      }

      if (stepTitle[currentStep.step] === t('Complete registration')) {
        return 'overflow-auto';
      }

      if (stepTitle[currentStep.step] === t('general.createNewUser')) {
        return 'overflow-auto';
      }

      return 'overflow-visible';
    }

    return currentStep.step === 0 ||
      (currentStep.step === 3 && stepTitle[currentStep.step] !== 'Summary')
      ? 'overflow-auto'
      : 'overflow-auto';
  }

  const modalSettings: ModalSettingsShape = {
    title: stepTitle[currentStep.step],
    handleClose: handleClose,
    childrenClassName: getChildrenClassName(
      tenantType,
      currentStep,
      stepTitle,
      t
    ),
    handleBackPress:
      currentStep.step > 0
        ? () => {
            if (currentStep.step === 6) {
              onChangeStep(2, currentStep.data);
            } else if (
              currentStep.step === 3 &&
              tenantType === TenantTypeEnum.DEFAUL_TENANT &&
              (stepTitle[currentStep.step] === t('general.addNewProduct') ||
                stepTitle[currentStep.step] === t('general.editProduct'))
            ) {
              onChangeStep(2, currentStep.data);
            } else if (tenantType === TenantTypeEnum.DEFAUL_TENANT) {
              onChangeStep(0);
            } else {
              onChangeStep(currentStep.step - 1);
            }
          }
        : undefined,
  };

  const onChangeStep = (step_in: number, data: any = null) => {
    if (step_in === 0)
      return setCurrentStep({ step: step_in, data: currentStep?.data || {} });
    setCurrentStep({
      ...currentStep,
      step: step_in,
      data: Object.assign(currentStep.data || {}, data),
    });
  };

  return (
    <Modal
      kind="generic"
      settings={modalSettings}
      isLoading={isLoading}
      className="m-auto w-[724px] rounded-md border bg-white"
    >
      {tenantType === TenantTypeEnum.DEFAUL_TENANT && (
        <RenderGenericFlow
          onChangeStep={onChangeStep}
          currentStep={currentStep}
          handleClose={handleClose}
          setIsLoading={setIsLoading}
          tenantType={tenantType}
        />
      )}
      {tenantLink === 'WARRANTYIRELAND' && (
        <RenderWarrantyIrelandFlow
          onChangeStep={onChangeStep}
          currentStep={currentStep}
          handleClose={handleClose}
          setIsLoading={setIsLoading}
          tenantType={tenantType}
        />
      )}
      {tenantLink === 'NUCOVER' && (
        <RenderNuCoverFlow
          onChangeStep={onChangeStep}
          currentStep={currentStep}
          handleClose={handleClose}
          setIsLoading={setIsLoading}
          tenantType={tenantType}
        />
      )}
      {tenantLink === 'PINNACLEWARRANTIES' && (
        <RenderPinnacleWarrantiesFlow
          onChangeStep={onChangeStep}
          currentStep={currentStep}
          handleClose={handleClose}
          setIsLoading={setIsLoading}
          tenantType={tenantType}
        />
      )}
    </Modal>
  );
}

export default ModalNewRegistration;
