import {
  Warranty_GetAvailableTemplates,
  Warranty_RegisterSRVHoldingWithWarranty,
  Warranty_RegisterWarranty,
  Warranty_GetApprovedOffers,
  UserInvites_Business_GenerateInvite,
  Warranty_GetAllOffers,
  Warranty_CreateWarrantyOfferForPartner,
  Vendable_CreateOrUpdate,
  Holding_GetVehicleInfo,
  Tenant_GetPartnerData,
  Tenant_UpdatePartner_Data,
  UserInvites_Business_ResendInvite,
  Warranty_VoidWarranty,
  Claims_RefreshForIdentity,
  CaseFlow_InitiateCase,
  Holding_Register_Sage,
} from '@rabbit/bizproc/client';
import {
  APICallParamsBase,
  Warranty_GetApprovedOffersParams,
  Warranty_GetApprovedOffersResult,
  Warranty_GetAvailableTemplatesParams,
  Warranty_GetAvailableTemplatesResult,
  Warranty_RegisterSRVHoldingWithWarrantyParams,
  Warranty_RegisterWarrantyParams,
  Warranty_RegisterWarrantyResult,
  Warranty_GetAllOffersParams,
  GetVehicleInfoParams,
  GetPartnerTenantParams,
  Warranty_VoidParams,
} from '@rabbit/elements/shared-types';
import { useAPI } from './useAPI';
import { z } from 'zod';
import {
  ICF_CreateOrUpdateVendable,
  ICF_CreateWarrantyOfferForPartner,
  ICF_GenerateBusinessUserInvite,
  ICF_RefreshIdentityClaims,
  OCF_CreateOrUpdateVendable,
  OCF_CreateWarrantyOfferForPartner,
  OCF_GenerateBusinessUserInvite,
  OCF_GetAllWarrantyOffers_Full,
  OCF_GetVechicleInfo,
  OCF_GetPartnerTenantData,
  ICF_UpdatePartnerTenantData,
  OCF_UpdatePartnerTenantData,
  ICF_ResendBusinessUserInvite,
  OCF_ResendBusinessUserInvite,
  OCF_RefreshIdentityClaims,
  ICF_RegisterCase,
  OCF_RegisterCase,
  RegisterCase_Payload,
  ICF_SageRegisterConsumerHolding,
  OCF_SageRegisterConsumerHolding,
} from '@rabbit/bizproc/core';

export function useSageAPI() {
  const {
    // requestId,
    // apiVersion,
    isReady,
    callAPI,
  } = useAPI();

  // Import the functions, wrap them in callAPI, and return them

  /* -------------------------------------------------------------------------- */
  /*                                User Invites                                */
  /* -------------------------------------------------------------------------- */

  /* ----------------------------- Generate invite ---------------------------- */

  async function generateBusinessUserInvite(
    params: Omit<
      z.infer<typeof ICF_GenerateBusinessUserInvite>,
      keyof APICallParamsBase
    >
  ): Promise<z.infer<typeof OCF_GenerateBusinessUserInvite>> {
    return await callAPI(UserInvites_Business_GenerateInvite, params);
  }

  /* ----------------------------- Resend invite ---------------------------- */
  async function resendBusinessUserInvite(
    params: Omit<
      z.infer<typeof ICF_ResendBusinessUserInvite>,
      keyof APICallParamsBase
    >
  ): Promise<z.infer<typeof OCF_ResendBusinessUserInvite>> {
    return await callAPI(UserInvites_Business_ResendInvite, params);
  }

  /* -------------------------------------------------------------------------- */
  /*                   Warranty registration-related functions                  */
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Get templates ----------------------------- */

  async function getAvailableWarrantyTemplates(
    params: Warranty_GetAvailableTemplatesParams
  ): Promise<Warranty_GetAvailableTemplatesResult['data']> {
    return await callAPI(Warranty_GetAvailableTemplates, params);
  }

  /* --------------------------- Register warranties -------------------------- */
  // I'd like a better name here but yeah
  // It'll be replaced with a new function using the decider in the near future, so this will do for now
  async function registerWarranty(
    params: Warranty_RegisterWarrantyParams
  ): Promise<Warranty_RegisterWarrantyResult['data']> {
    return await callAPI(Warranty_RegisterWarranty, params);
  }

  async function registerSRVHoldingWithWarranty(
    params: Warranty_RegisterSRVHoldingWithWarrantyParams
  ): Promise<Warranty_RegisterWarrantyResult['data']> {
    return await callAPI(Warranty_RegisterSRVHoldingWithWarranty, params);
  }

  /* -------------------------------------------------------------------------- */
  /*                               Warranty                                    */
  /* -------------------------------------------------------------------------- */

  /* --------------------------- Get warranty offers -------------------------- */

  async function getApprovedWarrantyOffers(
    params: Warranty_GetApprovedOffersParams
  ): Promise<Warranty_GetApprovedOffersResult['data']> {
    return await callAPI(Warranty_GetApprovedOffers, params);
  }

  /* --------------------------- Get all warranty offers -------------------------- */
  async function getAllWarrantyOffers(
    params: Warranty_GetAllOffersParams
  ): Promise<z.infer<typeof OCF_GetAllWarrantyOffers_Full>> {
    return await callAPI(Warranty_GetAllOffers, params);
  }

  /* ------------------------ Create new warranty offer ----------------------- */

  async function createWarrantyOfferForPartner(
    params: Omit<
      z.infer<typeof ICF_CreateWarrantyOfferForPartner>,
      keyof APICallParamsBase
    >
  ): Promise<z.infer<typeof OCF_CreateWarrantyOfferForPartner>> {
    return await callAPI(Warranty_CreateWarrantyOfferForPartner, params);
  }

  /* ------------------------ Void Warranty ----------------------- */
  async function warrantyVoid(
    params: Warranty_VoidParams
  ): Promise<Warranty_VoidParams> {
    return await callAPI(Warranty_VoidWarranty, params);
  }
  /* -------------------------------------------------------------------------- */
  /*                           Create / Edit vendables                          */
  /* -------------------------------------------------------------------------- */

  async function createOrUpdateVendable(
    params: Omit<
      z.infer<typeof ICF_CreateOrUpdateVendable>,
      keyof APICallParamsBase
    >
  ): Promise<z.infer<typeof OCF_CreateOrUpdateVendable>> {
    return await callAPI(Vendable_CreateOrUpdate, params);
  }

  /* -------------------------------------------------------------------------- */
  /*                               Vehicle Details                              */
  /* -------------------------------------------------------------------------- */

  /* ------------------------ Get vehicle info ----------------------- */
  async function getHoldingVehicleInfo(
    params: GetVehicleInfoParams
  ): Promise<z.infer<typeof OCF_GetVechicleInfo>> {
    return await callAPI(Holding_GetVehicleInfo, params);
  }

  /* -------------------------------------------------------------------------- */
  /*                              Tenants                                       */
  /* -------------------------------------------------------------------------- */

  /* ------------------------ Get PartnerTenant ----------------------- */
  async function getPartnerTenantData(
    params: GetPartnerTenantParams
  ): Promise<z.infer<typeof OCF_GetPartnerTenantData>> {
    return await callAPI(Tenant_GetPartnerData, params);
  }

  /* ------------------------ Update Tenant Partner ----------------------- */
  async function updatePartnerTenantData(
    params: Omit<
      z.infer<typeof ICF_UpdatePartnerTenantData>,
      keyof APICallParamsBase
    >
  ): Promise<z.infer<typeof OCF_UpdatePartnerTenantData>> {
    return await callAPI(Tenant_UpdatePartner_Data, params);
  }

  /* -------------------------------------------------------------------------- */
  /*                              Refreshing claims                             */
  /* -------------------------------------------------------------------------- */

  async function refreshClaimsForIdentity(
    params: Omit<
      z.infer<typeof ICF_RefreshIdentityClaims>,
      keyof APICallParamsBase
    >
  ): Promise<z.infer<typeof OCF_RefreshIdentityClaims>> {
    return await callAPI(Claims_RefreshForIdentity, params);
  }

  /* -------------------------------------------------------------------------- */
  /*                                  Caseflow                                  */
  /* -------------------------------------------------------------------------- */

  async function initiateCaseFlowCase(
    params: RegisterCase_Payload
  ): Promise<z.infer<typeof OCF_RegisterCase>> {
    return await callAPI(CaseFlow_InitiateCase, params);
  }

  /* -------------------------------------------------------------------------- */
  /*                            Holding registration                            */
  /* -------------------------------------------------------------------------- */
  async function registerConsumerHoldingSage(
    params: Omit<
      z.infer<typeof ICF_SageRegisterConsumerHolding>,
      keyof APICallParamsBase
    >
  ): Promise<z.infer<typeof OCF_SageRegisterConsumerHolding>> {
    return await callAPI(Holding_Register_Sage, params);
  }

  return {
    isReady,
    // User Invites
    generateBusinessUserInvite,
    resendBusinessUserInvite,
    // Warranty Templates
    getAvailableWarrantyTemplates,
    // Warranty Offers
    getApprovedWarrantyOffers,
    getAllWarrantyOffers,
    createWarrantyOfferForPartner,
    // Warranty Registration
    registerWarranty,
    registerSRVHoldingWithWarranty,
    // Vendables
    createOrUpdateVendable,
    // Holding Vehicle Info
    getHoldingVehicleInfo,
    // Tenant
    getPartnerTenantData,
    updatePartnerTenantData,
    // voiding warranty
    warrantyVoid,
    // Refreshing Claims
    refreshClaimsForIdentity,
    // Caseflow
    initiateCaseFlowCase,
    // Holding Registration Sage
    registerConsumerHoldingSage,
  };
}
