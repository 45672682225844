import {
  Empty_NoSqlDoc,
  FBD_Keygen_BANNED,
  FBDTKeygenGeneric,
  MakeFBDocType,
  NoSqlDoc,
} from '@rabbit/firebase/doctype';
import { HoldingLink } from '@rabbit/data/types';

export interface DTUserRequest extends NoSqlDoc {
  orgName: string;
  firstName: string;
  lastName: string;
  telephone: string;
  email: string;
  type: 'INSTALLER' | 'REPAIRER' | 'CUSTOM';
  user_request_id: string;
  holdings: string[];
}

export const FBD_User_Request = MakeFBDocType<DTUserRequest>({
  name: 'User Request',
  collection: 'user_request',
  empty: () => {
    const result: DTUserRequest = {
      ...Empty_NoSqlDoc(),
      orgName: '',
      firstName: '',
      lastName: '',
      telephone: '',
      email: '',
      type: 'CUSTOM',
      user_request_id: '',
      holdings: [],
    };
    return result;
  },
  keygen: FBDTKeygenGeneric,
});
