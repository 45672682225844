import { formatUnixTime } from '../../../utils/helpers';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';

export interface ChatMessageProps {
  wasSentByUser: boolean;
  sender: string;
  message?: string;
  timestamp: number;
  image?: string;
}

export function ChatMessage({
  wasSentByUser,
  message,
  sender,
  timestamp,
  image,
}: ChatMessageProps) {
  return (
    <div className="flex flex-col gap-1">
      {sender !== '' && (
        <div
          className={`text-xs ${wasSentByUser ? 'text-right' : 'text-left'}`}
        >
          {sender}
        </div>
      )}
      <div
        className={`font-nunito flex max-w-fit max-w-[calc(100%-75px)] flex-col rounded-md border border-gray-300 py-2 px-3 ${
          wasSentByUser
            ? 'ml-auto rounded-br-none bg-gray-50'
            : 'mr-auto rounded-bl-none bg-gray-300'
        }`}
      >
        <div className="break-words text-base"> {message}</div>
        {image && (
          <div className="">
            <img
              src={image}
              alt={'attachment'}
              className="max-h-[150px] max-w-[200px]"
            ></img>
            <a href={image} target="blank">
              <ArrowTopRightOnSquareIcon className="mt-2 h-5 w-5 cursor-pointer" />
            </a>
          </div>
        )}
        <div className="text-right text-sm text-gray-500">
          {formatUnixTime(timestamp, 'h:mmaaa')}
        </div>
      </div>
    </div>
  );
}

export default ChatMessage;
