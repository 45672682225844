import {
  ShadowContainer,
  Heading,
  LoadingSpinner,
} from '@rabbit/elements/shared-components';
import AuthForm from '../components/templates/AuthForm/AuthForm';
import { useEffect, useState } from 'react';
import { useAppInfo } from '../utils/helpers';
import { useTranslation } from 'react-i18next';
import logo from '../assets/images/logo/logo-iwarranty-black.svg';
import { SelectChangeEvent } from '@mui/material';
import { AVAILABLE_LANGUAGE_CODES } from '@rabbit/sage/utils/consts.ts';
import { useQueryParams } from 'raviger';
import { MenuListSelect } from '@rabbit/elements/shared-components';

function LoginView() {
  const appInfo = useAppInfo();
  const [isLoading, setIsLoading] = useState(false);
  const [authErrorMsg, setAuthErrorMsg] = useState('');
  const { t, i18n } = useTranslation();
  const params = useQueryParams()[0];
  const [lang, setLang] = useState(
    params.lang ?? window.localStorage.getItem('lang') ?? 'en'
  );
  useEffect(() => {
    (async () => {
      if (lang) {
        if (i18n.language !== lang) {
          await i18n.changeLanguage(lang);
          window.localStorage.setItem('lang', lang);
        }
      }
    })();
  }, [lang, params, setLang]);
  if (isLoading || t('CFG_COBRAND_NAME', '') === '')
    return <LoadingSpinner extraClasses={'mt-2'} size={'sm'} />;

  const handleChange = async (event: SelectChangeEvent) => {
    setLang(event.target.value as string);
    window.localStorage.setItem('lang', event.target.value as string);
    await i18n.changeLanguage(event.target.value as string);
  };
  const availableLanguages = AVAILABLE_LANGUAGE_CODES.map((data) => ({
    value: data.code,
    label: data.code,
  }));
  return (
    <div className="flex flex-grow flex-col justify-around">
      <ShadowContainer>
        <div style={{ position: 'absolute', right: 10, top: 10 }}>
          <MenuListSelect
            label="lang"
            value={lang}
            handleChange={handleChange}
            menuItem={availableLanguages}
          />
        </div>
        <div className="px-14 pt-12 pb-8">
          <div className="flex flex-col items-start gap-1">
            <img
              src={appInfo.logo}
              alt={appInfo.name}
              className="flex max-h-[64px] justify-start object-contain"
            />
            <Heading kind={'h2'} className="text-gray-800">
              {t(
                'sage_auth_strapline',
                t('message.warrantyIntelligencePortal')
              )}
            </Heading>
          </div>
          <div className="relative my-8 flex h-8 justify-center">
            <hr className="absolute top-1/2 h-px w-[80%] w-full border border-t-0 border-gray-200" />
            <p className="font-caudex relative top-1/2 w-fit -translate-y-1/2 bg-white px-4 text-2xl font-bold text-gray-500">
              {t('message.logIn')}
            </p>
          </div>
          <AuthForm
            kind="Login"
            setIsLoading={setIsLoading}
            authErrorMsg={authErrorMsg}
            setAuthErrorMsg={setAuthErrorMsg}
          />
          {/* <div className="my-4 text-base font-nunito">
            No account? &nbsp;
            <NavLink
              to={ROUTE_NAME.SIGNUP}
              className="text-primary-600"
              reloadDocument
            >
              Create one
            </NavLink>
          </div> */}
        </div>
        <div className="flex items-center gap-1 rounded-b-[6px] border-t border-gray-200 bg-gray-50 px-14 py-4">
          <div className="font-caudex text-sm text-gray-600">
            {t('message.poweredBy')}
          </div>
          <div className="flex items-center">
            <img
              src={logo}
              alt="logo"
              className="max-h-[28px] object-contain"
            />
          </div>
        </div>
      </ShadowContainer>
    </div>
  );
}

export default LoginView;
