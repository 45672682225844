import * as Yup from 'yup';
import { useRef, useState } from 'react';
import { Form, Formik, FormikProps } from 'formik';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Button } from '../../../atoms/buttons/Button/Button';
import { Input } from '../../../atoms/inputs/Input/Input';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

export interface FilterValueShape {
  dateType?: string;
  beginDate?: Date | undefined;
  endDate?: Date | undefined;
  unassignedOnly?: boolean;
  staleOnly?: boolean;
  starred?: boolean;
  showVoided?: string;
  warrantyStatus?: string;
}

//TODO: This modal is specific to Sage. Needs a refactor (ideally) or a name change + move to Sage - dc
export interface FiltersModalProps {
  page?: 'claims' | 'my-claims' | 'dashboard' | 'registrations';
  handleClose: () => void;
  initialValues?: any;
  data?: FilterValueShape;
  onChange: (value: FilterValueShape) => void;
  option?: {
    showWarrantyStatus: boolean;
  };
}

const schema = Yup.object({
  dateType: Yup.string(),
  endDate: Yup.date().max(new Date()),
  beginDate: Yup.date(),
  unassignedOnly: Yup.boolean(),
  staleOnly: Yup.boolean(),
  starred: Yup.boolean(),
  showVoided: Yup.string(),
  warrantyStatus: Yup.array(Yup.string()),
});

export function FiltersModal({
  page,
  handleClose,
  data,
  onChange,
  option,
}: FiltersModalProps) {
  const { t } = useTranslation();
  const formikRef = useRef<FormikProps<FilterValueShape> | null>(null);
  const initialValues: FilterValueShape = {
    dateType: data?.dateType ?? '',
    beginDate:
      data?.dateType !== 'This month' &&
      data?.dateType !== 'Last month' &&
      data?.beginDate
        ? data?.beginDate
        : undefined,
    endDate:
      data?.dateType !== 'This month' &&
      data?.dateType !== 'Last month' &&
      data?.endDate
        ? data?.endDate
        : undefined,
    unassignedOnly: data?.unassignedOnly ? data.unassignedOnly : false,
    showVoided: data?.showVoided ?? undefined,
    staleOnly: data?.staleOnly ? data.staleOnly : false,
    starred: data?.starred ? data.starred : false,
    warrantyStatus: data?.warrantyStatus ? data?.warrantyStatus : undefined,
  };

  return (
    <div className="flex h-fit w-[448px] flex-col rounded-md bg-white p-5 shadow-lg">
      <div className="flex items-center justify-between">
        <p className="font-nunito text-lg font-normal text-gray-500">
          {t('general.filters')}
        </p>
        <XMarkIcon
          onClick={handleClose}
          className="h-[18px] w-[18px] cursor-pointer bg-transparent text-gray-400"
        />
      </div>
      <Formik
        initialValues={initialValues}
        //enableReinitialize={true}
        validationSchema={schema}
        innerRef={formikRef}
        //Todo check object key naming
        onSubmit={async (values) => {
          let beginDate: Date | undefined;
          let endDate: Date | undefined;
          if (
            values.dateType === 'This month' ||
            values.dateType === 'Last month'
          ) {
            const today = new Date();
            const currentMonth = today.getMonth();
            const currentYear = today.getFullYear();

            if (values.dateType === 'This month') {
              beginDate = new Date(currentYear, currentMonth, 1);
              endDate = today;
            } else {
              beginDate = new Date(currentYear, currentMonth - 1, 1);
              endDate = new Date(currentYear, currentMonth, 0);
            }
          }
          const finalEndDate: Date | undefined =
            endDate || values.endDate || undefined;
          finalEndDate?.setHours(23);
          finalEndDate?.setMinutes(59);
          finalEndDate?.setSeconds(59);
          finalEndDate?.setMilliseconds(999);

          onChange({
            showVoided: values.showVoided ?? undefined,
            dateType: values.dateType ?? '',
            beginDate: beginDate ?? values.beginDate ?? undefined,
            endDate: finalEndDate ?? undefined,
            unassignedOnly: values.unassignedOnly ?? false,
            staleOnly: values.staleOnly ?? false,
            starred: values.starred ?? false,
            warrantyStatus: values.warrantyStatus ?? undefined,
          });
        }}
        onReset={() => {
          onChange({
            showVoided: undefined,
            dateType: '',
            beginDate: undefined,
            endDate: undefined,
            unassignedOnly: false,
            staleOnly: false,
            starred: false,
            warrantyStatus: undefined,
          });
        }}
      >
        {({ handleSubmit, resetForm, values, errors }) => {
          return (
            <Form className="mt-2 flex flex-col gap-3" onSubmit={handleSubmit}>
              {page === 'claims' && (
                <>
                  <div>
                    <Input
                      type="checkbox"
                      name="unassignedOnly"
                      settings={{
                        checkboxLabel: t('general.unassignedClaims'),
                      }}
                    />
                  </div>
                  <div>
                    <Input
                      type="checkbox"
                      name="staleOnly"
                      settings={{
                        checkboxLabel: t('general.stale'),
                      }}
                    />
                  </div>
                  <div>
                    <Input
                      type="checkbox"
                      name="starred"
                      settings={{
                        checkboxLabel: t('general.starred'),
                      }}
                    />
                  </div>
                </>
              )}
              {page === 'registrations' && (
                <>
                  <div>{t('general.warrantyView')}</div>
                  <div>
                    <Input
                      type="radio"
                      name="showVoided"
                      settings={{
                        radioValue: 'Hide voided',
                        radioLabel: t('general.hideVoided'),
                        values: values,
                      }}
                    />
                  </div>
                  <div>
                    <Input
                      type="radio"
                      name="showVoided"
                      settings={{
                        radioValue: 'Show only voided',
                        radioLabel: t('general.showOnlyVoided'),
                        values: values,
                      }}
                    />
                  </div>
                </>
              )}
              {option?.showWarrantyStatus && (
                <>
                  <div>{t('general.status')}</div>
                  <div
                    role="group"
                    aria-labelledby="checkbox-group"
                    className="flex flex-col gap-2"
                  >
                    <div>
                      <Input
                        type="checkbox"
                        name="warrantyStatus"
                        settings={{
                          value: 'active',
                          checkboxLabel: t('general.active'),
                        }}
                      />
                    </div>
                    <div>
                      <Input
                        type="checkbox"
                        name="warrantyStatus"
                        settings={{
                          value: 'upcoming',
                          checkboxLabel: t('general.upcoming'),
                        }}
                      />
                    </div>
                    <div>
                      <Input
                        type="checkbox"
                        name="warrantyStatus"
                        settings={{
                          value: 'expired',
                          checkboxLabel: t('general.expired'),
                        }}
                      />
                    </div>
                    <div>
                      <Input
                        type="checkbox"
                        name="warrantyStatus"
                        settings={{
                          value: 'voided',
                          checkboxLabel: t('general.voided'),
                        }}
                      />
                    </div>
                  </div>
                </>
              )}
              <div className="flex flex-col gap-2">
                <span className="">{t('general.date')}</span>
                <Input
                  type="radio"
                  name="dateType"
                  settings={{
                    radioValue: 'This month',
                    radioLabel: t('general.thisMonth'),
                    values: values,
                  }}
                />
                <Input
                  type="radio"
                  name="dateType"
                  settings={{
                    radioValue: 'Last month',
                    radioLabel: t('general.lastMonth'),
                    values: values,
                  }}
                />
                <Input
                  type="radio"
                  name="dateType"
                  settings={{
                    radioValue: 'Date Range',
                    radioLabel: t('general.dateRange'),
                    values: values,
                  }}
                />
                <div
                  className={classNames('flex items-center space-x-3', {
                    ['pointer-events-none opacity-50']:
                      values.dateType !== 'Date Range',
                  })}
                >
                  <Input
                    type="datepicker"
                    name="beginDate"
                    settings={{
                      id: 'beginDate',
                      placeholder: 'dd/mm/yyyy',
                      maxDate: values.endDate || new Date(),
                    }}
                  />
                  <span className="font-nunito font-medium text-gray-900">
                    to
                  </span>
                  <Input
                    type="datepicker"
                    name="endDate"
                    settings={{
                      id: 'endDate',
                      placeholder: 'dd/mm/yyyy',
                      maxDate: new Date(),
                      minDate: values.beginDate || new Date(0),
                    }}
                  />
                </div>
              </div>
              <div className="justify-star mt-5 flex items-center space-x-4">
                <Button kind={'primary'} size={'sm'} type="submit">
                  {t('general.applyFilters')}
                </Button>
                <Button
                  kind={'outline'}
                  size={'sm'}
                  onClick={() => {
                    resetForm();
                  }}
                >
                  {t('general.reset')}
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default FiltersModal;
