import MenuItem from '@mui/material/MenuItem';
import { Select, SelectChangeEvent } from '@mui/material';

export interface MenuListDataProps {
  value: string;
  label: string;
  menuItem: {
    label: string;
    value: string;
  }[];
  handleChange: (event: SelectChangeEvent) => void;
  selectSize?: 'small' | 'medium';
}
export function MenuListSelect({
  value,
  label,
  menuItem,
  handleChange,
  selectSize = 'small',
}: MenuListDataProps) {
  return (
    <Select
      variant="standard"
      size={selectSize}
      value={value}
      label={label}
      onChange={handleChange}
    >
      {menuItem.map((data: any, index: number) => (
        <MenuItem key={index} value={data.value}>
          {data.value}
        </MenuItem>
      ))}
    </Select>
  );
}
