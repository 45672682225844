import {
  Heading,
  LoadingSpinner,
  MenuListSelect,
} from '@rabbit/elements/shared-components';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useRef, useState } from 'react';
import { CreateNewConsumerForm_FormValues } from '@rabbit/sage/components/organisms/ModalNewRegistration/tenant-config/types.ts';
import {
  FileStorageContext,
  getCompletedUploadsOfCategory,
  useSageAPI,
} from '@rabbit/bizproc/react';
import {
  DTVendable,
  FBD_Consumer_Private,
  FBD_Holding_Private,
  UserUploadedDocument,
} from '@rabbit/data/types';
import {
  CompletedUploadShape,
  UploadedFileCategories,
} from '@rabbit/elements/shared-types';
import {
  Card,
  CardContent,
  CardMedia,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { Error, ExpandMore } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
  accordionSummaryClasses,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { useQueryParams } from 'raviger';
import { SearchVendablesBySkuCode } from '@rabbit/search/cherchons';
import { CheckBadgeIcon } from '@heroicons/react/20/solid';
import { ConfigContext } from '@rabbit/config/context';
import PartnerDetailsView from '@rabbit/sage/views/PartnerDetailsView.tsx';
import CustomerDetailsView from '@rabbit/sage/views/CustomerDetailsView.tsx';
import ProductDetailsView from '@rabbit/sage/views/ProductDetailsView.tsx';
import { Box } from '@mui/system';
import { AVAILABLE_LANGUAGE_CODES } from '@rabbit/sage/utils/consts.ts';
import MenuItem from '@mui/material/MenuItem';
import { nestApiRegisterInstallerHoldings } from '@rabbit/bizproc/core';
const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  [`& .${accordionSummaryClasses.expandIconWrapper}.${accordionSummaryClasses.expanded}`]:
    {
      transform: 'rotate(90deg)',
    },
  [`& .${accordionSummaryClasses.content}`]: {
    marginLeft: theme.spacing(1),
  },
  ...theme.applyStyles('dark', {
    backgroundColor: 'rgba(255, 255, 255, .05)',
  }),
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function RegisterProductInstaller() {
  const { t, i18n } = useTranslation();
  const params = useQueryParams();
  const steps = ['installer-create', 'customer-create', 'register-product'];
  const [currentStep, setCurrentStep] = useState(steps[0]);
  const [customerData, setCustomerData] =
    useState<CreateNewConsumerForm_FormValues>();
  const [productData, setProductData] = useState<any>();
  const [installerData, setInstallerData] = useState<any>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [registered, setRegistered] = useState<boolean>(false);
  const [defaultSelected, setDefaultSelected] = useState<any>({});
  const [productLoaded, setProductLoaded] = useState<boolean>(false);
  const [vendable, setVendable] = useState<DTVendable | null>(null);
  const [errorSubmit, setErrorSubmit] = useState<boolean>(false);

  const {
    uploadQueueState,
    moveCompletedUploadsToAttached,
    isUpdating,
    updateHoldingWithFiles,
  } = useContext(FileStorageContext) || {};
  const { completed } = uploadQueueState ?? {};

  const installerAttachmentUploads: CompletedUploadShape[] =
    getCompletedUploadsOfCategory(
      completed ?? [],
      UploadedFileCategories.installation_attachment
    );

  const installationAttachmentFiles = installerAttachmentUploads?.flatMap(
    (file) =>
      file.category === UploadedFileCategories.installation_attachment &&
      file?.uploadedFile
        ? [file.uploadedFile]
        : []
  );

  const onSubmitProductData = async (values: any) => {
    setProductData(values);
    setIsSubmitting(true);
    const finalData = {
      ...installerData,
      ...customerData,
      ...values,
    };
    finalData.installation_attachment = installationAttachmentFiles;
    try {
      const response = await nestApiRegisterInstallerHoldings({
        formData: finalData,
        tenantLink: t('tenantLink'),
        newHoldingLink: holding_docid,
        newConsumerLink: newConsumerLink,
      });
      if (response) {
        setIsSubmitting(false);
        setRegistered(true);
      } else {
        setIsSubmitting(false);
        setErrorSubmit(true);
      }
    } catch (error) {
      setErrorSubmit(true);
    }
  };
  useEffect(() => {
    (async () => {
      const sku = params[0] && params[0].sku ? params[0].sku : '';
      if (sku && !productLoaded) {
        const vendableData = await SearchVendablesBySkuCode(sku);
        if (vendableData && vendableData.items[0]) {
          setVendable(vendableData.items[0]);
          setDefaultSelected({
            id: vendableData.items[0].docid,
            label: vendableData.items[0].title,
            value: vendableData.items[0].docid,
          });
          setProductLoaded(true);
        }
      } else {
        setProductLoaded(true);
      }
    })();
  }, [setProductLoaded]);

  const holding_private = FBD_Holding_Private.empty();
  const holding_docid = FBD_Holding_Private.keygen(
    holding_private,
    FBD_Holding_Private
  );

  const consumer_private = FBD_Consumer_Private.empty();
  const newConsumerLink = FBD_Consumer_Private.keygen(
    consumer_private,
    FBD_Consumer_Private
  );

  const [lang, setLang] = useState(window.localStorage.getItem('lang') ?? 'en');

  const handleChange = async (event: SelectChangeEvent) => {
    setLang(event.target.value as string);
    window.localStorage.setItem('lang', event.target.value as string);
    await i18n.changeLanguage(event.target.value as string);
  };
  const availableLanguages = AVAILABLE_LANGUAGE_CODES.map((data) => ({
    value: data.code,
    label: data.code,
  }));

  return (
    <>
      {(registered || errorSubmit) && (
        <div className="xs:w-full relative flex flex-col rounded-md bg-white md:m-auto md:w-[720px] md:shadow-[4px_6px_24px_0px_rgba(0,0,0,0.08)] lg:content-center">
          <div className="pl-6 pt-4 pb-4">
            <Heading kind="h4">
              {errorSubmit && (
                <>
                  <Error height={30} style={{ position: 'absolute' }} />{' '}
                  <span className="font-nunito ml-10">
                    {t('message.productRegisterFailed')}
                  </span>
                </>
              )}
              {registered && (
                <>
                  <CheckBadgeIcon
                    height={30}
                    style={{ position: 'absolute' }}
                  />{' '}
                  <span className="font-nunito ml-10">
                    {t('general.productRegisteredSuccess')}
                  </span>
                </>
              )}
            </Heading>
          </div>
        </div>
      )}
      {isSubmitting && (
        <div className="xs:w-full relative flex flex-col rounded-md bg-white md:m-auto md:w-[720px] md:shadow-[4px_6px_24px_0px_rgba(0,0,0,0.08)] lg:content-center">
          <div className="pl-6 pt-4 pb-4">
            <LoadingSpinner size={'lg'} />
          </div>
        </div>
      )}
      {!isSubmitting && !registered && (
        <div className="xs:w-full relative flex flex-col rounded-md bg-white md:m-auto md:w-[720px] md:shadow-[4px_6px_24px_0px_rgba(0,0,0,0.08)] lg:content-center">
          <div style={{ position: 'absolute', right: 10, top: 10 }}>
            <MenuListSelect
              label="lang"
              value={lang}
              handleChange={handleChange}
              menuItem={availableLanguages}
            />
          </div>
          <div className="p-2">
            <Card elevation={0} className="w-full" sx={{ display: 'flex' }}>
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <CardMedia
                  component="img"
                  sx={{ width: 151 }}
                  image={vendable && vendable.img ? vendable?.img[0] : ''}
                />
                <CardContent sx={{ flex: '1 0 auto', paddingTop: 1 }}>
                  <Typography
                    component="div"
                    variant="h6"
                    className="mt-0 pt-0"
                  >
                    <span className="font-nunito">
                      {t('general.productRegistration')}
                    </span>
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    component="div"
                    className="font-nunito"
                    sx={{ color: 'text.secondary' }}
                  >
                    <span className="font-nunito">
                      {vendable && vendable.title ? vendable.title : ''}
                    </span>
                  </Typography>
                </CardContent>
              </Box>
            </Card>
          </div>
          <Accordion expanded={currentStep === steps[0]}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography className="font-nunito" component="span">
                <span className="font-nunito">
                  {t('general.installerDetails')}
                </span>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <PartnerDetailsView
                steps={steps}
                setInstallerData={setInstallerData}
                setCurrentStep={setCurrentStep}
              />
            </AccordionDetails>
          </Accordion>

          <Accordion expanded={currentStep === steps[1]}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography component="span">
                <span className="font-nunito">
                  {t('general.customerDetails')}
                </span>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <CustomerDetailsView
                steps={steps}
                setCustomerData={setCustomerData}
                setCurrentStep={setCurrentStep}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={currentStep === steps[2]}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography component="span">
                <span className="font-nunito">
                  {t('general.productDetails')}
                </span>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ProductDetailsView
                steps={steps}
                setCurrentStep={setCurrentStep}
                defaultSelected={defaultSelected}
                onSubmitProductData={onSubmitProductData}
                holding_docid={holding_docid}
                newConsumerLink={newConsumerLink}
              />
            </AccordionDetails>
          </Accordion>
        </div>
      )}
    </>
  );
}
