import { useContext, useMemo, useState } from 'react';
import {
  PencilSquareIcon,
  PlusIcon,
  TrashIcon,
  MagnifyingGlassIcon,
  XMarkIcon,
  CheckIcon,
} from '@heroicons/react/24/solid';
import { PersonaTypeSingleLetter } from '@rabbit/data/types';
import {
  ButtonIcon,
  CardWrapperWithHeader,
  LoadingSpinner,
  Modal,
  getCurrencyFormat,
} from '@rabbit/elements/shared-components';
import ModalAddEditInvoice, {
  ModalAddEditInvoiceModes,
} from '@rabbit/sage/components/organisms/ModalAddEditInvoice/ModalAddEditInvoice';
import { useAppInfo } from '../../../../utils/helpers';
import { DocumentIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { CaseflowContext } from '@rabbit/sage/context/CaseflowContext';
import {
  CaseFlowStations_Fatbikes,
  CFCF_ExternalRepairInvoice,
  ExternalRepairInvoiceStatus,
} from '@rabbit/bizproc/core';
import { FileStorageContext } from '@rabbit/bizproc/react';
import { UploadedFileCategories } from '@rabbit/elements/shared-types';
import { CreateTable } from '@rabbit/sage/utils/CreateTable';

// TODO: ADD APPROVE / REJECT FOR WARRANTORS

export default function ClaimInvoiceSection() {
  const { t } = useTranslation();
  const caseFlowContext = useContext(CaseflowContext);
  const {
    caseFacts,
    alterCaseFacts,
    operatingPersonaSingleKey,
    caseState,
    caseAlterability,
    moveSpotlight,
    executeAction,
  } = caseFlowContext || {};
  const fileStorageContext = useContext(FileStorageContext);
  const { deleteFile } = fileStorageContext || {};
  const external_repair_invoices = caseFacts?.external_repair_invoices;

  // this should really only be one state but I can't be bothered to refactor it right now. - DC
  const [deleteModal, setDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [uploadSuccessful, setUploadSuccessful] = useState(false);
  const [invoiceModal, setInvoiceModal] = useState(false);
  const [modalData, setModalData] = useState<{
    data: CFCF_ExternalRepairInvoice;
    mode?: ModalAddEditInvoiceModes;
  } | null>(null);
  const appInfo = useAppInfo();

  function renderNoInvoiceText() {
    if (operatingPersonaSingleKey === PersonaTypeSingleLetter.Repairer) {
      if (caseState === CaseFlowStations_Fatbikes.REPAIR_INVOICE_SUBMISSION) {
        return t('message.noInvoicesAddedMessage');
      } else {
        return t('message.noInvoicesAdded');
      }
    }
    if (operatingPersonaSingleKey === PersonaTypeSingleLetter.Warrantor) {
      return t('message.noInvoicesAddedByRepairer');
    }
  }

  if (!external_repair_invoices)
    return (
      <>
        <CardWrapperWithHeader
          title={t('general.invoices')}
          noPadding={true}
          collapsedByDefault={false}
          headerRight={
            operatingPersonaSingleKey === PersonaTypeSingleLetter.Repairer &&
            caseFacts?.final_assessment !== 'Not covered' &&
            caseState ===
              CaseFlowStations_Fatbikes.REPAIR_INVOICE_SUBMISSION && (
              <ButtonIcon
                type="primary"
                label={t('general.addInvoice')}
                Icon={PlusIcon}
                iconLeft
                onClick={() => {
                  setModalData(null);
                  setInvoiceModal(true);
                }}
              />
            )
          }
        >
          <p className="p-5">{renderNoInvoiceText()}</p>
        </CardWrapperWithHeader>
        {invoiceModal && (
          <ModalAddEditInvoice
            handleClose={() => setInvoiceModal(false)}
            data={modalData?.data}
            mode={modalData?.mode}
          />
        )}
      </>
    );

  // order invoices by timestamp, just in case
  external_repair_invoices.sort((a, b) => b.timestamp - a.timestamp);

  /* ------------------------------- Table setup ------------------------------ */
  const InvoiceTable = useMemo(
    () =>
      CreateTable<CFCF_ExternalRepairInvoice>(external_repair_invoices, [
        {
          header: t('general.document'),
          value: (invoice) => invoice.file.ogFilename,
          size: 180,
          Cell: ({ cell, row }: any) => {
            return (
              <div
                className="flex w-full cursor-pointer items-center gap-2 break-all text-green-600 underline underline-offset-4"
                onClick={(e) => {
                  e.stopPropagation();
                  window.open(row.original.actions.file.url, '_blank');
                }}
              >
                <DocumentIcon className="h-4 w-4" />
                {cell.getValue()}
              </div>
            );
          },
        },
        {
          header: t('general.date'),
          type: 'Date',
          value: (invoice) => invoice.timestamp,
        },
        {
          header: t('general.status'),
          type: 'Status',
          value: (invoice) => invoice.status,
        },
        {
          header: t('general.cost'),
          value: (invoice) =>
            getCurrencyFormat(invoice.totalCost.amount, appInfo.currency),
        },
        {
          header: '',
          accessorKey: 'actions',
          size: 15,
          value: (invoice) => invoice,
          Cell: ({ cell }: any) => {
            // Invoices can only be edited or deleted by the repairer, but only when caseState === ASSIGNED and the invoice is UNSUBMITTED

            if (
              operatingPersonaSingleKey === PersonaTypeSingleLetter.Repairer
            ) {
              if (
                external_repair_invoices[cell.row.index].status ===
                  ExternalRepairInvoiceStatus.REJECTED ||
                external_repair_invoices[cell.row.index].status ===
                  ExternalRepairInvoiceStatus.APPROVED
              ) {
                return (
                  <div className="text-primary-900 flex cursor-pointer justify-center gap-1 text-right">
                    <MagnifyingGlassIcon
                      className="h-[20px] w-[20px]"
                      onClick={() => {
                        setModalData({
                          data: external_repair_invoices[cell.row.index],
                          mode: 'repairer-review',
                        });
                        setInvoiceModal(true);
                      }}
                    />
                  </div>
                );
              } else {
                return (
                  <div className="text-primary-900 flex cursor-pointer justify-center gap-1 text-right">
                    <PencilSquareIcon
                      className="h-[20px] w-[20px]"
                      onClick={() => {
                        setModalData({
                          data: external_repair_invoices[cell.row.index],
                          mode: 'add-edit',
                        });
                        setInvoiceModal(true);
                      }}
                    />
                    <TrashIcon
                      className="h-[20px] w-[20px]"
                      onClick={() => {
                        setModalData({
                          data: external_repair_invoices[cell.row.index],
                          mode: 'add-edit',
                        });
                        setDeleteModal(true);
                      }}
                    />
                  </div>
                );
              }
            }

            if (
              operatingPersonaSingleKey === PersonaTypeSingleLetter.Warrantor
            ) {
              if (
                external_repair_invoices[cell.row.index].status ===
                ExternalRepairInvoiceStatus.IN_REVIEW
              ) {
                return (
                  <div className="text-primary-900 flex cursor-pointer justify-center gap-4 text-right">
                    <XMarkIcon
                      className="h-[20px] w-[20px] text-red-600"
                      onClick={async () => {
                        setLoading(true);
                        await updateInvoiceStatus(
                          external_repair_invoices[cell.row.index],
                          ExternalRepairInvoiceStatus.REJECTED
                        );
                        setLoading(false);
                      }}
                    />
                    <CheckIcon
                      className="h-[20px] w-[20px]"
                      onClick={async () => {
                        setLoading(true);
                        await updateInvoiceStatus(
                          external_repair_invoices[cell.row.index],
                          ExternalRepairInvoiceStatus.APPROVED
                        );
                        setLoading(false);
                      }}
                    />
                  </div>
                );
              } else {
                return (
                  <div className="text-primary-900 flex cursor-pointer justify-center gap-1 text-right">
                    <MagnifyingGlassIcon
                      className="h-[20px] w-[20px]"
                      onClick={() => {
                        setModalData({
                          data: external_repair_invoices[cell.row.index],
                          mode: 'add-edit',
                        });
                        setInvoiceModal(true);
                      }}
                    />
                  </div>
                );
              }
            }
          },
        },
      ]),
    [external_repair_invoices]
  );

  /* ---------------------------- Submission handling --------------------------- */
  // async function submitInvoice() {
  //   if (
  //     executeAction &&
  //     moveSpotlight &&
  //     external_repair_invoices &&
  //     caseAlterability
  //   ) {
  //     try {
  //       // Alter the case facts to update the invoice status
  //       alterCaseFacts &&
  //         alterCaseFacts({
  //           external_repair_invoices: external_repair_invoices.map(
  //             (invoice) => {
  //               if (
  //                 invoice.status === ExternalRepairInvoiceStatus.UNSUBMITTED
  //               ) {
  //                 return {
  //                   ...invoice,
  //                   status: ExternalRepairInvoiceStatus.IN_REVIEW,
  //                 };
  //               }
  //               return invoice;
  //             }
  //           ),
  //         });

  //       await executeAction('submit_invoice');
  //       await moveSpotlight(PersonaTypeSingleLetter.Warrantor);
  //       toast.success(t('Invoice submitted successfully!'));
  //     } catch (e) {
  //       console.log(e);
  //       toast.error(t('Failed to submit invoice'));
  //     }
  //   }
  // }

  /* ---------------------------- Deletion handling --------------------------- */
  async function deleteInvoice() {
    // Filter the invoices to remove the one that matches the modal data
    if (external_repair_invoices) {
      const newInvoices = external_repair_invoices.filter(
        (invoice) => invoice.timestamp !== modalData?.data.timestamp
      );
      // Update the case facts
      alterCaseFacts &&
        alterCaseFacts({
          external_repair_invoices: newInvoices,
        });

      // delete the file from the file storage
      deleteFile &&
        modalData?.data.file.metadata.fullPath &&
        deleteFile(modalData?.data.file.metadata.fullPath, {
          category: UploadedFileCategories.ExternalRepairInvoices,
        });
    }
  }

  async function updateInvoiceStatus(
    incoming: CFCF_ExternalRepairInvoice,
    status: ExternalRepairInvoiceStatus
  ) {
    if (!moveSpotlight || !executeAction || !alterCaseFacts) return;
    let factsToAlter: any = {};
    factsToAlter = {
      ...factsToAlter,
      external_repair_invoices: caseFacts?.external_repair_invoices?.map(
        (invoice) => {
          if (invoice.timestamp === incoming?.timestamp) {
            return {
              ...invoice,
              status,
            };
          }
          return invoice;
        }
      ),
    };
    await moveSpotlight(PersonaTypeSingleLetter.Repairer);
    executeAction(
      status === ExternalRepairInvoiceStatus.APPROVED
        ? 'approve_invoice'
        : 'reject_invoice'
    );
    await alterCaseFacts(factsToAlter);
  }

  const unsubmittedInvoices = external_repair_invoices.filter(
    (invoice) => invoice.status === ExternalRepairInvoiceStatus.UNSUBMITTED
  );

  if (uploadSuccessful && unsubmittedInvoices.length > 0) {
    setUploadSuccessful(false);
    setModalData({
      data: unsubmittedInvoices[0],
      mode: 'submit',
    });
    setInvoiceModal(true);
  }

  const renderSectionHeaderRight = () => {
    if (
      operatingPersonaSingleKey === PersonaTypeSingleLetter.Repairer &&
      caseFacts?.final_assessment !== 'Not covered' &&
      caseState === CaseFlowStations_Fatbikes.REPAIR_INVOICE_SUBMISSION
    ) {
      if (unsubmittedInvoices.length === 0) {
        return (
          <ButtonIcon
            type="primary"
            label={t('general.addInvoice')}
            Icon={PlusIcon}
            iconLeft
            onClick={() => {
              setModalData(null);
              setInvoiceModal(true);
            }}
          />
        );
      }
      if (unsubmittedInvoices.length > 0) {
        if (caseAlterability) {
          return (
            <ButtonIcon
              type="primary"
              label={t('general.submitInvoice')}
              iconLeft
              onClick={() => {
                setModalData({
                  data: unsubmittedInvoices[0],
                  mode: 'submit',
                });
                setInvoiceModal(true);
              }}
            />
          );
        } else {
          return (
            <ButtonIcon
              type="primary"
              label={`${t('general.loading')}...`}
              Icon={PlusIcon}
              iconLeft
              onClick={() => void 0}
              disabled
            />
          );
        }
      }
    }
  };

  const handleRowClick = (invoice: CFCF_ExternalRepairInvoice) => {
    let mode: ModalAddEditInvoiceModes = 'submit';

    if (operatingPersonaSingleKey === PersonaTypeSingleLetter.Repairer) {
      if (
        invoice.status === ExternalRepairInvoiceStatus.REJECTED ||
        invoice.status === ExternalRepairInvoiceStatus.APPROVED
      ) {
        mode = 'repairer-review';
      } else if (invoice.status === ExternalRepairInvoiceStatus.UNSUBMITTED) {
        mode = 'submit';
      } else {
        mode = 'add-edit';
      }
    }

    setModalData({
      data: invoice,
      mode: mode || 'add-edit',
    });
    setInvoiceModal(true);
  };

  /* ----------------------------------- TSX ---------------------------------- */

  return (
    <CardWrapperWithHeader
      title={t(`general.claimInvoices`)}
      noPadding={true}
      collapsedByDefault={false}
      headerRight={renderSectionHeaderRight()}
    >
      <div>
        {loading && <LoadingSpinner size="md" overlay={true} />}
        <InvoiceTable
          enablePagination={external_repair_invoices.length > 10}
          initialState={{ showGlobalFilter: true }}
          muiSearchTextFieldProps={{
            sx: {
              display: 'none',
            },
          }}
          muiTopToolbarProps={{
            sx: {
              display: 'none',
            },
          }}
          muiTableHeadCellProps={{
            className: 'relative bg-gray-200 uppercase font-light',
          }}
          muiTableBodyCellProps={{
            className: 'px-4 py-0',
          }}
          onClickRow={handleRowClick}
        />

        {invoiceModal && (
          <ModalAddEditInvoice
            handleClose={(submitted) => {
              setInvoiceModal(false);
              setModalData(null);
              if (submitted === true) {
                setUploadSuccessful(submitted);
              }
            }}
            data={modalData?.data}
            mode={modalData?.mode}
          />
        )}
        {deleteModal && (
          <Modal
            kind={'pop-up'}
            settings={{
              text: t(`message.confirmDeleteInvoice`),
              primaryButtonText: t('general.yes'),
              outlineButtonText: t('general.cancel'),
              loading: false,
              handlePrimaryClick: async () => {
                await deleteInvoice();
                setDeleteModal(false);
              },
              handleOutlineClick: () => setDeleteModal(false),
            }}
          />
        )}
      </div>
    </CardWrapperWithHeader>
  );
}
