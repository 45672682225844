import {
  Empty_NoSqlDoc,
  FBD_Keygen_BANNED,
  MakeFBDocType,
  Z_NoSqlDoc,
} from '@rabbit/firebase/doctype';
import { z, schema } from '@rabbit/utils/ts';

import { NoSqlDoc, NoSqlDocYupSchema } from '@rabbit/firebase/doctype';
import { OneEachOptionalPersonaLink } from '.';
import { string, SchemaOf, array, object, number } from 'yup';

/** Identity defines who the user is and what personas they have.
 *
 * As it is a cornerstone of access control, we will only allow carefully validated writes via cloud functions.
 */

/* -------------------------------------------------------------------------- */
/*                             DTIdentity_Private                             */
/* -------------------------------------------------------------------------- */
export interface DTIdentity_Private extends NoSqlDoc {
  displayname: string;
  email: string;
  photoUrl: string;

  persona: OneEachOptionalPersonaLink;

  /** List of all the personas this user is a delegate of (eg I work for Sony) */
  delegate: string[];

  /** Last time we refreshed the claims for this user */
  claimsRefreshTime?: number;

  languageCode?: string;
}

export type IdentityLink = string;
export const Z_IdentityLink = z.string();

// Sage and Olive will not work without this - dc
// Error thrown: Uncaught ReferenceError: Cannot access 'Z_OneEachOptionalPersonaLink' before initialization

const Z_OneEachOptionalPersonaLink = schema<OneEachOptionalPersonaLink>()(
  z.object({
    consumer: z.string().optional(),
    manufacturer: z.string().optional(),
    repairer: z.string().optional(),
    retailer: z.string().optional(),
  })
);

export const Z_DTIdentity_Private = schema<DTIdentity_Private>()(
  z
    .object({
      displayname: z.string(),
      email: z.string().email(),
      photoUrl: z.string().url(),
      persona: Z_OneEachOptionalPersonaLink,
      delegate: z.array(z.string()),
    })
    .merge(Z_NoSqlDoc)
);

export const bootstrapValidationSchema: SchemaOf<DTIdentity_Private> =
  NoSqlDocYupSchema.shape({
    displayname: string().required('displayname is required'),
    email: string().email().required('email is required'),
    photoUrl: string().defined('photoUrl is required'),
    persona: object().shape({
      consumer: string().optional(),
      manufacturer: string().optional(),
      repairer: string().optional(),
      retailer: string().optional(),
      warrantor: string().optional(),
      warrantyDealer: string().optional(),
      installer: string().optional(),
    }),
    delegate: array()
      .of(string().required())
      .required('delegate is required')
      .min(0),
    claimsRefreshTime: number().optional(),
    languageCode: string().optional(),
  });

export const FBD_Identity_Private = MakeFBDocType<DTIdentity_Private>({
  name: 'DTIdentity_Private',
  collection: 'identity_private',
  empty: () => {
    const result: DTIdentity_Private = {
      ...Empty_NoSqlDoc(),
      displayname: '',
      email: '',
      photoUrl: '',
      delegate: [],
      persona: {},
    };
    return result;
  },
  keygen: FBD_Keygen_BANNED('Identity ID comes from auth'),
  validationSchema: bootstrapValidationSchema,
});

export function Empty_DTIdentity_Private() {
  return FBD_Identity_Private.empty();
}

export function Validate_DTIdentity_Private(identity: DTIdentity_Private) {
  if (!identity.displayname) throw new Error('displayname is required');
  if (!identity.email) throw new Error('email is required');
  if (!identity.photoUrl) throw new Error('photoUrl is required');
  return true;
}

/* -------------------------------------------------------------------------- */
/*                                 DTIdentity                                 */
/* -------------------------------------------------------------------------- */
export interface DTIdentity extends NoSqlDoc {
  displayname: string;
  photoUrl: string;
  languageCode?: string;
}

export const Z_DTIdentity = schema<DTIdentity>()(
  z
    .object({
      displayname: z.string(),
      photoUrl: z.string().url(),
      languageCode: z.string(),
    })
    .merge(Z_NoSqlDoc)
);

export function Empty_DTIdentity() {
  const identity: DTIdentity = {
    ...Empty_NoSqlDoc(),
    displayname: '',
    photoUrl: '',
  };
  return identity;
}

export function Validate_DTIdentity(identity: DTIdentity) {
  if (!identity.displayname) throw new Error('displayname is required');
  if (!identity.photoUrl) throw new Error('photoUrl is required');
  return true;
}

export const FBD_Identity = MakeFBDocType<DTIdentity>({
  name: 'DTIdentity',
  collection: 'identity',
  empty: () => {
    const result: DTIdentity = {
      ...Empty_NoSqlDoc(),
      displayname: '',
      photoUrl: '',
    };
    return result;
  },
  keygen: FBD_Keygen_BANNED('Identity ID comes from auth'),
});
