import { useGetMySagePersonas } from '@rabbit/data/portal';
import {
  DTIdentity_Private,
  DTRepairer_Private,
  DTRetailer_Private,
} from '@rabbit/data/types';
import React, { useEffect } from 'react';

type SagePersonaCheckerProps = {
  // setIdentityPrivate: React.Dispatch<
  //   React.SetStateAction<DTIdentity_Private | null>
  // >;
  setRepairerPersonaData: React.Dispatch<
    React.SetStateAction<DTRepairer_Private | null>
  >;
  setInstallerPersonaId: React.Dispatch<React.SetStateAction<string | null>>;
  setInstallerPersonaData: React.Dispatch<
    React.SetStateAction<DTRepairer_Private | null>
  >;
  setRepairerPersonaId: React.Dispatch<React.SetStateAction<string | null>>;
  setRetailerPersonaData: React.Dispatch<
    React.SetStateAction<DTRetailer_Private | null>
  >;
  setRetailerPersonaId: React.Dispatch<React.SetStateAction<string | null>>;
  // setManufacturerPersonaData: React.Dispatch<
  //   React.SetStateAction<DTManufacturer_Private | null>
  // >;
  // setManufacturerPersonaId: React.Dispatch<React.SetStateAction<string | null>>;
};

// TO CHECK: isLoading - is it the best option here?
export function SagePersonaChecker({
  //setIdentityPrivate,
  setRepairerPersonaData,
  setRepairerPersonaId,
  setInstallerPersonaId,
  setInstallerPersonaData,
  setRetailerPersonaData,
  setRetailerPersonaId,
}: // setManufacturerPersonaData,
// setManufacturerPersonaId,
SagePersonaCheckerProps) {
  const {
    repairerPersona,
    retailerPersona,
    installerPersona,
    queryStatus,
    //manufacturerPersona
  } = useGetMySagePersonas();

  const queryResults = queryStatus.queryResults;
  const {
    isLoading: repairerIsLoading,
    isLoadingError: repairerIsLoadingError,
    isError: repairerIsError,
  } = queryResults.repairer_private;
  const {
    isLoading: retailerIsLoading,
    isLoadingError: retailerIsLoadingError,
    isError: retailerIsError,
  } = queryResults.retailer_private;
  const {
    isLoading: installerIsLoading,
    isLoadingError: installerIsLoadingError,
    isError: installerIsError,
  } = queryResults.repairer_private;

  useEffect(() => {
    if (
      installerPersona?.repairer_private &&
      !installerIsError &&
      !installerIsLoading &&
      !installerIsLoadingError
    ) {
      setInstallerPersonaId(installerPersona.personaId);
      setInstallerPersonaData(installerPersona.repairer_private);
    }
  }, [installerPersona?.personaId, installerPersona?.repairer_private?.docid]);

  useEffect(() => {
    if (
      repairerPersona?.repairer_private &&
      repairerIsError === false &&
      repairerIsLoading === false &&
      repairerIsLoadingError === false
    ) {
      setRepairerPersonaData(repairerPersona.repairer_private);
      setRepairerPersonaId(repairerPersona.personaId);
      //setIdentityPrivate(identityPrivate);
      // NOTE: Every flag that needs to be instantly updated (e.g: t&cs) should be added to dependency array below.
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, [repairerPersona?.personaId, repairerPersona?.repairer_private?.docid]);

  useEffect(() => {
    if (
      retailerPersona?.retailer_private &&
      retailerIsError === false &&
      retailerIsLoading === false &&
      retailerIsLoadingError === false
    ) {
      setRetailerPersonaData(retailerPersona.retailer_private);
      setRetailerPersonaId(retailerPersona.personaId);
      //setIdentityPrivate(identityPrivate);
      // NOTE: Every flag that needs to be instantly updated (e.g: t&cs) should be added to dependency array below.
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [retailerPersona?.personaId, retailerPersona?.retailer_private?.docid]);

  return null;
}
