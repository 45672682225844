import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Button, InfoText, Input } from '@rabbit/elements/shared-components';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from '@rabbit/sage/context/UserContext';
import { useGetConsumerHoldings } from '@rabbit/bizproc/react';
import { DTHoldingProxy, FBD_Vendable } from '@rabbit/data/types';
import { fixCategoryAndSortHoldingsByTCreate } from '@rabbit/sage/utils/helpers';
import { NewClaimModalSteps } from '../../ModalNewClaim/ModalNewClaim';
import { t } from 'i18next';
import { ConfigContext } from '@rabbit/config/context';

export const validationSchema = Yup.object().shape({
  consumer_email: Yup.string().trim().required('Select user or add a new one'),
});

export type CreateNewSelectUserKinds = 'new-claim' | 'new-registration';
export interface CreateNewSelectUserProps {
  handleClose: () => void;
  onChange: (step: NewClaimModalSteps, data: any) => void;
  data: any;
  setLoading: (loading: boolean) => void;
  kind: CreateNewSelectUserKinds;
}

interface ConsumerDataShape {
  consumer_email: string;
}

export function CreateNewSelectUser(props: CreateNewSelectUserProps) {
  const { onChange, handleClose, setLoading, kind, data } = props;
  const { config } = useContext(ConfigContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const userContext = useContext(UserContext);
  const [consumer, setConsumer] = useState<any>(
    data?.previewData?.consumer || {}
  );
  const [productResponse, setProductResponse] = useState(null) as any;
  const [productLoaded, setProductLoaded] = useState(false);
  const { getAllHoldingProxies } = useGetConsumerHoldings(consumer?.id || '');

  const initialValues: ConsumerDataShape = {
    consumer_email: data?.previewData?.consumer?.value || '',
  } as any;

  const onSubmit = async (values: any) => {
    setIsSubmitting(true);
    setLoading(true);
    let resultHoldings: any[] = [];
    const holdings: DTHoldingProxy[] | Error = await getAllHoldingProxies();
    if (holdings instanceof Error) resultHoldings = [];
    else {
      const finalHoldings = fixCategoryAndSortHoldingsByTCreate(holdings);
      resultHoldings = finalHoldings.filter(
        (i) => i.active_cases?.length === 0
      );
    }
    setLoading(false);
    if (resultHoldings.length > 0) {
      onChange('choose-product', {
        ...values,
        consumer,
        holdings: resultHoldings,
        consumer_persona_id: consumer.id,
        previewData: { ...data?.previewData, consumer },
        productResponse: productResponse,
      });
    } else {
      onChange('add-product', {
        ...values,
        holdings: resultHoldings,
        consumer,
        previewData: { ...data?.previewData, consumer },
        productResponse: productResponse,
      });
    }
  };

  useEffect(() => {
    (async () => {
      const sku = window.localStorage.getItem('LRP_Vendablempn');
      if (sku && !productLoaded) {
        const vendableData = await FBD_Vendable.query()
          .where('mpn', '==', sku)
          .getContents();
        setProductLoaded(true);
        if (vendableData && vendableData[0]) {
          setProductResponse({
            id: vendableData[0].docid,
            label: vendableData[0].title,
            value: vendableData[0].docid,
          });
        }
      } else {
        setProductLoaded(true);
      }
    })();
  }, [productLoaded, setProductResponse]);

  function displayAddNewUser() {
    if (
      (kind === 'new-claim' &&
        config.CLAIMS.CLAIMS_FLOW.NEW_CLAIM.NEW_USER_BTN) ||
      (kind === 'new-registration' &&
        config.HOLDINGS.NEW_REGISTRATION_FLOW.NEW_USER_BTN)
    ) {
      return (
        <Button
          kind="primary"
          className="w-full"
          onClick={() => onChange('new-consumer', null)}
        >
          {t('general.addNewCustomer')}
        </Button>
      );
    }
    return null;
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      validateOnChange={true}
      validateOnBlur={false}
    >
      {({ values, errors }) => (
        <Form className="flex-col gap-3 p-5">
          <InfoText>
            {' '}
            {kind === 'new-claim'
              ? t('COPY_CREATE_NEW_SELECT_USER_CLAIMS')
              : t('COPY_CREATE_NEW_SELECT_USER_REGISTRATIONS')}{' '}
          </InfoText>
          <div className="mb-4 flex">
            <Input
              type="autocomplete-consumer"
              name="consumer_email"
              label={`${t('general.email')}*`}
              onChange={(item) => setConsumer(item)}
              settings={{
                id: 'consumer_email',
                placeholder: t('general.enterAnEmailToStartSearching'),
                hint: '*required',
                token: userContext?.user?.accessToken,
                customButton: displayAddNewUser(),
                options: data?.previewData?.consumer
                  ? [data?.previewData?.consumer]
                  : undefined,
                value: consumer?.value || '',
              }}
            />
          </div>
          <div className="mt-5 flex w-full gap-4">
            <Button
              kind="primary"
              type="submit"
              loading={isSubmitting}
              disabled={errors && errors.consumer_email ? true : false}
            >
              {t('general.continue')}
            </Button>
            <Button kind="outline_red" type="submit" onClick={handleClose}>
              {t('general.cancel')}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default CreateNewSelectUser;
