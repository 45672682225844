import { UserContext } from '@rabbit/sage/context/UserContext';
import { useContext, useState, useEffect } from 'react';
import TableRegistrations, {
  FormValues,
} from '../../molecules/TableRegistrations/TableRegistrations';
import { TenantType } from '@rabbit/sage/views/RegistrationsView';
import { HoldingListFilters, useGetHoldingList } from '@rabbit/bizproc/react';
import { useTranslation } from 'react-i18next';
import {
  PersonaIdTypeSplitter,
  PersonaTypeSingleLetter,
} from '@rabbit/data/types';

export interface TabPartnerRegistrationsProps {
  partnerId: string;
  onHoldingsChange?: (holdings: any) => void;
  partner: any;
}

function TabPartnerRegistrations({
  partnerId,
  onHoldingsChange,
  partner,
}: TabPartnerRegistrationsProps) {
  const {
    accessToken,
    repairerPersonaId,
    retailerPersonaId,
    installerPersonaId,
  } = useContext(UserContext) ?? ({} as any);
  // TODO: In the future it would be nice to get this directly from the context - DC
  const activePersonaIds = {
    repairer: repairerPersonaId,
    retailer: retailerPersonaId,
    installer: installerPersonaId,
  };
  const { t } = useTranslation();

  const [filters, setFilters] = useState<FormValues>({
    beginDate: undefined,
    endDate: undefined,
  });

  const holdingListFilters: HoldingListFilters = {
    beginDate: filters.beginDate,
    endDate: filters.endDate,
  };
  let singleLetterPersona = '';
  if (partner.principals && partner.principals[0]) {
    const principal = partner.principals[0];
    singleLetterPersona = principal.split(PersonaIdTypeSplitter)[0];
  }

  if (singleLetterPersona === PersonaTypeSingleLetter.Installer) {
    holdingListFilters.installer = `${singleLetterPersona}:${partnerId}`;
  } else if (partner.type) {
    holdingListFilters.installer = partner.docid;
  } else {
    holdingListFilters.retailers = [`S:${partnerId}`];
  }

  let tenantType: TenantType = 'defaultTenants';

  const {
    holdingList,
    // getNextPage,
    //totalHoldingCount,
    isLoading,
  } = useGetHoldingList(
    //pageSize,
    t('tenantLink'),
    accessToken,
    activePersonaIds,
    holdingListFilters
  );

  if (
    t('tenantLink') === 'WARRANTYIRELAND' ||
    t('tenantLink') === 'PINNACLEWARRANTIES'
  )
    tenantType = 'carTenants';
  if (t('tenantLink') === 'NUCOVER') tenantType = 'tableTenants';

  const onChangeFilters = (value: {
    beginDate?: Date | undefined;
    endDate?: Date | undefined;
  }) => {
    setFilters(value);
  };

  useEffect(() => {
    if (holdingList?.data && onHoldingsChange) {
      onHoldingsChange(holdingList.data);
    }
  }, [holdingList]);

  return (
    <div className="relative z-10 flex w-full items-center justify-between">
      <TableRegistrations
        isLoading={isLoading}
        tenantType={tenantType}
        holdingList={holdingList}
        filters={filters}
        onFilter={onChangeFilters}
        isRegularTenant={true}
      />
    </div>
  );
}

export default TabPartnerRegistrations;
