import { Form, Formik } from 'formik';
import {
  asyncValidateSchema,
  Button,
  Input,
} from '@rabbit/elements/shared-components';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Dispatch, SetStateAction, useRef, useState } from 'react';
import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import { NAME_VALIDATION_REGEX } from '@rabbit/sage/utils/consts.ts';

interface InstallerFormValuesShape {
  installer_org_name: string;
  installer_first_name: string;
  installer_last_name: string;
  installer_email: string;
  installer_telephone: string;
}

const installerInitialValues: InstallerFormValuesShape = {
  installer_org_name: '',
  installer_first_name: '',
  installer_last_name: '',
  installer_email: '',
  installer_telephone: '',
};
interface partnerDetailsProps {
  steps: any;
  setInstallerData: Dispatch<SetStateAction<any>>;
  setCurrentStep: Dispatch<SetStateAction<string>>;
}

export default function PartnerDetailsView({
  steps,
  setInstallerData,
  setCurrentStep,
}: partnerDetailsProps) {
  const { t } = useTranslation();
  const formikRefInstaller = useRef(null) as any;
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onInstallerSubmit = async (values: any) => {
    await setCurrentStep(steps[1]);
    await setInstallerData(values);
  };
  const validationSchemeInstaller = () =>
    Yup.object().shape({
      installer_org_name: Yup.string(),
      installer_first_name: Yup.string()
        .required(t('message.pleaseEnterFirstName'))
        .matches(
          NAME_VALIDATION_REGEX,
          t('message.firstNameMustStartWithCapitalAndContainOnlyLetters')
        ),
      installer_last_name: Yup.string()
        .trim()
        .required(t('message.pleaseEnterLastName'))
        .matches(
          NAME_VALIDATION_REGEX,
          t('message.lastNameMustStartWithCapitalAndContainOnlyLetters')
        ),
      installer_email: Yup.string()
        .email(t('message.pleaseEnterAValidEmailAddress'))
        .required(t('message.emailCannotBeEmpty')),
      installer_telephone: Yup.string()
        .min(6, t('message.phoneNumberMinLength'))
        .required(t('message.pleaseEnterAPhoneNumber')),
    });

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Formik
        initialValues={installerInitialValues}
        validate={asyncValidateSchema(validationSchemeInstaller())}
        validateOnBlur
        validateOnChange={true}
        onSubmit={async (values) => onInstallerSubmit(values)}
        innerRef={formikRefInstaller}
      >
        {({ errors, values, setFieldValue }) => (
          <Form className="font-nunito flex flex-col gap-3">
            <Grid
              container
              spacing={2}
              sx={{ paddingLeft: 2, paddingRight: 2 }}
            >
              <Grid item xs={12} md={4} lg={4}>
                <Input
                  type="text"
                  name="installer_org_name"
                  label={t('general.organisationName')}
                  settings={{
                    id: 'installer_org_name',
                    placeholder: t('general.organisationName'),
                    forceUpperCaseFirstLetter: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4} lg={4}>
                <Input
                  type="text"
                  name="installer_first_name"
                  label={`${t('general.firstName')}*`}
                  settings={{
                    id: 'installer_first_name',
                    placeholder: t('general.firstName'),
                    forceUpperCaseFirstLetter: true,
                    hint: t('*required'),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4} lg={4}>
                <Input
                  type="text"
                  name="installer_last_name"
                  label={`${t('general.lastName')}*`}
                  settings={{
                    id: 'installer_last_name',
                    forceUpperCaseFirstLetter: true,
                    placeholder: t('general.lastName'),
                    hint: t('*required'),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <Input
                  type="email"
                  name="installer_email"
                  label={`${t('general.email')}*`}
                  settings={{
                    id: 'installer_email',
                    placeholder: t('example@example.com'),
                    hint: t('*required'),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <Input
                  type="phone"
                  name="installer_telephone"
                  label={`${t('general.phoneNumber')}*`}
                  settings={{
                    id: 'installer_telephone',
                    placeholder: t('general.phoneNumber'),
                    hint: t('*required'),
                  }}
                />
              </Grid>
            </Grid>

            <div className="mt-5 flex w-full gap-4">
              <Button
                kind="primary"
                type="submit"
                children={t('general.continue')}
                loading={isSubmitting}
                disabled={
                  Object.keys(errors).length > 1 ||
                  !formikRefInstaller.current?.isValid
                }
              />
            </div>
          </Form>
        )}
      </Formik>
    </Box>
  );
}
