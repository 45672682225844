import {
  formatUnixTime,
  getCountryByValue,
  getCurrencyFormat,
} from '@rabbit/elements/shared-components';
import { add, format, fromUnixTime, getUnixTime } from 'date-fns';
import {
  Address,
  AppInfoShape,
  AppliedWarrantyOption,
  DTFault,
  DTHoldingProxy,
  DTWarranty_Template,
  FBD_Fault,
  FBD_Retailer_Public,
  FBD_Warranty,
  Money,
  Permissions,
  PersonaIdTypeSplitter,
  PersonaLink,
  PersonaTypeSingleLetter,
  RecursiveNumberArray,
  TenantLink,
  Warranty,
} from '@rabbit/data/types';
import { useTranslation } from 'react-i18next';
import {
  calcWarrantyTimeLeft,
  getPartnerTypeOptions,
  VEHICLE_CATEGORY_OPTIONS,
} from '@rabbit/bizproc/react';
import {
  BL_Tech,
  BL_Warranty,
  Permissions2string,
  String2Permissions,
} from '@rabbit/bizproc/core';
import { OurDateTime } from '@rabbit/utils/ts';
import { RegistrationsWarrantyOptionShape } from '@rabbit/elements/shared-types';
import { ExtendedDecodedIdToken } from '@rabbit/bizproc/server-logic';
import BaseConfig from '@rabbit/config/base';
import { UserInterface } from '@rabbit/sage/context/UserContext.tsx';
import React from 'react';

export const useAppInfo = (): AppInfoShape => {
  const { t } = useTranslation();

  const getProperty = (key: string) => {
    return t && t(key) !== key ? t(key) : null;
  };

  const business_name = getProperty('CFG_COBRAND_NAME') ?? '';
  const environment =
    import.meta.env.VITE_FIREBASE_MODE ?? 'ENVIRONMENT NOT SET';
  const logo = getProperty('CFG_COBRAND_LOGO') ?? '';
  const currency =
    getProperty('CFG_DEFAULT_CURRENCY') ?? import.meta.env.VITE_DEFAULT_COUNTRY;
  const country =
    getProperty('CFG_DEFAULT_COUNTRY') ?? import.meta.env.VITE_DEFAULT_COUNTRY;
  const amplitude_key =
    import.meta.env.VITE_AMPLITUDE_API_KEY ?? 'AMPLITUDE KEY NOT SET';
  const stale =
    getProperty('CFG_COBRAND_STALE') ?? import.meta.env.VITE_STALE_HOURS;
  const email_sender =
    getProperty('CFG_COBRAND_EMAIL_SENDER') ?? 'no-reply@global.iwarranty.co';
  const email_main_template =
    getProperty('CFG_COBRAND_EMAIL_MAIN_TEMPLATE') ??
    'main_header_footer_default';
  const templateLanguage =
    getProperty('CFG_COBRAND_EMAIL_TEMPLATE_LANGUAGE') ?? 'en';

  const data = {
    name: business_name ?? '',
    environment: environment,
    logo: logo,
    country: country ?? 'AU',
    currency: currency ?? 'AUD',
    stale: stale ?? 336, //336 hours / 2 weeks
    amplitude_key: amplitude_key,
    address: getBusinessDefaultAddress(),
    email_sender,
    email_main_template,
    templateLanguage,
  };

  return data;
};

function areAllPropertiesEmpty(obj: any) {
  for (const key in obj) {
    if (
      // eslint-disable-next-line no-prototype-builtins
      obj.hasOwnProperty(key) &&
      obj[key] !== null &&
      obj[key] !== undefined &&
      obj[key] !== ''
    ) {
      return false;
    }
  }
  return true;
}

export const printAddress = (address?: Address) => {
  if (!address) return 'No address';
  return `${address?.postcode} ${address?.line1}, ${address?.line2} ${address?.town} ${address?.state} ${address?.country}`;
};

export const formatAddress = (address: any, returnString = false) => {
  if (areAllPropertiesEmpty(address)) return '-';
  return returnString ? (
    `<div>
      ${address.address_line_1 ?? address.line1} <br />
      ${address.address_line_2 ? address.address_line_2 + '<br/>' : ''}
      ${address.line2 ? address.line2 + '<br/>' : ''}
      ${address.town ? address.town + '<br />' : ''}
      ${address.city ? address.city + '<br/>' : ''}
      ${address.county ? address.county + '<br/>' : ''}
      ${address.state ? address.state + '<br/>' : ''}
      ${address.zip_code ?? address.postcode}  <br />
      ${address.country && getCountryByValue(address.country)?.label}
    </div>`
  ) : (
    <div>
      {address.address_line_1 ?? address.line1}
      <br />
      {address.address_line_2 && (
        <>
          {address.address_line_2}
          <br />
        </>
      )}
      {address.line2 && (
        <>
          {address.line2}
          <br />
        </>
      )}
      {address.city ?? address.town},{' '}
      {address.county && <>{address.county}, </>}
      {address.state && <>{address.state}, </>}
      {address.zip_code ?? address.postcode} <br />
      {address.country && getCountryByValue(address.country)?.label}{' '}
    </div>
  );
};

export const getBusinessDefaultAddress = () => {
  const { t } = useTranslation(); // TODO: This is not a react component and therefore it shouldn't use hooks. Pass the value in as a parameter - DC

  const getProperty = (key: string) => {
    return t && t(key) !== key ? t(key) : null;
  };

  const addresses = getProperty('CFG_COBRAND_ADDRESSES') ?? [];
  const parsedAddresses = addresses
    ? typeof addresses === 'string'
      ? JSON.parse(addresses)
      : addresses
    : [];
  if (!parsedAddresses.length) return null;
  const defaultAddress = parsedAddresses.find(
    (address: any) => address.isDefault
  );
  //if doesn't find  default address, return the first one
  if (!defaultAddress) {
    return parsedAddresses[0];
  }

  return defaultAddress;
};

export const getStoneImages = (stoneName: string): string | undefined => {
  const stones: Record<string, string> = {
    Marble:
      'https://firebasestorage.googleapis.com/v0/b/eulaunchpad-firerabbit.appspot.com/o/tenants%2Fnucover%2Fstones%2FMarble.jpg?alt=media&token=97b65825-1c9d-48ee-ad40-d91bd009716d',
    Granite:
      'https://firebasestorage.googleapis.com/v0/b/eulaunchpad-firerabbit.appspot.com/o/tenants%2Fnucover%2Fstones%2FGranite.jpg?alt=media&token=aabc69f1-b163-4e90-a98d-89033356e86a',
    Limestone:
      'https://firebasestorage.googleapis.com/v0/b/eulaunchpad-firerabbit.appspot.com/o/tenants%2Fnucover%2Fstones%2FLimestone.jpg?alt=media&token=671d3d8c-b6f3-4af9-b21e-8f1c2b29c6f9',
    'Engineered Stone':
      'https://firebasestorage.googleapis.com/v0/b/eulaunchpad-firerabbit.appspot.com/o/tenants%2Fnucover%2Fstones%2FEngineered%20Stone.jpg?alt=media&token=fee1d5a1-d2d4-4db7-985d-607365ac84c7',
    Quartzite:
      'https://firebasestorage.googleapis.com/v0/b/eulaunchpad-firerabbit.appspot.com/o/tenants%2Fnucover%2Fstones%2FQuartzite.jpg?alt=media&token=f4fb21ec-4b84-4855-9922-b29db424af51',
    Sandstone:
      'https://firebasestorage.googleapis.com/v0/b/eulaunchpad-firerabbit.appspot.com/o/tenants%2Fnucover%2Fstones%2FSandstone.jpg?alt=media&token=15561661-aca1-489a-b929-3e6a533796fa',
    Slate:
      'https://firebasestorage.googleapis.com/v0/b/eulaunchpad-firerabbit.appspot.com/o/tenants%2Fnucover%2Fstones%2FSlate.jpg?alt=media&token=cae8a023-d7a9-48e1-975c-ac4cf5688b31',
    Travertine:
      'https://firebasestorage.googleapis.com/v0/b/eulaunchpad-firerabbit.appspot.com/o/tenants%2Fnucover%2Fstones%2FTravertine.jpg?alt=media&token=704ec6cb-e2ce-45ab-88bc-00ac59461f39',
    Porcelain:
      'https://firebasestorage.googleapis.com/v0/b/eulaunchpad-firerabbit.appspot.com/o/tenants%2Fnucover%2Fstones%2FPorcelain.png?alt=media&token=6285ccdd-b55d-4f22-b60a-9e181faac537',
  };
  return stones[stoneName];
};

// calculate if the item is within the warranty term or not
export const isWarrantyExpired = (
  purchaseDate: number,
  warranty: { division: string; amount: number }
) => {
  const warrantyExpiryDate = calcWarrantyExpiryDate(purchaseDate, warranty);
  return warrantyExpiryDate
    ? warrantyExpiryDate.getTime() > new Date().getTime()
      ? 'Yes'
      : 'No'
    : '-';
};

export const formatDate = (date: Date) =>
  format(date && isValidDate(date) ? new Date(date) : new Date(), 'dd/MM/yyyy');

export const getWarrantyRemainingByDate = (
  expiryDate: number,
  fromDate: number
) => {
  const monthsLeft = calcWarrantyTimeLeft(
    expiryDate,
    new Date(fromDate) > new Date() ? fromDate : Date.now()
  );

  if (monthsLeft && monthsLeft?.amount && monthsLeft?.amount > 0) {
    return (
      monthsLeft?.amount +
      ' ' +
      (monthsLeft?.amount > 1
        ? monthsLeft?.unit
        : monthsLeft?.unit.slice(0, -1))
    );
  } else {
    return 'Warranty expired';
  }
};

export const multiply = (a: number, b: number) => {
  return a * b;
};

export const isValidDate = (d: Date) => d instanceof Date && !isNaN(d as any);

export const ratePerPerson = (timeSpentWorking: number) => {
  return (15 * timeSpentWorking).toFixed(2);
};

export const titleCase = (str: string) =>
  str.toLowerCase().replace(/\b(\s\w|^\w)/g, function (txt) {
    return txt.toUpperCase();
  });

export const toTitleCase = (str: string) =>
  str[0].toUpperCase() + str.slice(1).toLowerCase();

export const calculateTotalValue = (
  timeString: string,
  ratePerHour: number,
  VAT: number = 0
) => {
  const [hours, minutes] = timeString.split(':');
  const totalMinutes = parseInt(hours, 10) * 60 + parseInt(minutes, 10);
  let totalValue = (totalMinutes / 60) * ratePerHour;
  if (VAT) {
    totalValue += +(totalValue * VAT) / 100;
  }
  return totalValue;
};

export const formatAndGetLastCategory = (categories: any) => {
  return categories[categories.length - 1]
    .replace(/-/g, ' ')
    .replace(/\b\w/g, (char: string) => char.toUpperCase());
};

export const capitalizeFirstLetter = (string: string) => {
  return string[0].toUpperCase() + string.slice(1);
};

export const getFirstName = (fullName: string) => {
  return fullName.split(' ')[0];
};

export const getLastNames = (fullName: string) => {
  const nameParts = fullName.split(' ');
  return String(nameParts.slice(1)).replace(',', ' ');
};

export const getConsumerURL = () => {
  const currentDomain = window.location.origin;

  return BL_Tech.getOliveUrlFromSage(currentDomain);
};

/* -------------------------------------------------------------------------- */
/*                               Chart Formatter                              */
/* -------------------------------------------------------------------------- */
type ChartType = 'options' | 'series';

export type ChartDataShape = {
  data: { x: any; y: any }[];
  name: string;
};

export const formatDataChart = (
  data: any[],
  type: ChartType = 'series',
  name: string,
  limit?: number
): ChartDataShape[] => {
  let formattedData: { x: any; y: any }[] = [];

  if (type === 'series') {
    formattedData = Array.from(data, (item) => ({
      x: item.label,
      y: item.count,
    }));
  }

  return [
    { data: limit ? formattedData.slice(0, limit) : formattedData, name },
  ];
};

// Calculate the stale of a claim
export const isStale = (lastUpdateTimestamp: number, appInfo: AppInfoShape) => {
  const currentTimestamp = Date.now();
  const timeDifference = currentTimestamp - lastUpdateTimestamp;
  const hoursInMillis = Number(appInfo.stale) * 60 * 60 * 1000; // Convert hours to milliseconds

  return timeDifference > hoursInMillis;
};

/* -------------------------------------------------------------------------- */
/*                             Registration views                             */
/* -------------------------------------------------------------------------- */
export const getProductTitleFromHoldingData = (result: any) => {
  const { title, self_registration } = result;
  const { brand, model, version, typeofStone } =
    result.self_registration?.srvInfo?.productInfo ?? {};

  if (title) {
    return title;
  } else if (brand && model && version) {
    return `${brand} ${model} ${version}`;
  } else if (typeofStone) {
    return `${typeofStone}`;
  } else if (self_registration?.title) {
    return self_registration.title;
  } else {
    return '-';
  }
};

export const getProductCategoryFromHoldingData = (result: any) => {
  const { category } = result;
  const { category_title } = result.self_registration ?? {};
  const { type } = result.self_registration?.srvInfo ?? {};
  const { surfaceArea } = result.self_registration?.srvInfo?.productInfo ?? {};

  if (category) {
    return category;
  } else if (surfaceArea) {
    return 'Surface area: ' + (surfaceArea >= 15 ? '≥ 15m²' : '< 15m²');
  } else if (type) {
    return `${type}`;
  } else if (category_title) {
    return category_title;
  } else {
    return '-';
  }
};

export const calculateTotalSpent = (holdingsList: DTHoldingProxy[]) => {
  const currencies: string[] = [];
  holdingsList.forEach((holding: DTHoldingProxy) => {
    const purchase_price = holding?.purchase_price as Money;
    const currency = purchase_price?.currency;
    currencies.push(currency);
  });

  const uniqueCurrencies = [...new Set(currencies)];
  const formattedString = uniqueCurrencies
    .map((currency) => {
      const currencyTotal = holdingsList.reduce((acc, holding) => {
        const purchase_price = holding?.purchase_price as Money;
        if (purchase_price?.currency === currency) {
          const amount =
            purchase_price?.amount?.toString()?.replace(',', '.') ?? '0';
          acc += Number(amount);
        }
        return acc;
      }, 0);
      const currencyFormat = getCurrencyFormat(currencyTotal, currency);
      return currencyTotal ? `<span>${currencyFormat}</span><br>` : null;
    })
    .filter(Boolean)
    .join('');

  return formattedString ? (
    <div dangerouslySetInnerHTML={{ __html: formattedString }} />
  ) : (
    '-'
  );
};

export const calcWarrantyExpiryDate = (
  purchaseDate: number,
  warranty: { division: string; amount: number }
) => {
  if (!purchaseDate || !warranty) return undefined;
  const expiry_date = add(fromUnixTime(purchaseDate), {
    [warranty.division]: warranty.amount,
  });
  return expiry_date;
};

export const getExpiryDate = (
  returnType: 'date' | 'string' | 'number',
  holdingId: string,
  holdingsList: DTHoldingProxy[]
) => {
  const holding = holdingsList.filter(
    (holding: any) => holding.docid === holdingId
  )[0];

  const expiryDate = calcWarrantyExpiryDate(
    holding?.purchase_time,
    BL_Warranty.getLatestWarranty(holding?.warranties)?.duration ?? {
      division: 'years',
      amount: 0,
    }
  );
  if (expiryDate && returnType === 'number') return getUnixTime(expiryDate);
  if (expiryDate && returnType === 'date') return expiryDate;
  if (
    (expiryDate && returnType === 'string' && getUnixTime(expiryDate) <= 0) ||
    !expiryDate
  )
    return '-';
  // this is kind of convoluted but I'm in a hurry, refactor later - dc
  return formatUnixTime(getUnixTime(expiryDate), 'dd/MM/yyyy');
};

// moved to helpers.tsx from shared-components -VP
// export const getWarrantyTerm = (holding_proxy: DTHoldingProxy) => {
//   // Get the latest warranty from the list of warranties
//   const warranty = BL_Warranty.getLatestWarranty(holding_proxy?.warranties);
//   let value = '-';

//   if (warranty) {
//     // Check if the duration is specified as an object with a label
//     if (typeof warranty.decider?.stipulated.duration === 'object' && warranty.decider.stipulated.duration?.label) {
//       // If so, set the value to the label
//       value = warranty.decider.stipulated.duration.label;
//     } else if (warranty.appliedOptions?.find((option: any) => option.key === 'duration')) {
//       // If the duration is not specified as an object, check if it is specified as an applied option
//       const durationOption = warranty.appliedOptions.find((option: any) => option.key === 'duration');
//       // If the duration option has a value, format it using the `formatDuration` function and set the value
//       value = durationOption?.value ? formatDuration(parse(durationOption.value)) : '-';
//     }
//   }
//   return value;
// };

export async function getWarranty(docid: string) {
  const warranty = await FBD_Warranty.get(docid);
  return warranty;
}

export const renderWarrantyRemaining = (
  holding_proxy: DTHoldingProxy,
  t: (key: string) => string,
  remainingLabel: boolean = true
) => {
  const latestWarranty: Warranty | undefined = BL_Warranty.getLatestWarranty(
    holding_proxy?.warranties
  );
  const remaining = latestWarranty?.endDate
    ? getWarrantyRemainingByDate(
        latestWarranty?.endDate,
        latestWarranty?.startDate ?? OurDateTime.nowUTCTimestamp()
      )
    : '-';
  if (
    !remaining.includes('remaining') &&
    !remaining.includes('Warranty expired')
  ) {
    return `${remaining} ${remainingLabel ? t('general.remaining') : ''}`;
  } else if (remaining.includes('Warranty expired')) {
    return t('general.warrantyExpired');
  } else {
    return remaining;
  }
};

// Deprecated, use `getWarrantyTerm` instead -VP
// export const renderWarrantyPlanTerm = (holding_proxy: DTHoldingProxy) => {
//   const warrantyInfo: Warranty | undefined = BL_Warranty.getLatestWarranty(
//     holding_proxy?.warranties
//   );

//   return warrantyInfo?.duration
//     ? `${warrantyInfo.duration.amount} ${warrantyInfo.duration.division}`
//     : '-';
// };

/* -------------------------------------------------------------------------- */
/*                               Label Fetchers                               */
/* -------------------------------------------------------------------------- */

export const getRetailerById = async (locationId: string) => {
  if (!locationId) return undefined;
  const retailer = await FBD_Retailer_Public.get(locationId || '');
  return retailer;
};

export const getFaultLabels = async (faultIds: string[]): Promise<string[]> => {
  const faultLabels = await Promise.all(
    faultIds.map(async (faultId) => {
      const fault = await FBD_Fault.get(faultId);
      return fault?.title;
    })
  );

  return faultLabels.filter(Boolean) as string[];
};

export const getFavouriteStore = async (
  holdingsList: DTHoldingProxy[]
): Promise<string> => {
  const stores: string[] = [];
  holdingsList.forEach((holding: DTHoldingProxy) => {
    const store = holding?.purchase_location;
    if (store) {
      stores.push(store);
    }
  });

  // Get the most frequent store
  const favouriteStore = stores
    .sort(
      (a, b) =>
        stores.filter((v) => v === a).length -
        stores.filter((v) => v === b).length
    )
    .pop();

  if (!favouriteStore) return '-';
  const retailerData = await getRetailerById(favouriteStore);
  if (!retailerData) return '-';
  return `${retailerData?.name} (${retailerData?.address?.town})`;
};

/* -------------------------------------------------------------------------- */
/*                      InputTypeSelect Option Generators                     */
/* -------------------------------------------------------------------------- */

export const generateFaultOptions = (faultListData?: DTFault[]) => {
  if (!faultListData) return [];
  return faultListData.map((fault) => ({
    id: fault.docid,
    value: fault.docid,
    label: fault.title,
    name: fault.title,
  }));
};

export const getDefaultAddress = (addresses: Array<any> | object) => {
  const parsedAddresses = Array.isArray(addresses)
    ? addresses
    : typeof addresses === 'object'
    ? [addresses]
    : [];

  if (!parsedAddresses.length) return null;

  const defaultAddress = parsedAddresses.find(
    (address: any) => address.isDefault
  );

  // if default address is not found, return the first one
  if (!defaultAddress) {
    return parsedAddresses[0];
  }

  return defaultAddress;
};

export const getVehicleCategoryByValue = (value: string) => {
  return VEHICLE_CATEGORY_OPTIONS.find((i) => i.value === value);
};

export const getPartnerOptions = (config: Partial<typeof BaseConfig>) => {
  return getPartnerTypeOptions(config.PARTNERS?.PARTNER_TYPE_OPTIONS);
};

export const getPartnerLabelByValue = (
  value: PersonaTypeSingleLetter,
  config: Partial<typeof BaseConfig>
) => {
  const partnerOptions = getPartnerOptions(config);
  return (
    partnerOptions.find((type) => type.value.includes(value))?.label ?? '-'
  );
};

export const getUserTypeFromPrincipals = (value: any) => {
  return String(value).charAt(0) as PersonaTypeSingleLetter;
};

export const getUserPermissions = (
  tenantLink: string,
  contextValues: any
): Permissions[] => {
  const allowedKeys = ['R', 'S', 'M', 'N'];
  const accessToken = contextValues?.accessToken;

  if (accessToken) {
    if (accessToken.admin) return Object.values(Permissions); // Return all permissions if the user is an admin

    // Get all permissions for the current tenant
    const currentTenantPermissions = Object.keys(accessToken).filter(
      (key) =>
        allowedKeys.includes(key.charAt(0)) && key.split(':')[1] === tenantLink
    );

    // Get all permission values for the current tenant
    const userPermissions = currentTenantPermissions.map(
      (permission) => accessToken[permission]
    );

    // Concatenate all permission values and remove duplicates
    const concatenatedPermissions = [...new Set(userPermissions)].join('');

    return String2Permissions(concatenatedPermissions);
  }

  return [];
};

/* -------------------------------------------------------------------------- */
/*                             Warranty Templates                             */
/* -------------------------------------------------------------------------- */

/* ----- Old functions, to be removed once decider is fully implemented ----- */
/** @deprecated, decider to be used whenever possible instead */
function findPrice(
  pricingArr: RecursiveNumberArray,
  positions: number[]
): RecursiveNumberArray | number | undefined {
  if (positions.length === 0) {
    return pricingArr;
  }

  const [first, ...rest] = positions;
  const nextArr = pricingArr[first];

  if (!nextArr) {
    if (pricingArr[first] !== undefined) return pricingArr[first];
    return undefined;
  }

  return findPrice(nextArr as RecursiveNumberArray, rest);
}

/** @deprecated, decider to be used whenever possible instead */
export function getPricingForSelectedOptions(
  template: DTWarranty_Template,
  selectedOptions: AppliedWarrantyOption[]
) {
  try {
    const pricing = JSON.parse(template.options.pricing);

    if (!pricing) {
      return undefined;
    }

    // Find the indexes for the option item values that we are interested in
    const optionIndexes: number[] = [];

    selectedOptions.forEach((selectedOption) => {
      // find the index of the selected option on the template's optionItem
      const option = template.options.optionList.find(
        (item) => item.optionKey === selectedOption.key
      );
      if (!option) {
        throw new Error(`Option ${selectedOption.key} not found in template`);
      }
      const optionValueIndex = option.optionItems.findIndex(
        (item) => item.itemValue === selectedOption.value
      );

      if (optionValueIndex === -1) {
        throw new Error(
          `Option value ${selectedOption.value} not found in template`
        );
      }

      optionIndexes.push(optionValueIndex);
    });

    // find the item in pricing that matches the indexes of the option

    return findPrice(pricing, optionIndexes) as number | undefined;
  } catch (e) {
    return undefined;
  }
}

export const getChartOptions = (data: Array<object>) => {
  const options = {
    chart: {
      id: 'categories',
    },
    xaxis: {
      categories:
        (data as unknown as { categories: object[] })?.categories ?? [],
      labels: {
        rotate: 0,
        trim: true,
        hideOverlappingLabels: false,
        style: {
          fontFamily: 'nunito',
        },
      },
    },
    yaxis: {
      labels: {
        formatter: function (val: number) {
          return val.toFixed(0);
        },
      },
    },
    colors: ['#579085', '#905762'],
    dataLabels: {
      enabled: false,
    },
  };
  return options;
};

export const stripTags = (str: string) => {
  const dom = document.createElement('div');
  dom.innerHTML = str;
  return dom.textContent;
};

export const optionDescription = (offer: RegistrationsWarrantyOptionShape) => {
  const durations = offer.approvedOptions.find((i) => i.label === 'Duration');
  if (durations) {
    return durations.optionInfo
      .map((option, index) => {
        return index === durations.optionInfo.length - 1
          ? durations.optionInfo.length > 1
            ? `or ${option.option.label}`
            : `${option.option.label}`
          : index === durations.optionInfo.length - 2
          ? `${option.option.label?.replace(/\syear(s)?/, '')} `
          : `${option.option.label?.replace(/\syear(s)?/, '')}, `;
      })
      .join('');
  } else return '';
};

export const fixCategoryAndSortHoldingsByTCreate = (data: DTHoldingProxy[]) => {
  return data
    .sort((a: DTHoldingProxy, b: DTHoldingProxy) => {
      const aCreate = new Date(a.tcreate);
      const bCreate = new Date(b.tcreate);
      return bCreate.getTime() - aCreate.getTime();
    })
    .map((holding) => {
      return {
        ...holding,
        category: getHoldingCategory(holding),
      };
    });
};

export const getHoldingCategory = (holding: DTHoldingProxy) => {
  if (
    holding.self_registration &&
    typeof holding.self_registration !== 'boolean'
  ) {
    return holding?.self_registration?.category_title?.replace(/-/g, ' ');
  } else {
    if (holding.category && Array.isArray(holding.category)) {
      return holding.category[0].replace(/-/g, ' ');
    } else {
      return Array.isArray(holding.category)
        ? (holding.category as string[])[0].replace(/-/g, ' ')
        : (holding.category as any)?.replace(/-/g, ' ');
    }
  }
};

export const getOperatingPersonaId = (
  operatingPersonas: PersonaTypeSingleLetter[],
  tenantLink: TenantLink,
  accessToken: ExtendedDecodedIdToken,
  personaIds: {
    repairer?: PersonaLink;
    warrantor?: PersonaLink;
    installer?: PersonaLink;
  }
) => {
  const isAdmin = accessToken?.admin;

  if (
    operatingPersonas?.includes(PersonaTypeSingleLetter.Warrantor) &&
    personaIds.warrantor
  ) {
    if (isAdmin)
      return `${PersonaTypeSingleLetter.Warrantor}${PersonaIdTypeSplitter}${tenantLink}`;

    return personaIds.warrantor;
  }

  if (
    operatingPersonas?.includes(PersonaTypeSingleLetter.Repairer) &&
    personaIds.repairer
  ) {
    if (isAdmin)
      return `${PersonaTypeSingleLetter.Repairer}${PersonaIdTypeSplitter}${tenantLink}`;

    return personaIds.repairer;
  }
  if (
    operatingPersonas?.includes(PersonaTypeSingleLetter.Installer) &&
    personaIds.installer
  ) {
    if (isAdmin)
      return `${PersonaTypeSingleLetter.Installer}${PersonaIdTypeSplitter}${tenantLink}`;

    return personaIds.installer;
  }
};

export function isAuthPartner(
  contextValues: UserInterface | null,
  t: any
): boolean {
  if (contextValues?.accessToken) {
    const permissionsFromRootRepairer =
      contextValues.accessToken[
        PersonaTypeSingleLetter.Repairer +
          PersonaIdTypeSplitter +
          t('tenantLink')
      ];
    return (
      permissionsFromRootRepairer &&
      !permissionsFromRootRepairer.includes(
        Permissions2string([Permissions.Owner])
      ) &&
      !contextValues.accessToken.admin
    );
  }
  return false;
}

interface PopoverController<T> {
  anchorRef: React.MutableRefObject<T | null>;
  handleOpen: () => void;
  handleClose: () => void;
  handleToggle: () => void;
  open: boolean;
}

export function usePopover<T = HTMLElement>(): PopoverController<T> {
  const anchorRef = React.useRef<T>(null);
  const [open, setOpen] = React.useState<boolean>(false);

  const handleOpen = React.useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = React.useCallback(() => {
    setOpen(false);
  }, []);

  const handleToggle = React.useCallback(() => {
    setOpen((prevState) => !prevState);
  }, []);

  return { anchorRef, handleClose, handleOpen, handleToggle, open };
}

export const timeFormat = (time: string, hStr: string, mStr: string) => {
  const [hourStr, minuteStr] = time.split(':');
  let hour = `${Number(hourStr)} ${hStr}`;
  const minutes = `${minuteStr} ${mStr}`;
  return `${hour} ${minutes}`;
};
