import {
  IAfterGuiAttachedParams,
  IDoesFilterPassParams,
} from 'ag-grid-community';
import { CustomFilterProps, useGridFilter } from 'ag-grid-react';
import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';

const AgGridImageFilter = ({
  model,
  onModelChange,
  getValue,
}: CustomFilterProps) => {
  const refInput = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();

  // Check if row passes filter
  const doesFilterPass = useCallback(
    (params: IDoesFilterPassParams) => {
      const filterText: string = model || '';
      const { isImageUploaded } = params.node.data;

      // Return based on filter text
      if (!filterText) return true; // Show all rows if no filter is applied

      if (filterText === 'valid') {
        return isImageUploaded; // Show rows with valid images
      }

      if (filterText === 'default') {
        return !isImageUploaded; // Show rows with placeholder images
      }

      return true; // Show all rows by default
    },
    [model, getValue]
  );

  // Focus the input when the filter is activated
  const afterGuiAttached = useCallback((params?: IAfterGuiAttachedParams) => {
    if (!params || !params.suppressFocus) {
      refInput.current?.focus();
    }
  }, []);

  // Register filter methods with the grid
  useGridFilter({
    doesFilterPass,
    afterGuiAttached,
  });

  return (
    <div className="grid h-auto w-full grid-cols-1 gap-1 p-3">
      <div className="grid h-auto w-full grid-cols-1 gap-2">
        <label className="flex h-auto w-full items-center justify-start gap-2">
          <input
            type="radio"
            name="imageFilter"
            value=""
            checked={model === null}
            onChange={() => onModelChange(null)}
          />
          {t('general.all')}
        </label>
        <label className="flex h-auto w-full items-center justify-start gap-2">
          <input
            type="radio"
            name="imageFilter"
            value="valid"
            checked={model === 'valid'}
            onChange={() => onModelChange('valid')}
          />
          {t('general.valid')}
        </label>
        <label className="flex h-auto w-full items-center justify-start gap-2">
          <input
            type="radio"
            name="imageFilter"
            value="default"
            checked={model === 'default'}
            onChange={() => onModelChange('default')}
          />
          {t('general.default')}
        </label>
      </div>
    </div>
  );
};

export default AgGridImageFilter;
