import { CaseFlowConfiguration } from '@rabbit/data/types';
import { CaseFlowConfigurationRegistry } from '../configuration';
import {
  CaseFlowConfig_Facts_DocLinks,
  CaseFlowConfig_Facts_Comments,
  CaseFlowConfig_Facts_IssueDetails,
  CaseFlowConfig_Facts_Delegates,
  CaseFlowConfig_Facts_Holding,
  CaseFlowConfig_Facts_Consumer,
  CaseFlowConfig_Facts_CaseStatus,
  CaseFlowConfig_Facts_UploadedFiles,
  CaseFlowConfig_Facts_PartnerData,
  CaseFlowConfig_Facts_CostLogging,
} from '../_common/facts';
import { CaseflowCaseTypes } from '../_common/types';

/** This is the configuration for the simplest form of Caseflow, available to new premium tenants by default
 *  Here actions are all global, and stations are used only to define the case's status (with the possible
 * exception of actions available to the Olive user if those are required here)
 */

/* -------------------------------------------------------------------------- */
/*                                   Actors                                   */
/* -------------------------------------------------------------------------- */

const actors: CaseFlowConfiguration['actors'] = {
  consumer: {
    tech_description: 'holder of broken item',
  },
  warrantor: {
    tech_description: 'entity responsible for the warranty',
    proxy: {
      facts: [
        'administrative_cost_data',
        'parts_used_cost_data',
        'repair_time_data',
        'shipping_cost_data',
        'travel_cost_data',
        'other_cost_data',
        'consumer_holding',
        'consumer_persona_id',
        'holding_vendable_id',
        'consumer_issue_type',
        'consumer_issue_type_ref',
        'consumer_issue_description',
        'consumer_claim_evidence',
        'consumer_proof_of_purchase',
        'warranty_link',
        'vendable_version_number',
        'preliminary_assessment',
        'final_assessment',
        'goodwill_warranty_initial',
        'goodwill_warranty_final',
        'delegate_repairer_id',
        'delegate_repairer_name',
        'delegate_installer_id',
        'delegate_installer_name',
        'consumer_name',
        'consumer_holding_name',
        'holding_warranty_term',
        'purchase_date',
        'consumer_email',
        'srvProductInfo',
        'claim_outcome',
        'warranty_status',
      ],
    },
  },
  // right now a copy of the warrantor, but this might change?
  repairer: {
    tech_description: 'entity responsible for the repair of the item',
    proxy: {
      facts: [
        'administrative_cost_data',
        'parts_used_cost_data',
        'repair_time_data',
        'shipping_cost_data',
        'travel_cost_data',
        'other_cost_data',
        'consumer_holding',
        'consumer_persona_id',
        'holding_vendable_id',
        'consumer_issue_type',
        'consumer_issue_type_ref',
        'consumer_issue_description',
        'consumer_claim_evidence',
        'consumer_proof_of_purchase',
        'warranty_link',
        'vendable_version_number',
        'preliminary_assessment',
        'final_assessment',
        'goodwill_warranty_initial',
        'goodwill_warranty_final',
        'delegate_repairer_id',
        'delegate_repairer_name',
        'delegate_installer_id',
        'delegate_installer_name',
        'consumer_name',
        'consumer_holding_name',
        'holding_warranty_term',
        'purchase_date',
        'consumer_email',
        'srvProductInfo',
        'warranty_status',
      ],
    },
  },
};

/* -------------------------------------------------------------------------- */
/*                                    Facts                                   */
/* -------------------------------------------------------------------------- */

// Facts specific to tenants with flows like NUCOVER, WARRANTYIRELAND, PINNACLEWARRANTIES
const srvTenantFacts: CaseFlowConfiguration['facts'] = {
  srvProductInfo: {
    type: 'SelfRegistration', // from holding-proxy.ts
    label: 'Product information',
  },
};

const facts: CaseFlowConfiguration['facts'] = {
  /* --------------------------- Lite specific facts -------------------------- */
  ...srvTenantFacts,
  /* ------------------------------ Common facts ------------------------------ */
  ...CaseFlowConfig_Facts_CostLogging,
  ...CaseFlowConfig_Facts_DocLinks,
  ...CaseFlowConfig_Facts_Comments,
  ...CaseFlowConfig_Facts_IssueDetails,
  ...CaseFlowConfig_Facts_Delegates,
  ...CaseFlowConfig_Facts_Holding,
  ...CaseFlowConfig_Facts_Consumer,
  ...CaseFlowConfig_Facts_CaseStatus,
  ...CaseFlowConfig_Facts_UploadedFiles,
  ...CaseFlowConfig_Facts_PartnerData,
};

/* -------------------------------------------------------------------------- */
/*                                  Stations                                  */
/* -------------------------------------------------------------------------- */

const stations: CaseFlowConfiguration['stations'] = {
  _birth: {
    actions: {
      _onEnter: {
        available_to: ['consumer', 'repairer', 'warrantor'],
        steps: [
          {
            type: 'go_station',
            station: 'preliminary_assessment',
          },
        ],
      },
    },
  },
  preliminary_assessment: {
    actions: {},
  },
  initially_assessed: {
    actions: {},
  },
  in_repair: {
    actions: {},
  },
  repaired: {
    actions: {},
  },
  closed: {
    // No steps or actions are required. This station marks the end of Journey for a case
    actions: {},
  },
};

/* -------------------------------------------------------------------------- */
/*                               Global Actions                               */
/* -------------------------------------------------------------------------- */

/* -------- Generic actions ported over from the Shelta configuration ------- */
// TODO: Might be moved to a shared location
const genericActions: CaseFlowConfiguration['global_actions'] = {
  contact_customer_generic: {
    label: 'Contact customer for more info',
    available_to: ['repairer', 'warrantor'],
    steps: [
      {
        type: 'req_facts',
        facts: ['comment_to_customer'],
      },
    ],
  },
  internal_comment_generic: {
    label: 'Leave internal comment',
    available_to: ['repairer', 'warrantor'],
    steps: [
      {
        type: 'req_facts',
        facts: ['internal_comment'],
      },
    ],
  },
  // todo: confirm if will be used
  assign_case: {
    label: 'Assign case',
    available_to: ['repairer', 'warrantor'],
    params: {
      delegate_repairer_id: {
        type: 'String',
        label: 'Delegate repairer persona',
        required: true,
      },
      delegate_repairer_name: {
        type: 'String',
        label: 'Delegate repairer name',
        required: true,
      },
    },
    steps: [
      {
        type: 'set_fact',
        fact: 'delegate_repairer_id',
        value: '[[delegate_repairer_id]]',
      },
      {
        type: 'set_fact',
        fact: 'delegate_repairer_name',
        value: '[[delegate_repairer_name]]',
      },
      {
        type: 'alter_spotlight',
        clear: true,
        add: '[[delegate_repairer_id]]',
      },
    ],
  },
  //Set the spotlight to point at the consumer
  spotlight_on_consumer: {
    label: 'Spotlight on consumer',
    available_to: ['warrantor', 'repairer', 'consumer', 'installer'],
    params: {
      consumer: {
        type: 'String',
        label: 'Consumer persona',
        required: true,
        get_from: ['fact:consumer_persona_id', 'actor:consumer'],
      },
    },
    steps: [
      {
        type: 'alter_spotlight',
        clear: true,
        add: '[[consumer]]',
      },
    ],
  },
  // Set the spotlight to point at the repairer, which may be the delegate repairer if it has been set as a fact
  spotlight_on_repairer: {
    label: 'Spotlight on repairer',
    available_to: ['warrantor', 'repairer', 'consumer', 'installer'],
    params: {
      repairer: {
        type: 'String',
        label: 'Repairer persona',
        required: true,
        get_from: ['fact:delegate_repairer_id', 'actor:repairer'],
      },
    },
    steps: [
      {
        type: 'alter_spotlight',
        clear: true,
        add: '[[repairer]]',
      },
    ],
  },
  spotlight_on_warrantor: {
    label: 'Spotlight on warrantor',
    available_to: ['warrantor', 'repairer', 'consumer', 'installer'],
    params: {
      warrantor: {
        type: 'String',
        label: 'Warrantor persona',
        required: true,
        get_from: ['actor:warrantor'],
      },
    },
    steps: [
      {
        type: 'alter_spotlight',
        clear: true,
        add: '[[warrantor]]',
      },
    ],
  },
};

/* -------------- Actions for moving the case between stations -------------- */
const stationMovementActions: CaseFlowConfiguration['global_actions'] = {
  move_to_preliminary_assessment: {
    label: 'Move to preliminary assessment',
    available_to: ['repairer', 'warrantor'],
    steps: [
      {
        type: 'go_station',
        station: 'preliminary_assessment',
      },
    ],
  },
  move_to_initially_assessed: {
    label: 'Move to initially assessed',
    available_to: ['repairer', 'warrantor'],
    steps: [
      {
        type: 'go_station',
        station: 'initially_assessed',
      },
    ],
  },
  move_to_in_repair: {
    label: 'Move to in repair',
    available_to: ['repairer', 'warrantor'],
    steps: [
      {
        type: 'go_station',
        station: 'in_repair',
      },
    ],
  },
  move_to_repaired: {
    label: 'Move to repaired',
    available_to: ['repairer', 'warrantor'],
    steps: [
      {
        type: 'go_station',
        station: 'repaired',
      },
    ],
  },
  move_to_closed: {
    label: 'Move to closed',
    available_to: ['repairer', 'warrantor'],
    steps: [
      {
        type: 'go_station',
        station: 'closed',
      },
    ],
  },
};

/* --------------------- Actions for logging claim costs -------------------- */
const costLoggingActions: CaseFlowConfiguration['global_actions'] = {
  /* --------------------------- Administrative time -------------------------- */
  log_costs_administrative_time: {
    available_to: ['warrantor', 'repairer'],
    label: 'Log administrative time costs',
    params: {
      administrative_cost_data: {
        type: 'CFCF_AdministrativeCostLog',
        label: 'Administrative time cost',
        required: true,
      },
      internal_comment: {
        type: 'String',
        label: 'Internal comment',
      },
    },
    steps: [
      {
        type: 'set_fact',
        fact: 'administrative_cost_data',
        value: '[[administrative_cost_data]]',
      },
      {
        type: 'set_fact',
        fact: ' internal_comment',
        value: '[[internal_comment]]',
      },
    ],
  },
  /* ------------------------------- Parts used ------------------------------- */
  log_costs_parts_used: {
    available_to: ['warrantor', 'repairer'],
    label: 'Log parts used costs',
    params: {
      parts_used_cost_data: {
        type: 'CFCF_PartsUsed',
        label: 'Parts used cost',
        required: true,
      },
      internal_comment: {
        type: 'String',
        label: 'Internal comment',
      },
    },
    steps: [
      {
        type: 'set_fact',
        fact: 'parts_used_cost_data',
        value: '[[parts_used_cost_data]]',
      },
      {
        type: 'set_fact',
        fact: 'internal_comment',
        value: '[[internal_comment]]',
      },
    ],
  },
  /* ------------------------------- Repair time ------------------------------ */
  log_costs_repair_time: {
    available_to: ['warrantor', 'repairer'],
    label: 'Log repair time costs',
    params: {
      repair_time_data: {
        type: 'CFCF_RepairTimeLog',
        label: 'Repair time cost',
        required: true,
      },
      internal_comment: {
        type: 'String',
        label: 'Internal comment',
      },
    },
    steps: [
      {
        type: 'set_fact',
        fact: 'repair_time_data',
        value: '[[repair_time_data]]',
      },
      {
        type: 'set_fact',
        fact: 'internal_comment',
        value: '[[internal_comment]]',
      },
    ],
  },
  /* -------------------------------- Shipping -------------------------------- */
  log_costs_shipping: {
    available_to: ['warrantor', 'repairer'],
    label: 'Log shipping costs',
    params: {
      shipping_cost_data: {
        type: 'CFCF_ShippingCostLog',
        label: 'Shipping cost',
        required: true,
      },
      internal_comment: {
        type: 'String',
        label: 'Internal comment',
      },
    },
    steps: [
      {
        type: 'set_fact',
        fact: 'shipping_cost_data',
        value: '[[shipping_cost_data]]',
      },
      {
        type: 'set_fact',
        fact: 'internal_comment',
        value: '[[internal_comment]]',
      },
    ],
  },
  /* --------------------------------- Travel -------------------------------- */
  log_costs_travel: {
    available_to: ['warrantor', 'repairer'],
    label: 'Log travel costs',
    params: {
      travel_cost_data: {
        type: 'CFCF_TravelCostLog',
        label: 'Travel cost',
        required: true,
      },
      internal_comment: {
        type: 'String',
        label: 'Internal comment',
      },
    },
    steps: [
      {
        type: 'set_fact',
        fact: 'travel_cost_data',
        value: '[[travel_cost_data]]',
      },
      {
        type: 'set_fact',
        fact: 'internal_comment',
        value: '[[internal_comment]]',
      },
    ],
  },
  /* ------------------------------- Other costs ------------------------------ */
  log_costs_other: {
    available_to: ['warrantor', 'repairer'],
    label: 'Log other costs',
    params: {
      other_cost_data: {
        type: 'CFCF_OtherCostLog',
        label: 'Other cost',
        required: true,
      },
      internal_comment: {
        type: 'String',
        label: 'Internal comment',
      },
    },
    steps: [
      {
        type: 'set_fact',
        fact: 'other_cost_data',
        value: '[[other_cost_data]]',
      },
      {
        type: 'set_fact',
        fact: 'internal_comment',
        value: '[[internal_comment]]',
      },
    ],
  },
};
/* ------------------- The complete global actions object ------------------- */
const global_actions: CaseFlowConfiguration['global_actions'] = {
  ...genericActions,
  ...stationMovementActions,
  ...costLoggingActions,
};

export const LiteConfig: CaseFlowConfiguration = {
  actors,
  facts,
  stations,
  global_actions,
};

CaseFlowConfigurationRegistry.Register(LiteConfig, CaseflowCaseTypes.LITE);
