import { Form, Formik, FormikProps } from 'formik';
import {
  Button,
  Input,
  LoadingSpinner,
  Modal,
} from '@rabbit/elements/shared-components';
import { ReactElement, useContext, useRef, useState } from 'react';
import {
  CLAIM_COST_OPTIONS,
  LIST_CURRENCIES,
  OptionShape,
  useGetRepairerPeers,
} from '@rabbit/bizproc/react';
import * as Yup from 'yup';
import { SelectOptionShape } from '@rabbit/elements/shared-types';
import { useAppInfo } from '@rabbit/sage/utils/helpers';
import {
  CaseflowContext,
  CaseflowInterface,
} from '@rabbit/sage/context/CaseflowContext';
import PartUsedForm from './forms/PartUsedForm';
import AdministrativeTimeForm from './forms/AdministrativeTimeForm';
import RepairTimeForm from './forms/RepairTimeForm';
import ShippingForm from './forms/ShippingForm';
import TravelForm from './forms/TravelForm';
import OtherForm from './forms/OtherForm';
import { useGetMySagePersonas } from '@rabbit/data/portal';
import { t } from 'i18next';
import { ConfigContext } from '@rabbit/config/context';

export const inputTypeCurrencyOverrideOptions: Array<SelectOptionShape> =
  LIST_CURRENCIES.map((currency, index) => ({
    id: `${index}`,
    label: currency.code + '/h',
    value: currency.code,
  }));

export interface LogForms<T> {
  schema: Yup.AnySchema;
  initialValues: Record<string, any>;
  onDelete: (index: number, onComplete: () => void) => void;
  onSubmit: (
    values: T & {
      internal_comment: string;
    },
    onComplete: () => void
  ) => void;
  Form: (args: FormikProps<Record<string, any>>) => ReactElement<any, any>;
  name:
    | 'administrative-time'
    | 'parts-used'
    | 'repair-time'
    | 'shipping'
    | 'travel'
    | 'other';
}

export type AlterCaseFacts = (facts: { [key: string]: any }) => Promise<void>;

export default function ClaimCostModal({
  handleClose,
}: {
  handleClose: () => void;
}) {
  const appInfo = useAppInfo();
  const { config } = useContext(ConfigContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedForm, setSelectedForm] = useState<LogForms<any>>();
  const [showInternalComment, setShowInternalComment] = useState(false);
  const caseFlow = useContext(CaseflowContext) || ({} as CaseflowInterface);
  const { repairerPeer, repairerPeerLoading } = useGetRepairerPeers();
  const { warrantorPersona, repairerPersona, installerPersona } =
    useGetMySagePersonas();
  const activePersonaId =
    warrantorPersona?.personaId ??
    repairerPersona?.personaId ??
    installerPersona?.personaId ??
    '';
  const formikRef = useRef<FormikProps<any>>(null);
  const costSelectRef = useRef<any>(null);

  if (!activePersonaId) return <LoadingSpinner size="md" />;

  let teamMembers: OptionShape[] = repairerPeerLoading
    ? [
        {
          id: '-1',
          label: t('Loading...'),
          value: 'Loading...',
        },
      ]
    : repairerPeer.map((repairer, index) => ({
        id: `${index}`,
        label: repairer?.name || '',
        value: repairer?.docid || '',
      }));

  if (!config.CLAIMS.CLAIM_DETAIL_VIEW.CLAIM_COSTS.LIST_NON_CASE_ACTORS) {
    const caseActors = caseFlow.caseActors;

    // Compare ids without PersonaTypeSingleLetter and the id separator prefix, to cover
    // assigned persona differences (e.g. W:FATBIKES is the warrantor case actor but we want
    // to use the R:FATBIKES repairer for cost assignment)
    // TODO: Should be handled in the hook, which should be persona independent (not use repairers exclusively)
    const caseActorIds = Object.values(caseActors) as string[];
    const slicedActorIds = caseActorIds.map((id) => id.slice(2));

    teamMembers = teamMembers.filter((member) =>
      slicedActorIds.includes(member.value.slice(2))
    );
  }

  const handleFormSelected = (selected: SelectOptionShape) => {
    const { caseFacts, alterCaseFacts } = caseFlow;
    if (!caseFacts || !alterCaseFacts) return;
    if (!activePersonaId) return;
    const valueMap: Record<string, LogForms<any>> = {
      'administrative-time': AdministrativeTimeForm(
        appInfo,
        config,
        caseFlow,
        teamMembers,
        activePersonaId
      ),
      'parts-used': PartUsedForm(appInfo, config, caseFlow, activePersonaId),
      'repair-time': RepairTimeForm(
        appInfo,
        caseFlow,
        teamMembers,
        activePersonaId
      ),
      shipping: ShippingForm(appInfo, config, caseFlow, activePersonaId),
      travel: TravelForm(appInfo, config, caseFlow, activePersonaId),
      other: OtherForm(appInfo, caseFlow, activePersonaId),
    };
    setSelectedForm(valueMap[selected.value as string]);
  };

  const onSubmit = (values: any) => {
    if (!selectedForm) return;
    setIsSubmitting(true);
    selectedForm.onSubmit(values, () => {
      setIsSubmitting(false);
      if (values.another) {
        setSelectedForm(undefined);
        costSelectRef.current?.setValue(undefined);
      } else handleClose();
    });
  };

  return (
    <Modal
      settings={{
        title: t('general.logClaimCost'),
        handleClose,
      }}
      className="w-full max-w-[1024px]"
      kind="generic"
      isLoading={isSubmitting}
    >
      <Formik
        initialValues={selectedForm?.initialValues || {}}
        onSubmit={onSubmit}
        validationSchema={selectedForm?.schema}
        enableReinitialize
        innerRef={formikRef}
      >
        {(props) => {
          const { errors, values } = props;
          return (
            <Form className="mt-5 flex flex-col gap-3 px-4">
              <Input
                type="select"
                name="type_of_cost"
                label={t('general.typeOfCost')}
                settings={{
                  options: CLAIM_COST_OPTIONS,
                  id: 'type_of_cost',
                  onChange: (value: any) => value && handleFormSelected(value),
                  placeholder: t('message.pleaseSelectAnOption'),
                  ref: costSelectRef,
                }}
              />
              {selectedForm && (
                <div className="flex flex-col gap-4 rounded-lg border border-gray-300 p-4">
                  {
                    // Selected form is displayed here
                    // Depending on the state change
                  }
                  {<selectedForm.Form {...props} />}

                  {!showInternalComment && (
                    <div className="mt-4">
                      <Button
                        kind="outline"
                        type="button"
                        className="w-full"
                        onClick={() => setShowInternalComment(true)}
                      >
                        {t('message.addInternalComment')}
                      </Button>
                    </div>
                  )}
                  {showInternalComment && (
                    <div className="mt-3">
                      <Input
                        type="rich-text"
                        label={t('general.internalComment')}
                        name="internal_comment"
                        settings={{
                          id: 'internal_comment',
                          allowSpecialCharacter: true,
                        }}
                      />
                    </div>
                  )}
                </div>
              )}
              <div className="mt-4 grid grid-cols-3 gap-3">
                <div className="flex items-center">
                  <Input
                    type="checkbox"
                    name="another"
                    settings={{ checkboxLabel: t('general.logAnother') }}
                  />
                </div>
                <Button kind="primary" type="submit">
                  {t('general.logCost')}
                </Button>
                <Button
                  kind="outline_red"
                  type="submit"
                  onClick={handleClose}
                  disabled={isSubmitting}
                >
                  {t('general.cancel')}
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
}
