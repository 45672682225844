import { Modal, TabsGeneric } from '@rabbit/elements/shared-components';
import { useContext, useState } from 'react';
import ModalAddEditItem, {
  ModalAddEditItemKind,
} from '../components/organisms/ModalNewEditProduct/ModalAddEditItem';
//import FaultsCategories from '../components/templates/ProductsTabElements/FaultsCategoriesTabs';
import FaultsTab from '../components/templates/ProductsTabElements/FaultsTab';
import CustomersTab from '../components/templates/ProductsTabElements/CustomersTab';
import PartnersTab from '../components/templates/ProductsTabElements/PartnersTab';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../context/UserContext';
import { getUserPermissions } from '../utils/helpers';
import { useNavigate } from 'react-router-dom';
import { Permissions, PersonaTypeSingleLetter } from '@rabbit/data/types';
import ProductsTab from '../components/templates/ProductsTabElements/ProductsTab';
import { VendableEditorProviderWrapper } from '@rabbit/bizproc/react';
import IssueTypesTab from '../components/templates/ProductsTabElements/IssueTypesTab';
import { ConfigContext } from '@rabbit/config/context';
import { useGetMySagePersonas } from '@rabbit/data/portal';
import CauseTab from '../components/templates/ProductsTabElements/CauseTab';
import {
  UserGroupIcon,
  BuildingOfficeIcon,
  ExclamationTriangleIcon,
  QuestionMarkCircleIcon,
  QueueListIcon,
  ShoppingBagIcon,
} from '@heroicons/react/24/solid';

export function ManageView() {
  const { t } = useTranslation();
  const contextValues = useContext(UserContext);
  const { config } = useContext(ConfigContext);
  const navigate = useNavigate();
  const tenantLink = t('tenantLink');
  const userPermissions = getUserPermissions(tenantLink, contextValues);
  const { warrantorPersona } = useGetMySagePersonas();
  const [isNewEditModalOpen, setIsNewEditModalOpen] = useState(false);
  const [itemDetails, setItemDetails] = useState<any>();
  const [modalTitle, setModalTitle] = useState<string>();
  const [modalKind, setModalKind] = useState<ModalAddEditItemKind>();
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const handleEditModal = (
    kind: ModalAddEditItemKind,
    title: string,
    itemDetails?: any
  ) => {
    setModalKind(kind);
    setModalTitle(title);
    setIsNewEditModalOpen(true);
    setItemDetails(itemDetails);
  };

  const modalSettings = {
    kind: 'pop-up' as const,
    settings: {
      text: t(
        'You are about to delete this product. Are you sure you want to continue?'
      ),
      primaryButtonText: t('general.yes'),
      outlineButtonText: t('general.cancel'),
      handleClose: () => setIsConfirmationModalOpen(false),
      handlePrimaryClick: async () => console.log('delete --> ToDo'),
      handleOutlineClick: () => setIsConfirmationModalOpen(false),
    },
  };

  const tabs = [
    {
      label: t('general.customers'),
      icon: UserGroupIcon,
      children: <CustomersTab />,
    },
    ...(config.VENDABLES.EDITOR.ENABLED.includes(
      (warrantorPersona?.personaId?.[0] as PersonaTypeSingleLetter) ?? ''
    ) || contextValues?.accessToken?.admin
      ? [
          {
            label: t('general.products'),
            icon: ShoppingBagIcon,
            children: <ProductsTab handleEditModal={handleEditModal} />,
          },
        ]
      : []),
    ...(t('CFG_MANAGE_PARTNERS') === 'true'
      ? [
          {
            label: t('general.partners'),
            icon: BuildingOfficeIcon,
            children: <PartnersTab />,
          },
        ]
      : []),
    // {
    //   label: t('Retailers'),
    //   children: <CustomersTab />
    // },
    {
      label: t('general.issues'),
      icon: QueueListIcon,
      children: <IssueTypesTab />,
    },
    {
      label: t('general.faults'),
      icon: ExclamationTriangleIcon,
      children: <FaultsTab />,
    },
    {
      label: t('general.causes'),
      icon: QuestionMarkCircleIcon,
      children: <CauseTab />,
    },
    // {
    //   label: t('Fault categories'),
    //   children: <FaultsCategories handleEditModal={handleEditModal} />,
    // },
  ];

  //Check if user has permissions to view this page
  if (!userPermissions?.includes(Permissions.Owner)) navigate('/');

  return (
    <VendableEditorProviderWrapper>
      <div className="flex justify-center pt-5 pb-4">
        <TabsGeneric items={tabs} customTabs={true} autoSelect={true} />
      </div>
      {isNewEditModalOpen && (
        <div>
          <ModalAddEditItem
            handleClose={() => setIsNewEditModalOpen(false)}
            handleDelete={() => setIsConfirmationModalOpen(true)}
            handleSubmit={() => void 0}
            itemDetails={itemDetails}
            kind={modalKind}
            title={modalTitle}
          />
        </div>
      )}
      {isConfirmationModalOpen && (
        <Modal
          className="h-fit w-[350px] rounded-md bg-white"
          kind={modalSettings.kind}
          settings={modalSettings.settings}
        />
      )}
    </VendableEditorProviderWrapper>
  );
}

export default ManageView;
