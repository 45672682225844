import { Field, useFormikContext } from 'formik';
import { InputSettingsShape } from '@rabbit/elements/shared-types';
import { useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

interface InputRichTextProps {
  name: string;
  onChange?: (...args: any) => any;
  settings: InputSettingsShape;
}

export const quillModules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6] }],
    ['bold', 'underline'],
    [{ size: ['small', false, 'large', 'huge'] }],
    [{ font: [] }],
    [{ align: [] }],
    // ['image'], // not used for now
    [{ color: [] }, { background: [] }, { height: ['208px'] }],
  ],
};

// export const quillAllModules = {
//   toolbar: [
//     ['bold', 'italic', 'underline', 'strike'],
//     ['blockquote', 'code-block'],
//     [{ header: 1 }, { header: 2 }],
//     [{ list: 'ordered' }, { list: 'bullet' }],
//     [{ script: 'sub' }, { script: 'super' }],
//     // [{ indent: '-1' }, { indent: '+1' }],
//     // [{ direction: 'rtl' }],
//     [{ size: ['small', false, 'large', 'huge'] }],
//     [{ header: [1, 2, 3, 4, 5, 6, false] }],
//     [{ color: [] }, { background: ['bg-white'] }, { height: ['208px'] }],
//     [{ font: ['font-nunito'] }],
//     [{ align: [] }],
//     ['image', 'video'],
//   ],
// };

export const quillFormats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'link',
  'color',
  'image',
  'background',
  'align',
  'size',
  'font',
];

const customQuillStyles = {
  borderRadius: '0.5rem',
  borderWidth: '1px',
  borderColor: '#e1e1e1',
  minHeight: '150px',
  backgroundColor: 'bg-white',
  width: 'w-full',
  display: 'flex',
  flexDirection: 'column' as const,
  '> div': {
    flexGrow: 1,
  },
};

export function InputRichText({ name, settings }: InputRichTextProps) {
  const { placeholder, disabled, id, shouldValidate = true, ...rest } = settings;
  const { setTouched, setFieldValue } = useFormikContext();
  const [focused, setFocused] = useState(false);
  const ref = useRef<ReactQuill>(null);

  const prepare = () => {
    setFocused(true);
    queueMicrotask(() => {
      ref.current?.focus();
    });
  };

  return (
    <Field name={name} settings={settings}>
      {({ field, form: { errors } }: any) => {
        return (
          <>
            {!field?.value && !focused && (
              <div
                className="font-nunito cursor-text rounded-lg border border-gray-300 bg-gray-50 px-4 py-3 text-base text-gray-500 hover:border-gray-400"
                onClick={() => prepare()}
              >
                <span>Write text here ...</span>
              </div>
            )}
            {(field?.value || focused) && (
              <ReactQuill
                theme="snow"
                placeholder={placeholder}
                id={id}
                ref={ref}
                className={
                  errors[name] ? 'border !border-red-700 bg-red-50' : ''
                }
                style={customQuillStyles}
                modules={quillModules}
                formats={quillFormats}
                value={field.value}
                onChange={(value) => {
                  field.onChange(field.name, value);
                  if(shouldValidate) void setTouched(field.name, true);
                  void setFieldValue(field.name, value);
                }}
                readOnly={disabled}
                {...rest}
              />
            )}
          </>
        );
      }}
    </Field>
  );
}

export default InputRichText;
