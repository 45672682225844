import { useFirestoreQueryData } from '@react-query-firebase/firestore';
import {
  FirestoreError,
  orderBy,
  query,
  QueryConstraint,
  where,
  doc,
  updateDoc,
  onSnapshot,
  DocumentSnapshot,
  Unsubscribe,
  DocumentReference,
  getDoc,
} from 'firebase/firestore';
import { t } from 'i18next';
import {
  DR_Cause,
  DR_Fault,
  DR_Thread,
  DR_Warranty,
} from '../../data/portal/src/index';
import {
  TenantLinkFieldName,
  DTCause,
  DTWarranty,
  DTFault,
  DTThread,
} from '../../data/types/src/index';
import { FBF_CloudCallable } from '../../firebase/adapter/src/index';
import { DRDocType } from '../../firebase/react/src/index';
import { UseQueryResult } from 'react-query';
import { firebaseStore } from '../../firebase/adapter-react/src/index';
import { NoSqlDoc } from '@rabbit/firebase/doctype';

type FormParams = {
  Cause: {
    title: string;
    description: string;
    enabled?: boolean;
  };
  Fault: {};
  Issue: {};
  Partner: {};
  Product: {};
  Warranty: {};
} & Record<Docs, { [key: string]: any }>;

type Filters = {
  status?: boolean;
  date?: { min: Date; max: Date };
  enabled?: boolean;
};

type Sort<T> = { field: keyof T; direction: 'asc' | 'desc' };
type _DocTypes<T extends Docs> = Record<T, DocTypes[T]>;

// Add collection
type Docs = 'Cause' | 'Fault' | 'Warranty' | 'Thread';
// Update collection type definitions
type DocTypes = {
  Cause: DTCause;
  Fault: DTFault;
  Warranty: DTWarranty;
  Thread: DTThread;
};
// define collections here
const Collections: Record<Docs, { name: string; collection: DRDocType<any> }> =
  {
    Cause: {
      name: 'cause-list',
      collection: DR_Cause,
    },
    Fault: {
      name: 'fault-list',
      collection: DR_Fault,
    },
    Warranty: {
      name: 'warranty-list',
      collection: DR_Warranty,
    },
    Thread: {
      name: 'thread',
      collection: DR_Thread,
    },
  };

export function useApi() {
  const tenantId = t(TenantLinkFieldName);

  const createDoc = async <T extends Docs>(doc: Docs, data: FormParams[T]) => {
    const url = `api/${doc.toLowerCase()}s|POST`;
    FBF_CloudCallable<{ input: FormParams[T]; tenantLink: string }, any>(url)({
      input: data,
      tenantLink: tenantId,
    });
  };

  const updateDoc = async <T extends Docs>(
    doc: Docs,
    data: FormParams[T] & { docid: string }
  ) => {
    const url = `api/${doc.toLowerCase()}s/:docid|PUT`;
    FBF_CloudCallable<{ input: FormParams[T]; docid: string }, any>(url)({
      input: data,
      docid: data.docid,
    });
  };

  const deleteDoc = async (doc: Docs, docid: string) => {
    const url = `api/${doc.toLowerCase()}s/:docid/disable|DELETE`;
    FBF_CloudCallable<{ docid: string }, any>(url)({
      docid,
    });
  };

  const subscribe = <T extends Docs>(
    doc: T,
    filters?: Filters,
    sort?: Sort<_DocTypes<T>[T]>
  ): UseQueryResult<_DocTypes<T>[T][], FirestoreError> => {
    // Initialize constraints
    const constraints = [
      where('tenantLink', '==', tenantId),
    ] as QueryConstraint[];

    // Update constraints filters
    if (filters?.status)
      constraints.push(where('status', '==', filters.status));
    if (filters?.enabled)
      constraints.push(where('enabled', '==', filters.enabled));
    if (filters?.date) {
      constraints.push(where('tcreate', '>=', filters.date.min));
      constraints.push(where('tcreate', '<=', filters.date.max));
    }

    if (sort) {
      constraints.push(orderBy(sort.field as string, sort.direction));
    }

    const requestQuery = query<_DocTypes<T>[T], any>(
      Collections[doc].collection.collection(),
      ...constraints
    );

    // Get data, add filters as dependency
    const data = useFirestoreQueryData<_DocTypes<T>[T]>(
      [Collections[doc].name, filters, sort],
      requestQuery,
      {
        subscribe: true,
      }
    );

    return data;
  };

  //
  return { createDoc, subscribe, updateDoc, deleteDoc };
}

export const useDocSubscription = <T extends Docs>(
  doc: T,
  params: Record<string, string> = {}
): UseQueryResult<_DocTypes<T>[T][], FirestoreError> => {
  const constraints = Object.keys(params).map((key) =>
    where(key, '==', params[key])
  );
  const requestQuery = query<_DocTypes<T>[T], any>(
    Collections[doc].collection.collection(),
    ...constraints
  );
  // Get data, add filters as dependency
  const data = useFirestoreQueryData<_DocTypes<T>[T]>(
    [Collections[doc].name, params],
    requestQuery,
    {
      subscribe: true,
    }
  );
  return data;
};

export const subscribeDocument = <T>(
  path: string,
  observer: (snapshot: DocumentSnapshot<T>) => void
): Unsubscribe => {
  const docRef = doc(firebaseStore, path) as DocumentReference<T>;
  return onSnapshot(docRef, observer, (error) => console.log(error));
};

export const updateDocument = <T extends { [key: string]: any }>(
  path: string,
  data: T
) => {
  const docRef = doc(firebaseStore, path) as DocumentReference<T>;
  return updateDoc(docRef, data);
};
