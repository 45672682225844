import {
  Empty_NoSqlDoc,
  FBDTKeygenGeneric,
  MakeFBDocType,
  NoSqlDoc,
} from '@rabbit/firebase/doctype';
import { z } from 'zod';

export interface ChatMessage {
  body: string;
  attachment?: string;
  sender: string;
  timestamp: number;
}

export const Z_ChatMessage = z.object({
  body: z.string(),
  attachment: z.string().optional(),
  sender: z.string(),
  timestamp: z.number(),
});

export interface DTThread extends NoSqlDoc {
  /* Array of messages contained in the thread */
  messages: ChatMessage[];

  /* ID of the claim this thread belongs to */
  claimId: string;

  /* List of actors in the thread */
  actors: Record<string, string>;
}

export const FBD_Thread = MakeFBDocType<DTThread>({
  name: 'Thread',
  collection: 'thread',
  empty: () => {
    const result: DTThread = {
      ...Empty_NoSqlDoc(),
      messages: [],
      claimId: '',
      actors: {},
    };
    return result;
  },
  keygen: FBDTKeygenGeneric,
});
