import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CaseflowContext } from '../../../context/CaseflowContext';
import { Modal } from '@rabbit/elements/shared-components';
import ClaimActions from '../../molecules/claims/ClaimActions/ClaimActions';
import ClaimStatusSection from '../../molecules/claims/ClaimStatusSection/ClaimStatusSection';
import ClaimActivitySection from '../../molecules/claims/ClaimActivitySection/ClaimActivitySection';
import ClaimDetailsSection from '../../molecules/claims/ClaimDetailsSection/ClaimDetailsSection';
import ClaimChatLog from '../../molecules/claims/ClaimChatLog/ClaimChatLog';
import ProductDetailsCard from '../../molecules/ProductDetailsCard/ProductDetailsCard';
import CustomerDetailsCard from '../../molecules/CustomerDetailsCard/CustomerDetailsCard';
import ClaimCostsSection from '../../molecules/claims/ClaimCostsSection/ClaimCostsSection';
import ClaimWarrantyDetailSection from '../../molecules/claims/ClaimWarrantyDetailSection/ClaimWarrantyDetailSection';
import ClaimInvoiceSection from '../../molecules/claims/ClaimInvoiceSection/ClaimInvoiceSection';
import { ConfigContext } from '@rabbit/config/context';
import RepairerDetailsCard from '../../molecules/claims/RepairerDetailsCard/RepairerDetailsCard';
import { RCO } from 'libs/configs/src/context/render-control';
import ClaimAssessmentSection from '../../molecules/claims/ClaimAssessmentSection/ClaimAssessmentSection';
import ClaimInstallerDetailsSection from '@rabbit/sage/components/molecules/claims/ClaimInstallerDetailsSection/ClaimInstallerDetailsSection.tsx';

export function ClaimDetailViewTemplate() {
  const context = useContext(CaseflowContext);
  const { config, RenderControl } = useContext(ConfigContext);
  const { showModal, modalSettings, caseExists, operatingPersonaSingleKey } =
    context || {};
  const navigate = useNavigate();

  // If we create a case through the proper methods, it won't ever be in the birth station
  // In that case, redirect to the claims page so the user doesn't see an eternally loading empty page
  useEffect(() => {
    if (caseExists === false) navigate('/claims');
  }, [caseExists]);

  return (
    <div className="flex flex-col gap-4">
      <ClaimStatusSection />
      {RenderControl(RCO.SRO_ClaimActionsSection, {
        operatingPersonaSingleKey,
        caseState: context?.caseState,
      }) && <ClaimActions />}
      <div className="flex gap-4">
        <div className="flex w-full max-w-[400px] flex-shrink-0 flex-col gap-4">
          <CustomerDetailsCard />
          <ProductDetailsCard />
          <ClaimWarrantyDetailSection />
          <ClaimInstallerDetailsSection />
          {operatingPersonaSingleKey &&
            config.CLAIMS.CLAIM_DETAIL_VIEW.REPAIRER_DETAILS_SECTION.includes(
              operatingPersonaSingleKey
            ) && <RepairerDetailsCard />}
          <div className="flex min-h-[500px]">
            <ClaimChatLog />
          </div>
        </div>
        <div className="flex flex-grow flex-col gap-4">
          <ClaimDetailsSection />
          <ClaimAssessmentSection />
          <ClaimCostsSection />
          {config.CLAIMS.CLAIMS_FLOW.INVOICE.ENABLED && <ClaimInvoiceSection />}
          <div className="flex flex-shrink-0 grow flex-col gap-4">
            <ClaimActivitySection />
          </div>
        </div>
      </div>
      {showModal && modalSettings && (
        <Modal
          kind={modalSettings?.kind}
          settings={modalSettings?.settings}
          children={modalSettings?.children}
          className="w-full max-w-[500px]"
        />
      )}
    </div>
  );
}

export default ClaimDetailViewTemplate;
